import { CameraType, OrthographicCameraDescription } from '@data/models';


export const defaultOrthographicCameraDescription: OrthographicCameraDescription = {
  type: CameraType.Orthographic,
  name: 'Orthographic Camera',
  width: 800,
  height: 600,
  near: 1,
  far: 10000,
  zoom: 5,
  position: {
    x: 0,
    y: 0,
    z: 100,
  },
  target: {
    x: 0,
    y: 0,
    z: 0
  },
  up: {
    x: 0,
    y: 1,
    z: 0
  }
};
