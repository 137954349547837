import { IToothMovement } from './TeethMovement';

export interface ITooth {
  file: number;
  rootFile: number;
  id: string;
  number: number;
  transform: number[];
  displacement?: IToothMovement;
  occlusion?: string;
  label?: string;
  toothPosition?: IToothPosition;
}

enum ToothRelativePosition {
  Main, Mesial, Distal
}

export interface IToothPosition {
  toothMapPosition: number;
  relativePosition: ToothRelativePosition;
}

export interface IIPR {
  adjacentTeethFiles: number[];
  adjacentTeethNumbers: number[];
  appliedOnStage: number;
  collisionAmount: number;
  collisionCenter: number[] | null;
  spacing: number;
}

export interface IElastic {
  id: string;
  number: number;
  type: 'Hook' | 'Button';
  points: number[][];
}

export interface IJaw {
  attachments: any[];
  biteRamps: any[];
  gingiva: {
    file: number,
    transform: number[]
  };
  iprs: IIPR[];
  missingTeeth: any[];
  pontics: any[];
  elastics: IElastic[];
  teeth: ITooth[];
  hasOverCorrection: boolean;
}

export interface IStage {
  id: string;
  enabled: boolean;
  upper: IJaw | null;
  lower: IJaw | null;
}

export interface ITreatmentPlan {
  files: string[];
  format: string;
  stages: IStage[];
  toothNumbering: string;
  version: number[];
  jawDisplacement?: number[];
  jawDisplacementStage: IJawDisplacement;
}

export interface IJawDisplacement {
  transform: number[];
  occlusions: IToothlikeOcclusion[];
}

export interface IToothlikeOcclusion {
  toothlikeId: string;
  occlusion: string;
}

export interface ITreatmentPlanVersionShort {
  treatmentPlanVersionId: number | string;
  createdDate: string;
  isEditable: boolean;
}

export interface ITreatmentPlanMeta {
  createdDate: string;
  dateofBirth: string;
  latestStlUrls: any;
  patientId: number | string;
  patientName: string;
  treatingDoctorName: string;
  treatmentPlanId: string;
  treatmentPlanVersions: ITreatmentPlanVersionShort[],
  webViewerUrl: string;
}

export interface ITreatmentPlanVersion {
  createdDate: string;
  isEditable: boolean;
  metaDataAssetUrl: string;
  notes: string[];
  numberOfStages: number;
  previousVersionId: number;
  processingUserId: number | string;
  status: string;
  treatmentPlanId: number | string;
  treatmentPlanVersionAssetUrl: string;
  treatmentPlanVersionId: number | string;
  treatmentReportUrl: string;
  updatedDate: string;
  viewerAssetUrl: string;
}
