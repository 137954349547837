import React from 'react';
import styles from './styles.scss';
import { IToothMovement } from '@data/models';
import { cn } from '@utils';

const formatValue = (value: number) => {
  const valueTwoDecimals = +value.toFixed(2);

  if (Math.abs(valueTwoDecimals) < 0.01) {
    return null;
  }

  return valueTwoDecimals.toFixed(2);
};

export type TableRowProps = {
  data?: IToothMovement;
  toothNumber: string;
  isLast: boolean;
  isFirst: boolean;
};

const TableRow = ({ data, toothNumber, isLast, isFirst }: TableRowProps) => {
  if (data) {
    return (
      <div className={cn(styles.teethMovement_table_row)}>
        <div
          className={cn(
            styles.teethMovement_table_cell,
            styles.__withoutBorder
          )}
        >
          {toothNumber}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {formatValue(data.extrusionIntrusionTranslation) ?? (
            <span className="!text-gray-400">0</span>
          )}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {formatValue(data.buccalLingualTranslation) ?? (
            <span className="!text-gray-400">0</span>
          )}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {formatValue(data.mesialDistalTranslation) ?? (
            <span className="!text-gray-400">0</span>
          )}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {formatValue(data.axialRotationDeg) ?? (
            <span className="!text-gray-400">0</span>
          )}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {formatValue(data.mesialDistalAngulationDeg) ?? (
            <span className="!text-gray-400">0</span>
          )}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, styles.__right, {
            [styles.__bottom]: isLast,
          })}
        >
          {formatValue(data.buccalLingualAngulationDeg) ?? (
            <span className="!text-gray-400">0</span>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={cn(styles.teethMovement_table_row)}>
        <div
          className={cn(
            styles.teethMovement_table_cell,
            styles.__withoutBorder,
            'relative text-space opacity-40'
          )}
        >
          <div className="ml-0.5 h-[26px] w-0.5 bg-space opacity-40 absolute right-1/2 -top-0.5 rotate-45" />
          {toothNumber}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {''}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {''}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {''}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {''}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, {
            [styles.__bottom]: isLast,
          })}
        >
          {''}
        </div>

        <div
          className={cn(styles.teethMovement_table_cell, styles.__right, {
            [styles.__bottom]: isLast,
          })}
        ></div>
      </div>
    );
  }
};

export default TableRow;
