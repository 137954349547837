import { IStage, ITreatmentPlan } from '@data/models';
import { cloneDeep } from 'lodash';


export const getFilledStages = (plan: ITreatmentPlan): IStage[] => {
  const stagesNew: IStage[] = [];

  for (let i = 0; i < plan.stages.length; i++) {
    const newStage = cloneDeep(plan.stages[i]);
    if (!plan.stages[i].lower) {
      newStage.lower = stagesNew[i - 1].lower;
    }
    if (!plan.stages[i].upper) {
      newStage.upper = stagesNew[i - 1].upper;
    }
    stagesNew.push(newStage);
  }

  return stagesNew;
};
