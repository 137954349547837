import React from 'react';
import { ConfigProvider } from '@data/providers';
import { IViewSettings } from '@data/models';
import { BooleanKeys } from '@types';
import MenuCheckbox from './MenuCheckbox';
import MenuRadiobutton from './MenuRadiobutton';
import MenuButton from './MenuButton';
import MenuHeader from './MenuHeader';
import styles from './styles.scss';
import { Sidebar } from '@view/layouts';

type MobileMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  configProvider: ConfigProvider;
  viewSettings: IViewSettings;
  toggleViewSetting: (setting: BooleanKeys<IViewSettings>) => void;
  setViewSetting: (setting: keyof IViewSettings, value: any) => void;
  onTeethMovementTableOpen: () => void;
  onPhotosOpen: () => void;
  onTreatmentPlanClick?: () => void;
  onPatientInfoOpen: () => void;
  isPhotos?: boolean;
  isOcclusionsEnabled?: boolean;
  isPatientInfo?: boolean;
  isTeethMovement?: boolean | null;
  isTreatmentPlanPdf?: boolean;
};

const MobileMenu = ({
  isOpen,
  onClose,
  toggleViewSetting,
  setViewSetting,
  viewSettings,
  configProvider,
  onTeethMovementTableOpen,
  onPhotosOpen,
  onTreatmentPlanClick,
  onPatientInfoOpen,
  isPhotos,
  isTeethMovement,
  isPatientInfo,
  isOcclusionsEnabled,
  isTreatmentPlanPdf,
}: MobileMenuProps) => {
  const overlayFeatureEnabled = configProvider.isFeatureEnabled('overlay');
  const hideGumFeatureEnabled = configProvider.isFeatureEnabled('hideGum');

  return (
    <Sidebar isOpen={isOpen} size="sm">
      <MenuHeader onClose={onClose} />
      <div className={styles.mobileMenu}>
        <section className={styles.mobileMenu_section}>
          {isPatientInfo && (
            <MenuButton
              title="Patient Info"
              onClick={onPatientInfoOpen}
              icon={
                <img
                  src="/assets/images/mobile/patientInfo_icon.svg"
                  alt="Patient info"
                />
              }
            />
          )}
          {isTreatmentPlanPdf && (
            <MenuButton
              title="Treatment Plan PDF"
              onClick={onTreatmentPlanClick}
              icon={
                <img
                  src="/assets/images/mobile/treatment-plan-icon.svg"
                  alt="Patient photos"
                />
              }
            />
          )}
          {isPhotos && (
            <MenuButton
              title="Patient photos"
              onClick={onPhotosOpen}
              icon={
                <img
                  src="/assets/images/mobile/patientPhoto_icon.svg"
                  alt="Patient photos"
                />
              }
            />
          )}
          {isTeethMovement && (
            <MenuButton
              title="Tooth Moving Table"
              onClick={onTeethMovementTableOpen}
              icon={
                <img
                  src="/assets/images/mobile/tmt_icon.svg"
                  alt="Tooth Moving Table"
                />
              }
            />
          )}
        </section>
        <section className={styles.mobileMenu_section}>
          <MenuCheckbox
            id="mobileGrid"
            icon={
              <img src="/assets/images/mobile/grid_icon.svg" alt="grid icon" />
            }
            checked={viewSettings.grid}
            onChange={() => toggleViewSetting('grid')}
          >
            Grid
          </MenuCheckbox>
          <MenuRadiobutton
            id="grid1mm"
            checked={viewSettings.gridSize === 1}
            onChange={(event) =>
              setViewSetting('gridSize', Number(event.target.value))
            }
            value={1}
          >
            1 mm
          </MenuRadiobutton>
          <MenuRadiobutton
            id="grid5mm"
            checked={viewSettings.gridSize === 5}
            onChange={(event) =>
              setViewSetting('gridSize', Number(event.target.value))
            }
            value={5}
          >
            5 mm
          </MenuRadiobutton>
        </section>
        <section className={styles.mobileMenu_section}>
          <MenuCheckbox
            id="mobileAttachments"
            icon={
              <img
                src="/assets/images/mobile/attachments_icon.svg"
                alt="attachments icon"
              />
            }
            checked={viewSettings.attachments}
            onChange={() => toggleViewSetting('attachments')}
          >
            Attachments
          </MenuCheckbox>
          <MenuCheckbox
            id="mobileElastics"
            icon={
              <img
                src="/assets/images/mobile/elastics_icon.svg"
                alt="Elastics icon"
              />
            }
            checked={viewSettings.elastics}
            onChange={() => toggleViewSetting('elastics')}
          >
            Elastics
          </MenuCheckbox>
          <MenuCheckbox
            id="mobileBiteRamps"
            icon={
              <img
                src="/assets/images/mobile/biteRamps_icon.svg"
                alt="Bite ramps icon"
              />
            }
            checked={viewSettings.biteRamps}
            onChange={() => toggleViewSetting('biteRamps')}
          >
            Bite Ramps
          </MenuCheckbox>
          <MenuCheckbox
            id="mobilePontics"
            icon={
              <img
                src="/assets/images/mobile/pontics_icon.svg"
                alt="Pontics icon"
              />
            }
            checked={viewSettings.pontics}
            onChange={() => toggleViewSetting('pontics')}
          >
            Pontics
          </MenuCheckbox>
          <MenuCheckbox
            id="mobileIPRs"
            icon={
              <img src="/assets/images/mobile/iprs_icon.svg" alt="IPRs icon" />
            }
            checked={viewSettings.iprs}
            onChange={() => toggleViewSetting('iprs')}
          >
            IPRs
          </MenuCheckbox>
          <MenuCheckbox
            id="mobileOverlay"
            disabled={!overlayFeatureEnabled}
            icon={
              <img
                src="/assets/images/mobile/overlay_icon.svg"
                alt="Overlay icon"
              />
            }
            checked={viewSettings.overlay}
            onChange={() => toggleViewSetting('overlay')}
          >
            Overlay
          </MenuCheckbox>
          <MenuCheckbox
            id="mobileContacts"
            icon={
              <img
                src="/assets/images/mobile/occlusions_icon.svg"
                alt="Contacts panel"
              />
            }
            disabled={!isOcclusionsEnabled}
            checked={viewSettings.occlusions}
            onChange={() => toggleViewSetting('occlusions')}
          >
            Contacts
          </MenuCheckbox>
          <MenuCheckbox
            id="hideGum"
            icon={
              <img 
                className="w-4"
                src="/assets/images/mobile/hideGum_icon.svg"
                alt="Contacts panel"
              />
            }
            disabled={!hideGumFeatureEnabled}
            checked={!viewSettings.hideGum}
            onChange={() => toggleViewSetting('hideGum')}
          >
            Gum
          </MenuCheckbox>
        </section>
      </div>
    </Sidebar>
  );
};

export default MobileMenu;
