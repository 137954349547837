import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Sidebar } from '@view/layouts';
import { CloseButton } from '@view/components';
import {
  FlipIcon,
  GridIcon,
  RotateIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'assets/images/toolbar';
import styles from './styles.scss';
import { cn, debounce } from '@utils';
import { BooleanKeys, NumberKeys } from '@types';
import { CanvasImageState, ImageState, PreviewModeProps } from './types';
import { isTablet } from 'react-device-detect';

const initialPreviewState: ImageState = {
  grid: false,
  flipped: false,
  zoom: 1,
  rotate: 0,
};

const PreviewMode = ({
  isPreviewModeOpen,
  onPreviewModeClose,
  url,
}: PreviewModeProps) => {
  const [imageState, setImageState] = useState<ImageState>(initialPreviewState);
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Load the image
  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImage(img);
      drawImage(img, initialPreviewState);
    };
    img.onerror = () => {
      console.error('Failed to load image.');
    };
  }, []);

  const renderDescription = useCallback(() => {
    return [
      imageState.flipped && 'Image flipped horizontally',
      imageState.zoom !== 1 && `Zoom ${imageState.zoom * 100}%`,
    ]
      .filter(Boolean)
      .join(', ');
  }, [imageState.flipped, imageState.zoom]);

  const drawImage = useCallback(
    (image: HTMLImageElement, state: ImageState) => {
      if (canvasRef.current && image) {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
          canvas.width = canvas.parentElement!.offsetWidth;
          canvas.height = canvas.parentElement!.offsetHeight;
          context.clearRect(0, 0, canvas.width, canvas.height);
          const scale =
            Math.min(canvas.width / image.width, canvas.height / image.height) *
            state.zoom;

          context.save();
          context.translate(canvas.width / 2, canvas.height / 2);
          context.rotate((state.rotate * Math.PI) / 180);
          if (state.flipped) {
            context.scale(-1, 1);
          }

          const imageState: CanvasImageState = {
            imagePositionX: canvas.width / 2 - (image.width / 2) * scale,
            imagePositionY: canvas.height / 2 - (image.height / 2) * scale,
            imageWidth: image.width * scale,
            imageHeight: image.height * scale,
          };

          context.drawImage(
            image,
            (-image.width / 2) * scale,
            (-image.height / 2) * scale,
            imageState.imageWidth,
            imageState.imageHeight
          );
          context.restore();

          if (state.grid) {
            drawGridOverlay(context, state, imageState);
          }
        }
      }
    },
    [imageState]
  );

  const drawGridOverlay = (
    context: CanvasRenderingContext2D,
    state: ImageState,
    imageState: CanvasImageState
  ) => {
    const { imagePositionX, imageHeight, imagePositionY, imageWidth } =
      imageState;
    const cellSize = 50;
    const cellSizeLarge = cellSize * 5;
    const centerX = imagePositionX + imageWidth / 2;
    const centerY = imagePositionY + imageHeight / 2;

    // Save the current context state
    context.save();

    // Move the context to the center of the image
    context.translate(centerX, centerY);

    // Rotate the context
    context.rotate((state.rotate * Math.PI) / 180);

    // Move the context back
    context.translate(-centerX, -centerY);

    // Clip the context to the bounds of the image
    context.beginPath();
    context.rect(imagePositionX, imagePositionY, imageWidth, imageHeight);
    context.clip();

    context.lineWidth = 1;
    context.strokeStyle = 'rgba(69, 90, 100, 0.40)';

    for (
      let i = imagePositionX + cellSize;
      i <= imagePositionX + imageWidth;
      i += cellSize
    ) {
      context.beginPath();
      context.moveTo(i, imagePositionY);
      context.lineTo(i, imagePositionY + imageHeight);
      context.stroke();
    }

    for (
      let i = imagePositionY + cellSize;
      i <= imagePositionY + imageHeight;
      i += cellSize
    ) {
      context.beginPath();
      context.moveTo(imagePositionX, i);
      context.lineTo(imagePositionX + imageWidth, i);
      context.stroke();
    }

    // Draw lines (lg cells)
    context.strokeStyle = 'rgba(69, 90, 100, 0.80)';
    for (
      let i = imagePositionX + cellSizeLarge;
      i <= imagePositionX + imageWidth;
      i += cellSizeLarge
    ) {
      context.beginPath();
      context.moveTo(i, imagePositionY);
      context.lineTo(i, imagePositionY + imageHeight);
      context.stroke();
    }

    for (
      let i = imagePositionY + cellSizeLarge;
      i <= imagePositionY + imageHeight;
      i += cellSizeLarge
    ) {
      context.beginPath();
      context.moveTo(imagePositionX, i);
      context.lineTo(imagePositionX + imageWidth, i);
      context.stroke();
    }

    // Restore the context to its original state
    context.restore();
  };

  const handleResize = debounce(() => {
    if (image) drawImage(image, imageState);
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleToggleModeValue = (mode: BooleanKeys<ImageState>) => {
    setImageState((prevState) => {
      const newState = {
        ...prevState,
        [mode]: !prevState?.[mode],
      };
      if (image) drawImage(image, newState);
      return newState;
    });
  };

  const handleChangeModeValue = (
    mode: NumberKeys<ImageState>,
    value: number
  ) => {
    setImageState((prevState) => {
      const newState = {
        ...prevState,
        [mode]: value,
      };
      if (image) drawImage(image, newState);
      return newState;
    });
  };

  return (
    <Sidebar size="lg" isOpen={isPreviewModeOpen}>
      {/* Header Toolbar - start */}
      <div className={styles.previewMode_header}>
        <button
          type="button"
          className={styles.previewMode_closeButton}
          onClick={onPreviewModeClose}
        >
          <span className="sr-only">Close panel</span>
          <img src="/assets/images/arrow_left.svg" alt="Close icon" />
        </button>
        <div className="flex gap-x-4 items-center">
          <button
            onClick={() => handleToggleModeValue('grid')}
            className={cn(
              styles.previewMode_toolbarButton,
              imageState.grid && 'bg-blue [&>svg>path]:fill-white'
            )}
          >
            <GridIcon />
          </button>
          <button
            onClick={() =>
              handleChangeModeValue(
                'zoom',
                Math.max(0.25, imageState.zoom - 0.25)
              )
            }
            className={cn(
              styles.previewMode_toolbarButton,
              imageState.zoom === 0.25 &&
                'bg-transparent [&>svg>path]:fill-gray-300 pointer-events-none'
            )}
          >
            <ZoomOutIcon />
          </button>
          <button
            onClick={() =>
              handleChangeModeValue('zoom', Math.min(2, imageState.zoom + 0.25))
            }
            className={cn(
              styles.previewMode_toolbarButton,
              imageState.zoom === 2 &&
                'bg-transparent [&>svg>path]:fill-gray-300 pointer-events-none'
            )}
          >
            <ZoomInIcon />
          </button>
          <button
            onClick={() => handleToggleModeValue('flipped')}
            className={cn(
              styles.previewMode_toolbarButton,
              imageState.flipped && 'bg-blue [&>svg>path]:fill-white'
            )}
          >
            <FlipIcon />
          </button>
          <button
            onClick={() => {
              if (imageState.rotate === 270) {
                handleChangeModeValue('rotate', 0);
                return null;
              }
              handleChangeModeValue('rotate', imageState.rotate + 90);
            }}
            className={styles.previewMode_toolbarButton}
          >
            <RotateIcon />
          </button>
        </div>
        <div className="w-5" />
        <CloseButton
          className={cn('hidden lg:block', isTablet && 'hidden')}
          size="lg"
          onClick={onPreviewModeClose}
        />
      </div>
      {/* Header Toolbar - end */}
      <div className={styles.previewMode_photoWrapper}>
        <div className="relative w-full h-full">
          <canvas ref={canvasRef} />
        </div>
        <div className={styles.previewMode_previewChangeLabel}>
          {renderDescription()}
        </div>
      </div>
    </Sidebar>
  );
};
export default PreviewMode;
