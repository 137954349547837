import { BlobReader, BlobWriter, TextWriter, ZipReader } from '@zip.js/zip.js';
import { FXClient } from '@data/clients';
import {
  IStage,
  ITreatmentPlan,
  ITreatmentPlanMeta,
  ITreatmentPlanVersion,
  IViewSettings,
  JawType,
  ObjectDescription,
  ObjectTypes
} from '@data/models';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { getAllObjects, getFilledStages, getObjectsForStage, getStagesCount, } from './utils';
import { RawFile, TreatmentPlan } from './types';


class ViewerProvider {
  private readonly _fxClient: FXClient;
  private readonly _sessionId: string | null;
  private _token: string | null;

  constructor (sessionId: string | null) {
    this._fxClient = new FXClient();
    this._sessionId = sessionId;
    this._token = null;
  }

  async getPatientName (planId: string): Promise<string> {
    if (!this._token) {
      await this.authorize();
    }

    const planMetadata = await this._fxClient.getTreatmentPlanVersions(this._token as string, planId);

    return planMetadata.patientName;
  }

  async getTreatmentPlanMetadata (planId: string, versionId?: string): Promise<ITreatmentPlanVersion> {
    if (!this._token) {
      await this.authorize();
    }

    let planMetadata: ITreatmentPlanMeta | null = null;
    if (versionId) {
      return await this._fxClient.getPlanDetails(this._token as string, versionId);
    } else {
      planMetadata = await this._fxClient.getTreatmentPlanVersions(this._token as string, planId);
      const versions = planMetadata.treatmentPlanVersions.slice();
      versions.sort((a, b) => {
        const leftDate = moment(a.createdDate);
        const rightDate = moment(b.createdDate);

        return leftDate.isBefore(rightDate) ? 1 : -1;
      });
      const [ latestVersion ] = versions;
      return await this._fxClient.getPlanDetails(this._token as string, latestVersion.treatmentPlanVersionId);
    }
  }

  async loadStages (assetUrl: string) {
    const { plan, files } = await this.readAssetUrl(assetUrl);
    return await this.getStagesFromPlan(plan, files);
  }

  applyViewSettingsForObjects (objects: ObjectDescription[], viewSettings: IViewSettings) {
    return objects.map((item: ObjectDescription) => {
      const newObject = cloneDeep(item);

      // If object is invisible, we don't need to do anything
      if (!newObject.visible) {
        return newObject;
      }

      // If jaw is invisible, make all jaw objects invisible and return
      if (!viewSettings.upperJawVisible && newObject.jaw === JawType.Upper) {
        newObject.visible = false;
        return newObject;
      }
      if (!viewSettings.lowerJawVisible && newObject.jaw === JawType.Lower) {
        newObject.visible = false;
        return newObject;
      }

      // If jaw is visible, apply view settings to the object
      switch (newObject.type) {
        case ObjectTypes.Attachment:
          newObject.visible = viewSettings.attachments;
          break;

        case ObjectTypes.Elastic:
          newObject.visible = viewSettings.elastics;
          break;

        case ObjectTypes.Biteramp:
          newObject.visible = viewSettings.biteRamps;
          break;

        case ObjectTypes.IPR:
          newObject.visible = viewSettings.iprs;
          break;

        case ObjectTypes.Pontic:
          newObject.visible = viewSettings.pontics;
          break;

        default:
          // For not presented types above we don't need to do anything.
          break;
      }

      return newObject;
    });
  }

  private async readAssetUrl (assetUrl: string) : Promise<TreatmentPlan>{
    const result = await this._fxClient.getViewerAsset(assetUrl);

    const zipFileReader = new BlobReader(result);
    const zipReader = new ZipReader(zipFileReader);

    const entries = await zipReader.getEntries();

    const data: (RawFile | null)[] = await Promise
      .all(entries.map(async (entry): Promise<RawFile | null> => {
        if (!entry || !entry.getData || entry.directory) {
          return null;
        }

        const writer = entry.filename === 'meta.json'
          ? new TextWriter()
          : new BlobWriter();
        const data = await entry.getData(writer);

        return {
          filename: entry.filename,
          data: data
        };
      }));

    await zipReader.close();

    const [ rawPlan ] = data.filter((item) => item && item.filename === 'meta.json');

    if (!rawPlan) {
      throw Error('Can\'t find meta.json!');
    }

    const plan: ITreatmentPlan = JSON.parse(rawPlan.data as string);

    return {
      plan,
      files: (data
        .filter((item) => item !== null)
        .filter((item: RawFile | null) => item && item.filename !== 'meta.json')
      ) as RawFile[]
    };
  }

  private async getStagesFromPlan (plan: ITreatmentPlan, files: RawFile[]) {
    const { upperStagesCount, lowerStagesCount } = getStagesCount(plan);
    const stages = getFilledStages(plan);
    const allObjects = getAllObjects(plan, files, stages);

    const objectsByStage: ObjectDescription[][] = stages.map((stage: IStage) => {
      return getObjectsForStage(stage, allObjects, plan.files);
    });

    let lowerStageOvercorrection: number | null = null;
    let upperStageOvercorrection : number | null = null;

    stages.forEach((stage: IStage, index: number) => {
      if (stage.lower.hasOverCorrection && lowerStageOvercorrection === null) {
        lowerStageOvercorrection = index;
      }

      if (stage.upper.hasOverCorrection && upperStageOvercorrection === null) {
        upperStageOvercorrection = index;
      }
    });

    return {
      objectsByStage,
      upperStagesCount,
      lowerStagesCount,
      upperStageOvercorrection,
      lowerStageOvercorrection,
      allStagesCount: plan.stages.length
    };
  }

  private async authorize (): Promise<string | null> {
    if (!this._sessionId) {
      throw new Error('Can\'t auth without session_id. Please check URL parameters.');
    }

    this._token = await this._fxClient.getToken(this._sessionId);

    if (!this._token) {
      throw Error('Failed to get a token');
    }

    return this._token;
  }
}

export default ViewerProvider;
