import { OrthographicCamera as ThreeOrthographicCamera, Scene, Vector3 } from 'three';
import { OrthographicCameraDescription } from '@data/models';
import { cloneDeep, isEqual } from 'lodash';

export class OrthographicCamera {
  private readonly _name: string;
  private _description: OrthographicCameraDescription;
  private readonly _camera: ThreeOrthographicCamera;
  private readonly _scene: Scene;

  constructor (scene: Scene, description: OrthographicCameraDescription, aspect: number) {
    this._scene = scene;
    this._description = cloneDeep(description);
    this._name = description.name;
    this._camera = new ThreeOrthographicCamera(
      -description.width / 2,
      description.width / 2,
      description.height / 2,
      - description.height / 2,
      description.near,
      description.far
    );

    this._camera.zoom = description.zoom;
    this._camera.position.set(description.position.x, description.position.y, description.position.z);
    this._camera.lookAt(new Vector3(description.target.x, description.target.y, description.target.z));

    this._camera.updateProjectionMatrix();
  }

  update (description: OrthographicCameraDescription) {
    if (isEqual(this._description, description)) {
      return;
    }

    this._camera.left = -description.width / 2;
    this._camera.right = description.width / 2;
    this._camera.top = description.height / 2;
    this._camera.bottom = -description.height / 2;

    this._camera.near = description.near;
    this._camera.far = description.far;

    this._camera.zoom = description.zoom;
    this._camera.position.set(description.position.x, description.position.y, description.position.z);
    this._camera.lookAt(new Vector3(description.target.x, description.target.y, description.target.z));

    this._camera.updateProjectionMatrix();

    this._description = cloneDeep(description);
  }

  get camera () {
    return this._camera;
  }

  getCamera () {
    return this._camera;
  }

  resize (width: number, height: number) {
    // this._camera.aspect = getAspectRatio(width, height);
    this._camera.updateProjectionMatrix();
  }

  getDescription () {
    return this._description;
  }

  remove () {
    this._scene.remove(this._camera);
  }
}
