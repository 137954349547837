import BaseObject from './BaseObject';
import { Matrix4, Mesh } from 'three';
import { BiterampObjectDescription, MaterialTypes, ObjectTypes } from '@data/models';
import { GeometryProvider, MaterialsProvider } from '@data/providers';
import { WebGLScene } from '../WebGLScene';


class BiterampObject extends BaseObject {
  constructor(mesh: Mesh) {
    super(mesh, ObjectTypes.Biteramp);
  }

  static async create(
    description: BiterampObjectDescription,
    scene: WebGLScene,
    geometryProvider: GeometryProvider,
    materialsProvider: MaterialsProvider
  ) {
    const mesh = new Mesh(
      geometryProvider.getGeometryByDescription(description),
      materialsProvider.getMaterial(MaterialTypes.Biteramp)
    );

    const position = mesh.position.clone();
    const rotation = mesh.rotation.clone();
    const scale = mesh.scale.clone();

    if (description.transform) {
      const matrix = new Matrix4();
      matrix.fromArray(description.transform);
      matrix.transpose();
      mesh.applyMatrix4(matrix);
    }

    const obj = new BiterampObject(mesh);
    obj.setInitial(position, rotation, scale);
    scene.getRootObject().add(obj.mesh);

    obj.id = description.id;
    obj.visible = description.visible;

    return obj;
  }
}

export default BiterampObject;
