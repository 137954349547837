import {IToothlikeNameProvider} from '@data/providers/DentalNotationProvider/IToothlikeNameProvider';
import {DentalNotationHelper} from '@data/providers/DentalNotationProvider/DentalNotationHelper';
import {PrimaryTeethNotationHelper} from '@data/providers/DentalNotationProvider/PrimaryTeethNotationHelper';

export class ToothlikePalmerNameProvider implements IToothlikeNameProvider {
  getName(toothNumber: number): string {
    return this.getToothNumberPalmer(toothNumber);
  }
  private getToothNumberPalmer(toothNumber: number): string {
    if (DentalNotationHelper.isValidToothNumber(toothNumber))
      return ToothlikePalmerNameProvider.getPermanentToothNameInNotation(toothNumber);
    else if (PrimaryTeethNotationHelper.isValidPrimaryToothNumber(toothNumber))
      return ToothlikePalmerNameProvider.getPrimaryToothNameInNotation(toothNumber);
    else
      throw new Error(`${toothNumber} is not a valid tooth number!`);
  }

  private static getPermanentToothNameInNotation (toothNumber: number): string {
    const quadrant = DentalNotationHelper.getQuadrant(toothNumber);
    const toothNumberInQuadrant = DentalNotationHelper.getNumberInQuadrant(toothNumber);
    switch (quadrant)
    {
      case 1:
        return `UR${toothNumberInQuadrant}`;
      case 2:
        return `UL${toothNumberInQuadrant}`;
      case 3:
        return `LL${toothNumberInQuadrant}`;
      case 4:
        return `LR${toothNumberInQuadrant}`;
      default:
        throw new Error('Argument out of range exception');
    }
  }

  private static getPrimaryToothNameInNotation(toothNumber: number): string {
    const quadrant = PrimaryTeethNotationHelper.getJawQuadrant(toothNumber);
    const toothNumberInQuadrant = PrimaryTeethNotationHelper.getNumberInQuadrant(toothNumber);

    switch (quadrant) {
      case 1:
        return `UR${this.getPrimaryToothSubNameInNotation(toothNumberInQuadrant)}`;
      case 2:
        return `UL${this.getPrimaryToothSubNameInNotation(toothNumberInQuadrant)}`;
      case 3:
        return `LL${this.getPrimaryToothSubNameInNotation(toothNumberInQuadrant)}`;
      case 4:
        return `LR${this.getPrimaryToothSubNameInNotation(toothNumberInQuadrant)}`;
      default:
        throw new Error('Argument out of range exception');
    }
  }

  private static getPrimaryToothSubNameInNotation(toothNumberInQuadrant: number) {
    switch (toothNumberInQuadrant) {
      case 1: return 'A';
      case 2: return 'B';
      case 3: return 'C';
      case 4: return 'D';
      case 5: return 'E';
      default:
        throw new Error('Argument out of range exception');
    }
  }
}