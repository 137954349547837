import { KeyCode } from '@data/constants';
import { bindAll } from 'lodash';
import EventEmitter from './EventEmitter';


const SEPARATOR = ',';

const fireEvents = (keyCode, keys, char, handlersMap) => {
  const handlerKey = keys
    .slice()
    .sort()
    .join(SEPARATOR);
  const handlers = handlersMap[handlerKey];

  if (handlers) {
    handlers.forEach((handler) => handler(keyCode, handlerKey.split(SEPARATOR), char));
  }
};

class Keyboard extends EventEmitter {
  static EventType = {
    Down: 'down',
    Up: 'up',
  };

  constructor (domElement = window) {
    super();

    this._domElement = domElement;

    this._keyDownHandlers = {};
    this._keyUpHandlers = {};

    this._currentPressedKeys = [];

    bindAll(this, [
      '_onKeyDown',
      '_onKeyUp'
    ]);
  }

  enable () {
    this._domElement.addEventListener('keydown', this._onKeyDown);
    this._domElement.addEventListener('keyup', this._onKeyUp);
  }

  disable () {
    this._domElement.removeEventListener('keydown', this._onKeyDown);
    this._domElement.removeEventListener('keyup', this._onKeyUp);
  }

  bindKey (keys, callback, eventType = Keyboard.EventType.Down) {
    const keysArr = Array.isArray(keys)
      ? keys
      : [keys];

    const handlerKey = keysArr
      .slice()
      .sort()
      .join(SEPARATOR);

    const addCallback = (handlers) => {
      handlers[handlerKey] = handlers[handlerKey]
        ? [...handlers[handlerKey], callback]
        : [callback];
    };

    switch (eventType) {
      case Keyboard.EventType.Down:
        addCallback(this._keyDownHandlers);
        break;
      case Keyboard.EventType.Up:
        addCallback(this._keyUpHandlers);
        break;
      default:
        break;
    }
  }

  _onKeyDown (event) {
    if (!this._currentPressedKeys.includes(event.keyCode)) {
      this._currentPressedKeys.push(event.keyCode);
    }

    fireEvents(event.keyCode, this._currentPressedKeys, event.char, this._keyDownHandlers);
  }

  _onKeyUp (event) {
    this._currentPressedKeys = this._currentPressedKeys.filter((keyCode) => keyCode !== event.keyCode);

    fireEvents(event.keyCode, this._currentPressedKeys, event.char, this._keyUpHandlers);
  }
}

Object.defineProperty(Keyboard, 'KeyCode', {
  enumerable: false,
  writable: false,
  configurable: false,
  value: KeyCode
});

const keyboardGlobal = new Keyboard();

export default keyboardGlobal;
