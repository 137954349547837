import { EventEmitter } from '@utils';
import { WebGLScene } from './WebGLScene';

class BaseManager<DescriptionType> extends EventEmitter {
  protected _scene: WebGLScene;

  constructor(scene: WebGLScene) {
    super();

    this._scene = scene;
  }

  async initialize() {
    // empty initialization by default
  }

  async createItem(description: DescriptionType) {
    throw new Error('No cases for use BaseManager. Method .createItem(description)');
  }

  removeItem(id: string) {
    throw new Error('No cases for use BaseManager. Method .removeItem(id)');
  }

  updateItem(description: DescriptionType) {
    throw new Error('No cases for use BaseManager. Method .updateItem(description)');
  }

  getChanges(descriptions: DescriptionType[]) {
    throw new Error('No cases for use BaseManager. Method .getChanges()');
  }

  async update(descriptions: DescriptionType[]): Promise<void> {
    throw new Error('No cases for use BaseManager. Method .update(descriptions)');
  }

  async updateByAnimation (): Promise<void> {
    // Nothing to do
  }
}

/* eslint-enable no-unused-vars, class-methods-use-this, require-await */

export default BaseManager;
