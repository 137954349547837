import React from 'react';
import { CloseButton, Toggle } from '@view/components';
import styles from './styles.scss';

type PatientPhotosHeaderProps = {
  onPatientPhotosClose: () => void;
  isViewPortPhotoEnabled: boolean;
  setIsViewPortModeEnabled: (state: boolean) => void;
};

const PatientPhotosHeader = ({
  onPatientPhotosClose,
  isViewPortPhotoEnabled,
  setIsViewPortModeEnabled,
}: PatientPhotosHeaderProps) => {
  return (
    <div className={styles.photosHeader}>
      <div className={styles.photosHeader_top}>
        <span className={styles.photosHeader_title}>Patient Photos</span>
        <CloseButton size="sm" onClick={onPatientPhotosClose} />
      </div>
      <div className={styles.photosHeader_bottom}>
        <Toggle
          label="Show in Viewport"
          labelClassName="text-base text-space"
          value={isViewPortPhotoEnabled}
          onChange={(value) => setIsViewPortModeEnabled(value)}
        />
      </div>
    </div>
  );
};

export default PatientPhotosHeader;
