import React from 'react';
import styles from './styles.scss';
import { Point } from '@data/models';
import Arc from '../Arc';
import TextLabel from '../TextLabel';
import { GeometryUtil } from '@utils';
import classNames from 'classnames';

interface IJawIndicatorProps {
  size: number;
  center: Point;
  radius: number;
  label: string;
  stagesAll: number;
  stagesActual: number;
  overcorrectionStage: number | null;
  firstStage: number;
}

const JawIndicator = ({
  size,
  center,
  radius,
  label,
  stagesAll,
  stagesActual,
  overcorrectionStage,
  firstStage,
}: IJawIndicatorProps) => {
  const baseWidth = size >= 300 ? 2 : 1;
  const filledWidth = size >= 300 ? 6 : 3;
  const startDeg = -225;
  const fullDeg = 270;

  let sectionPerStageDeg = 0;
  if (stagesAll <= 1) {
    sectionPerStageDeg = 0;
  } else if (stagesAll > 1) {
    sectionPerStageDeg = fullDeg / (stagesAll - 1);
  }

  let filledPart = 0;
  if (stagesAll === 1 || stagesActual === 0) {
    filledPart = 0;
  } else if (stagesActual + 1 === stagesAll) {
    filledPart = fullDeg;
  } else if (stagesAll > 1) {
    // filledPart = (stagesActual + 1) * sectionPerStageDeg + fillPaddingDeg;
    filledPart = stagesActual * sectionPerStageDeg;
  }

  const disabledPart = firstStage > 0 ? firstStage * sectionPerStageDeg : 0;

  const overcorrectionStartDeg =
    stagesAll > 0 && overcorrectionStage !== null
      ? startDeg + (overcorrectionStage / stagesAll) * fullDeg
      : 0;
  const overcorrectionEndDeg =
    overcorrectionStage !== null ? startDeg + filledPart : 0;

  const leftLabelPosition = { radius: radius - 5, phi: startDeg - 5 };
  const rightLabelPosition = {
    radius: radius - 5,
    phi: startDeg + fullDeg + 5,
  };

  const cornerDotStartPosition = GeometryUtil.toPoint(
    { radius, phi: startDeg + disabledPart },
    center
  );
  const cornerDotEndPosition = GeometryUtil.toPoint(
    { radius, phi: startDeg + filledPart },
    center
  );

  const dotForDisabledPart = GeometryUtil.toPoint(
    { radius, phi: startDeg },
    center
  );

  return (
    <g x={center.x - radius} y={center.y - radius}>
      <Arc
        className={styles.knob_jawIndicator_arc}
        center={center}
        radius={radius}
        startDeg={startDeg}
        endDeg={startDeg + fullDeg}
        width={baseWidth}
      />
      <Arc
        className={styles.knob_jawIndicator_arc}
        center={center}
        radius={radius}
        startDeg={startDeg}
        endDeg={startDeg + filledPart}
        width={filledWidth}
      />
      {firstStage > 0 && (
        <>
          <Arc
            className={classNames(
              styles.knob_jawIndicator_arc,
              styles.__disabled
            )}
            center={center}
            radius={radius}
            startDeg={startDeg}
            endDeg={startDeg + disabledPart}
            width={filledWidth}
          />
          <circle
            className={classNames(
              styles.knob_jawIndicator_cornerDot,
              styles.__disabled
            )}
            cx={dotForDisabledPart.x}
            cy={dotForDisabledPart.y}
            r={filledWidth / 2}
          />
        </>
      )}
      {stagesAll > 1 && stagesActual > 0 && (
        <circle
          className={styles.knob_jawIndicator_cornerDot}
          cx={cornerDotStartPosition.x}
          cy={cornerDotStartPosition.y}
          r={filledWidth / 2}
        />
      )}
      {stagesAll > 1 && stagesActual > 0 && (
        <circle
          className={
            overcorrectionStage === null
              ? styles.knob_jawIndicator_cornerDot
              : styles.knob_jawIndicator_overcorrection
          }
          cx={cornerDotEndPosition.x}
          cy={cornerDotEndPosition.y}
          r={filledWidth / 2}
        />
      )}
      {overcorrectionStage !== null && (
        <Arc
          className={styles.knob_jawIndicator_overcorrection}
          center={center}
          radius={radius}
          startDeg={overcorrectionStartDeg}
          endDeg={overcorrectionEndDeg}
          width={filledWidth}
        />
      )}
      <TextLabel
        className={styles.knob_jawIndicator_label}
        position={GeometryUtil.toPoint(leftLabelPosition, center)}
      >
        {label}
      </TextLabel>
      <TextLabel
        className={styles.knob_jawIndicator_label}
        position={GeometryUtil.toPoint(rightLabelPosition, center)}
      >
        {label}
      </TextLabel>
    </g>
  );
};

export default JawIndicator;
