import { GeometryLoader } from '@data/loaders';
import { Dictionary } from '@types';
import { BufferGeometry, CatmullRomCurve3, TubeGeometry, Vector2, Vector3 } from 'three';
import {
  AttachmentObjectDescription,
  BiterampObjectDescription,
  ElasticObjectDescription,
  GingivaObjectDescription,
  JawType,
  ObjectDescription,
  ObjectTypes,
  PonticObjectDescription,
  ToothObjectDescription,
  ToothOverlayObjectDescription,
  ToothRootOverlayObjectDescription,
  ToothRootObjectDescription
} from '@data/models';
import { computeGingivaUV, computeToothUV } from './utils';

class GeometryProvider {
  private readonly _geometryLoader: GeometryLoader;
  private _meshesById: Dictionary<BufferGeometry>;
  private _iprLineMeshes: Dictionary<BufferGeometry>;
  private _allObjects: Dictionary<ObjectDescription>;

  constructor (allObjects: Dictionary<ObjectDescription>) {
    this._geometryLoader = new GeometryLoader();
    this._meshesById = {};
    this._iprLineMeshes = {};
    this._allObjects = allObjects;
  }

  async init () {
    await Promise.all(Object.values(this._allObjects).map(async (object: ObjectDescription) => {
      if (object.type !== ObjectTypes.IPR && object.type !== ObjectTypes.Grid) {
        const geometry = await this._loadGeometryByType(object);
        if (!geometry) {
          console.error(`Can't load geometry for object type ${object.type} with ID ${object.id}`);
          return;
        }

        this._meshesById[object.id] = geometry;
      }
    }));

    this._iprLineMeshes[JawType.Upper] = await this._loadIPRLineGeometry(JawType.Upper);
    this._iprLineMeshes[JawType.Lower] = await this._loadIPRLineGeometry(JawType.Lower);
  }

  getGeometryByDescription (desc: ObjectDescription): BufferGeometry {
    if (desc.type === ObjectTypes.IPR) {
      return this._iprLineMeshes[desc.jaw];
    }

    return this._meshesById[desc.id];
  }

  private async _loadGeometryByType (object: ObjectDescription): Promise<BufferGeometry | null> {
    switch (object.type) {
      case ObjectTypes.Attachment:
        return await this._loadAttachmentGeometry(object as AttachmentObjectDescription);
      case ObjectTypes.Elastic:
        return await this._loadElasticGeometry(object as ElasticObjectDescription);
      case ObjectTypes.Biteramp:
        return await this._loadBiteRampGeometry(object as BiterampObjectDescription);
      case ObjectTypes.Tooth:
        return await this._loadToothGeometry(object as ToothObjectDescription);
      case ObjectTypes.Pontic:
        return await this._loadPonticGeometry(object as PonticObjectDescription);
      case ObjectTypes.Gingiva:
        return await this._loadGingivaGeometry(object as GingivaObjectDescription);
      case ObjectTypes.ToothOverlay:
        return await this._loadToothOverlayGeometry(object as ToothOverlayObjectDescription);
      case ObjectTypes.ToothRootOverlay:
        return await this._loadRootOverlayGeometry(object as ToothRootOverlayObjectDescription);
      case ObjectTypes.ToothRoot:
        return await this._loadToothRootGeometry(object as ToothRootObjectDescription);
      default:
        return null;
    }
  }

  private async _loadAttachmentGeometry (object: AttachmentObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    result.computeVertexNormals();
    return result;
  }

  private async _loadPonticGeometry (object: PonticObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    result.computeVertexNormals();
    return result;
  }

  private async _loadBiteRampGeometry (object: BiterampObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    result.computeVertexNormals();
    return result;
  }

  private async _loadElasticGeometry (object: ElasticObjectDescription): Promise<BufferGeometry> {
    const path = new CatmullRomCurve3(
      object.points.map((point: number[]) => new Vector3(point[0], point[1], point[2]))
    );
    const extrusionSegments = 25;
    const radius = 0.10;
    const radiusSegments = 5;
    const result = new TubeGeometry(path, extrusionSegments, radius, radiusSegments, false);
    result.computeVertexNormals();
    return result;
  }

  private async _loadToothGeometry (object: ToothObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    computeToothUV(result, new Vector2(0.04, 0.04));
    result.computeVertexNormals();
    return result;
  }

  private async _loadToothRootGeometry (object: ToothRootObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    computeToothUV(result, new Vector2(0.04, 0.04));
    result.computeVertexNormals();
    return result;
  }

  private async _loadToothOverlayGeometry (object: ToothOverlayObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    computeToothUV(result, new Vector2(0.04, 0.04));
    result.computeVertexNormals();
    return result;
  }

  private async _loadRootOverlayGeometry (object: ToothRootOverlayObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    computeToothUV(result, new Vector2(0.04, 0.04));
    result.computeVertexNormals();
    return result;
  }

  private async _loadGingivaGeometry (object: GingivaObjectDescription): Promise<BufferGeometry> {
    const result = (await this._geometryLoader.loadByType(object.url, 'drc')) as BufferGeometry;
    computeGingivaUV(result, new Vector2(0.02, 0.02));
    result.computeVertexNormals();
    return result;
  }

  private async _loadIPRLineGeometry (jawType: JawType): Promise<BufferGeometry> {
    const startPoint = new Vector3(0, 0, 0);
    const endPoint = jawType === JawType.Upper
      ? new Vector3(0, 8, 15)
      : new Vector3(0, -8, 15);
    const lineGeometry = new BufferGeometry();
    lineGeometry.setFromPoints([startPoint, endPoint]);
    // lineGeometry.computeVertexNormals();

    return lineGeometry;
  }
}

export default GeometryProvider;
