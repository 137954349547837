import {getOcclusionDistances} from '@data/providers/SceneDataProvider/utils/getOcclusions';
import { RawFile } from '../types';
import {getNameByFile, getNumberOnly} from '@data/providers/Viewer/utils';
import {IOcclusionFeatureConfig, ITreatmentPlan} from '@data/models';

export type JawDisplacementOcclusion = {
  toothlikeId?: string,
  filename?: string,
  distances: number[]
};

export const getJawDisplacementOcclusions = async (occlusionFiles: RawFile[] | undefined, plan: ITreatmentPlan, configProvider: IOcclusionFeatureConfig): Promise<JawDisplacementOcclusion[]|null> => {
  if (!occlusionFiles) return null;
  if (plan.jawDisplacementStage) {
    return await Promise.all(getJdOcclusionFiles(plan, occlusionFiles).map(async (occFile) => {
      return {toothlikeId: getToothlikeIdFromPlan(plan, occFile), distances: await getOcclusionDistances(occFile, configProvider)};
    }));
  } else {
    // legacy
    const jawDisplacementOcclusions = occlusionFiles?.filter(o => o.filename.includes('occlusion-jd'));
    return await Promise.all(jawDisplacementOcclusions?.map(async (o) => {
      return {filename: getNameByFile(o.filename), distances: await getOcclusionDistances(o, configProvider)};
    }));
  }
};

export const getJawDisplacementOcclusion = (jawDisplacementOcclusionFiles: JawDisplacementOcclusion[] | null, toothId: string) => {
  return jawDisplacementOcclusionFiles?.find(occ => {
    if(occ.toothlikeId) return occ.toothlikeId === removeFileExtension(toothId);
    // legacy
    if(occ.filename) return occ.filename.includes(`${getNumberOnly(toothId)}`);
  })?.distances;
};

export const getJawDisplacementTransform = (plan: ITreatmentPlan) => {
  // supporting legacy version, legacy jawDisplacement is an array of numbers
  return plan.jawDisplacementStage ? plan.jawDisplacementStage.transform : (plan.jawDisplacement || null);
};

const getToothlikeIdFromPlan = (plan: ITreatmentPlan, occlusionFile: RawFile) => {
  return plan.jawDisplacementStage.occlusions.find(planOcc => planOcc.occlusion === occlusionFile.filename)?.toothlikeId;
};

const getJdOcclusionFiles = (plan: ITreatmentPlan, occlusions: RawFile[]) => {
  const planOcclusionFilenames = plan.jawDisplacementStage.occlusions.map(o => o.occlusion);
  return occlusions.map(o => ({...o, filename: getNameByFile(o.filename)}))
    .filter(o => planOcclusionFilenames.includes(o.filename));
};

const removeFileExtension = (filename: string) => filename.substring(0, filename.lastIndexOf('.')) || filename;
