import SceneWidget from './Scene';
import PatientPhotos from './PatientPhotos';
import TeethMovement from './TeethMovement';
import MobileMenu from './MobileMenu';

export {
  SceneWidget,
  PatientPhotos,
  TeethMovement,
  MobileMenu
};
