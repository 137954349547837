import { IIPR, IJaw, IPRObjectDescription, IStage, ITooth, JawType, ObjectDescription } from '@data/models';
import { Dictionary } from '@types';
import { cloneDeep } from 'lodash';
import { getNameByFile } from './getNameByFile';


const fillJawObjects = (
  jaw: IJaw,
  objects: Dictionary<ObjectDescription>,
  jawType: JawType,
  stageId: string,
  fileNames: string[]
) => {
  const gingivaName = getNameByFile(fileNames[jaw.gingiva.file]);
  const gingivaObject = objects[gingivaName];
  gingivaObject.visible = true;
  gingivaObject.transform = jaw.gingiva.transform;
  gingivaObject.jaw = jawType;

  jaw.teeth.forEach((tooth: ITooth) => {
    const toothName = getNameByFile(fileNames[tooth.file]);
    const toothObject = objects[toothName];
    toothObject.visible = true;
    toothObject.transform = tooth.transform;
    toothObject.jaw = jawType;
  });

  jaw.attachments.forEach((attachment: any) => {
    const attachmentName = getNameByFile(fileNames[attachment.file]);
    const attachmentObject = objects[attachmentName];
    attachmentObject.visible = true;
    attachmentObject.transform = attachment.transform;
    attachmentObject.jaw = jawType;
  });

  jaw.elastics?.forEach((elastic) => {
    const elasticName = `${elastic.id}--${stageId}`;
    const elasticObject = objects[elasticName];
    elasticObject.visible = true;
    elasticObject.jaw = jawType;
  });

  jaw.biteRamps?.forEach((biteRamp: any) => {
    const biterampName = getNameByFile(fileNames[biteRamp.file]);
    const biterampObject = objects[biterampName];
    biterampObject.visible = true;
    biterampObject.transform = biteRamp.transform;
    biterampObject.jaw = jawType;
  });

  jaw.pontics?.forEach((pontic: any) => {
    const ponticName = getNameByFile(fileNames[pontic.file]);
    const ponticObject = objects[ponticName];
    ponticObject.visible = true;
    ponticObject.transform = pontic.transform;
    ponticObject.jaw = jawType;
  });

  jaw.iprs
    .forEach((ipr: IIPR, index: number) => {
      if ((ipr.appliedOnStage + 1) <= parseInt(stageId, 10)) {
        const firstToothName = getNameByFile(fileNames[ipr.adjacentTeethFiles[0]]);
        const secondToothName = getNameByFile(fileNames[ipr.adjacentTeethFiles[1]]);

        const iprName = `ipr-${jawType === JawType.Upper ? 'u': 'l'}-${stageId}-${index + 1}`;
        const iprObject = objects[iprName] as IPRObjectDescription;

        iprObject.transform = cloneDeep(objects[firstToothName].transform);
        iprObject.jaw = jawType;
        iprObject.visible = true;
        iprObject.firstToothName = firstToothName;
        iprObject.secondToothName = secondToothName;
      }
    });
};

export const getObjectsForStage = (
  stage: IStage,
  allObjects: Dictionary<ObjectDescription>,
  fileNames: string[]
): ObjectDescription[] => {
  const stageObjects = cloneDeep(allObjects);

  fillJawObjects(stage.upper, stageObjects, JawType.Upper, stage.id, fileNames);
  fillJawObjects(stage.lower, stageObjects, JawType.Lower, stage.id, fileNames);

  return Object.values(stageObjects);
};
