import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@utils';
import { useCompanyColorScheme } from '../../../../hooks/useCompanyColorScheme';

type ViewportPhotoProps = {
  opacity: number;
  url?: string;
  title: string;
  colorScheme?: ReturnType<typeof useCompanyColorScheme>[0] | undefined;
  onCloseIconClick?: () => void;
};

const ViewportPhoto = ({
  opacity,
  url,
  title,
  colorScheme,
  onCloseIconClick,
}: ViewportPhotoProps) => {
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(
    null
  );
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const { t } = useTranslation();

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true);
    setDragStart({
      x: event.clientX - position.x,
      y: event.clientY - position.y,
    });
  };

  const handleMouseMove = throttle((event: MouseEvent) => {
    if (dragging && dragStart) {
      const newX = event.clientX - dragStart.x;
      const newY = Math.max(0, event.clientY - dragStart.y); // Ограничение на 46 пикселей от верхнего края
      setPosition({
        x: newX,
        y: newY,
      });
    }
  }, 16);

  const handleMouseUp = () => {
    setDragging(false);
    setDragStart(null);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault(); // Отключаем стандартное поведение браузера
    const touch = event.touches[0];
    setDragging(true);
    setDragStart({
      x: touch.clientX - position.x,
      y: touch.clientY - position.y,
    });
  };

  const handleTouchMove = throttle((event: TouchEvent) => {
    event.preventDefault(); // Отключаем стандартное поведение браузера
    if (dragging && dragStart) {
      const touch = event.touches[0];
      const newX = touch.clientX - dragStart.x;
      const newY = Math.max(0, touch.clientY - dragStart.y); // Ограничение на 46 пикселей от верхнего края
      setPosition({
        x: newX,
        y: newY,
      });
    }
  }, 8);

  const handleTouchEnd = () => {
    setDragging(false);
    setDragStart(null);
  };

  useEffect(() => {
    if (dragging) {
      document.body.style.overflow = 'hidden'; // Отключаем прокрутку страницы
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      document.body.style.overflow = ''; // Восстанавливаем прокрутку страницы при размонтировании
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [dragging]);

  return (
    <div
      style={{
        left: position.x,
        top: position.y,
        pointerEvents: 'none',
      }}
      className="fixed z-40 w-screen h-[100dvh] bottom-0 left-0 grid place-content-center select-none"
    >
      <div className="pt-[48px] lg:w-[985px] max-w-full w-screen h-[100dvh]">
        <div
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          className={cn(
            'w-full h-8 px-2 bg-deepSpace cursor-grab  flex items-center justify-between font-bold text-lg',
            colorScheme && 'bg-primary-color text-white',
            colorScheme?.light && 'text-gray-400'
          )}
          style={{
            cursor: dragging ? 'grabbing' : 'grab',
            pointerEvents: 'auto',
          }}
        >
          <div className="flex items-center gap-3">
            <img
              className={cn(
                colorScheme?.light && 'invert-[.75] drop-shadow-lg'
              )}
              src="/assets/images/photos_icon.svg"
              alt="Patient Photos"
            />
            <span>{t(title)}</span>
          </div>
          <div
            className={cn(
              'w-6 flex items-center justify-center rounded-md bg-white bg-opacity-0 hover:bg-opacity-10',
              colorScheme?.light && 'invert-[.75] drop-shadow-lg'
            )}
            onClick={onCloseIconClick}
          >
            X
          </div>
        </div>
        <div
          className="flex justify-center items-center w-full max-h-[calc(100dvh-77px)] h-auto bg-gray-200"
          style={{
            opacity,
          }}
        >
          <img
            className="max-h-[calc(100dvh-80px)] object-contain pointer-events-none"
            draggable={false}
            src={url}
            alt="patient photo"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewportPhoto;
