import Color from 'color';
import { useEffect, useState } from 'react';

type ColorScheme = {
  primary?: string;
  secondary?: string;
  light?: boolean;
  gradient?: string;
  logo?: string;
};

export const useGetCompanyColorScheme = (data: any) => {
  const [companyColorScheme, setCompanyColorScheme] = useState<
    ColorScheme | undefined
  >();

  useEffect(() => {
    if (data) {
      const _companyColorScheme: ColorScheme = {
        primary: data.primary_color,
        secondary: data.secondary_color,
        gradient: data.gradient_color,
        logo: data.logo,
        light: Color(parseRGB(data.primary_color)).isLight(),
      };

      setCompanyColorScheme(_companyColorScheme);
    }
  }, [data]);

  return companyColorScheme;
};

const parseRGB = (rgb?: string) => {
  if (!rgb) return;
  return `rgb(${rgb})`;
};
