import { DirectionalLight as ThreeDirectionalLight } from 'three';
import BaseLight from './BaseLight';
import { ColorConverter } from '@utils';
import { LightTypes, DirectionalLightDescription } from '@data/models';

export class DirectionalLight extends BaseLight {
  constructor(description: DirectionalLightDescription) {
    super(description.id, description.name, LightTypes.Directional);

    this._light = new ThreeDirectionalLight(
      ColorConverter.numberToThreeColor(description.color),
      description.intensity,
    );

    this._light.position.set(description.position.x, description.position.y, description.position.z);
  }
}
