import { supabase } from '../../supabase-client';

export default function getCompany({
  companyId,
  subdomain,
}: {
  companyId: string | undefined;
  subdomain: string;
}) {
  return supabase
    .from('companies')
    .select()
    .or(`company_id.eq.${companyId},short_name.eq.${subdomain}`)
    .throwOnError()
    .single()
    .then((result) => result.data);
}
