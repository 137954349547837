import BaseLight from './BaseLight';
import { HemisphereLight as ThreeHemisphereLight } from 'three';
import { HemisphereLightDescription, LightTypes } from '@data/models';

export class HemisphereLight extends BaseLight {
  constructor (description: HemisphereLightDescription) {
    super(description.id, description.name, LightTypes.Hemisphere);

    this._light = new ThreeHemisphereLight(description.skyColor, description.groundColor, description.intensity);
    this._light.name = description.name;
  }
}
