import React, { useEffect, useRef, useState, WheelEvent } from 'react';
import Cursor from './Cursor';
import InfoCircle from './InfoCircle';
import JawIndicator from './JawIndicator';
import Scale from './Scale';
import styles from './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackwardStep,
  faForwardStep,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import JawDisplacementIcon from './images/jaw_displacement.svg?react';
import classNames from 'classnames';
import { Point } from '@data/models';

interface IKnobProps {
  size: number;
  stagesAll: number;
  stagesUpper: number;
  stagesLower: number;
  stageCurrent: number;
  onStageChange: (stage: number) => void;
  onAnimationStartClick: (e: React.MouseEvent) => void;
  onAnimationStopClick: (e: React.MouseEvent) => void;
  onAnimationBackwardClick: (e: React.MouseEvent) => void;
  onAnimationForwardClick: (e: React.MouseEvent) => void;
  onShowStartStageClick: (e: React.MouseEvent) => void;
  onShowFinalStageClick: (e: React.MouseEvent) => void;
  onJawDisplacementClick: (e: React.MouseEvent) => void;
  isJawDisplacementActive: boolean;
  isJawDisplacementPresent: boolean;
  upperStageOvercorrection: number | null;
  lowerStageOvercorrection: number | null;
  isPlaying: boolean;
  firstStage: number;
}

const Knob = ({
  size,
  stagesAll,
  stagesUpper,
  stagesLower,
  stageCurrent,
  onStageChange,
  onAnimationBackwardClick,
  onAnimationForwardClick,
  onAnimationStartClick,
  onAnimationStopClick,
  onShowStartStageClick,
  onShowFinalStageClick,
  onJawDisplacementClick,
  isJawDisplacementActive,
  isJawDisplacementPresent,
  upperStageOvercorrection,
  lowerStageOvercorrection,
  isPlaying,
  firstStage,
}: IKnobProps) => {
  const knobRootRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState<Point>({ x: 0, y: 0 });
  const center = {
    x: size / 2,
    y: size / 2,
  };

  const fullR = size / 2;

  const infoCircleR = fullR * 0.45;
  const lowerJawR = fullR * 0.55;
  const upperJawR = fullR * 0.75;
  const scaleR = fullR * 0.65;
  const cursorR = fullR * 0.92;

  function handleWheel(e: WheelEvent<HTMLDivElement>) {
    e.stopPropagation();
    onStageChange(stageCurrent + Math.sign(e.deltaY));
  }

  const isLastStage = stageCurrent >= stagesAll - 1;

  const onResizeHandler = () => {
    if (!knobRootRef.current) {
      return;
    }
    const rect = knobRootRef.current.getBoundingClientRect();
    setOffset({
      x: rect.x,
      y: rect.y,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);
    onResizeHandler();
    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  return (
    <div className={styles.knob} onWheel={handleWheel} ref={knobRootRef}>
      <svg height={size} width={size}>
        <InfoCircle
          center={center}
          radius={infoCircleR}
          stagesUpper={stagesUpper}
          stagesLower={stagesLower}
          onShowFinalStageClick={onShowFinalStageClick}
          onShowStartStageClick={onShowStartStageClick}
        />
        <JawIndicator
          size={size}
          center={center}
          radius={lowerJawR}
          label="L"
          stagesAll={stagesAll}
          stagesActual={stagesLower}
          overcorrectionStage={lowerStageOvercorrection}
          firstStage={firstStage}
        />
        <JawIndicator
          size={size}
          center={center}
          radius={upperJawR}
          label="U"
          stagesAll={stagesAll}
          stagesActual={stagesUpper}
          overcorrectionStage={upperStageOvercorrection}
          firstStage={firstStage}
        />
        <Scale
          size={size}
          center={center}
          radius={scaleR}
          stagesAll={stagesAll}
          stageCurrent={stageCurrent}
          onStageClick={onStageChange}
          offset={offset}
        />
        <Cursor
          size={size}
          center={center}
          radius={cursorR}
          stagesAll={stagesAll}
          stageCurrent={stageCurrent}
          lowerStageOvercorrection={lowerStageOvercorrection}
          upperStageOvercorrection={upperStageOvercorrection}
        />
      </svg>
      <div className={styles.knob_buttons}>
        <div
          className={classNames(styles.knob_buttons_jawDisplacement, {
            [styles.__disabled]: !isLastStage || !isJawDisplacementPresent,
            [styles.__active]: isJawDisplacementActive,
          })}
          onClick={(e) => {
            e.stopPropagation();
            if (isLastStage && isJawDisplacementPresent) {
              onJawDisplacementClick(e);
            }
          }}
        >
          <div
            className={classNames(styles.knob_buttons_jawDisplacement_icon, {
              [styles.__active]: isJawDisplacementActive,
            })}
          >
            <JawDisplacementIcon />
          </div>
        </div>
        <FontAwesomeIcon
          className={styles.knob_buttons_button}
          icon={faBackwardStep}
          size="lg"
          onClick={(e) => {
            e.stopPropagation();
            onAnimationBackwardClick(e);
          }}
        />
        <FontAwesomeIcon
          className={styles.knob_buttons_button}
          icon={isPlaying ? faPause : faPlay}
          size="2x"
          onClick={(e) => {
            e.stopPropagation();
            isPlaying ? onAnimationStopClick(e) : onAnimationStartClick(e);
          }}
        />
        <FontAwesomeIcon
          className={styles.knob_buttons_button}
          icon={faForwardStep}
          size="lg"
          onClick={(e) => {
            onAnimationForwardClick(e);
          }}
        />
      </div>
    </div>
  );
};

export default Knob;
