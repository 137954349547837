import React from 'react';
import Slider, { SliderProps } from 'rc-slider';

const StepSlider = (props: SliderProps) => {
  return (
    <div className="stepSliderStyles">
      <Slider
        {...props}
      />
    </div>
  );
};

export default StepSlider;
