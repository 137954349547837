import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.scss';
import {
  getColorTextureHistogram, getDepths
} from '@data/providers/SceneDataProvider/utils/getOcclusions';
import useWindowInnerDimensions from '../../../hooks/useWindowInnerDimensions';
import {IOcclusionFeatureConfig} from '@data/models';

const DEFAULT_HISTOGRAM_HEIGHT = 185;
const DEFAULT_HISTOGRAM_WIDTH = 11;
const MIN_HEIGHT_TO_FIT_HISTOGRAM = 450;

interface OcclusionHistogramProps {
  configProvider: IOcclusionFeatureConfig
}

const OcclusionHistogram = ({configProvider}: OcclusionHistogramProps) => {
  const [depths, setDepths] = useState<{ depth: number, position: number }[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerDimensions = useWindowInnerDimensions();

  useEffect(() => {
    const canvas = getColorTextureHistogram(DEFAULT_HISTOGRAM_WIDTH, innerDimensions.height < MIN_HEIGHT_TO_FIT_HISTOGRAM ? innerDimensions.height/3 : DEFAULT_HISTOGRAM_HEIGHT, configProvider);
    if (containerRef.current) {
      containerRef.current.innerHTML = '';  // Clear existing content
      containerRef.current.appendChild(canvas);  // Append the new canvas
    }
    const depths = getDepths(configProvider);
    setDepths(depths);
  }, [innerDimensions.height]);

  return (
    <div className={styles.histogram_container}>
      <div className={styles.histogram_title}>Occlusion map</div>
      <div className={styles.histogram_titleTop}>Gap, mm</div>
      <div className={styles.histogram_item}>
        <div className={styles.canvasContainer} ref={containerRef}></div>
        <div className={styles.histogram_labels}>
          {depths && depths.map((depth, index) =>
            <div className={styles.histogram_label} style={{top: depth.position * 100 + "%"}} key={index}>
              <span>{depth.depth.toFixed(1)}</span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.histogram_titleBottom}>Collision</div>
    </div>
  );
};

export default OcclusionHistogram;