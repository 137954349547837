import React from 'react';
import { Point } from '@data/models';
import { getArcDescription } from './utils';


interface IArcProps {
  className?: string;
  center: Point;
  radius: number;
  startDeg: number;
  endDeg: number;
  onClick?: () => void;
  onMouseMove?: () => void;
  onMouseUp?: () => void;
  width: number;
}

const Arc = ({
  className,
  center,
  radius,
  startDeg,
  endDeg,
  onClick,
  onMouseMove,
  onMouseUp,
  width
}: IArcProps) => (
  <path
    className={className}
    d={getArcDescription(center, radius - width / 2, width, startDeg, endDeg)}
    strokeLinejoin="round"
    onPointerDown={e => {
      // releasePointerCapture is required for pointerdown even to be triggered on other path elements
      (e.target as any).releasePointerCapture(e.pointerId);
      onClick?.();
    }}
    onPointerMove={onMouseMove}
    onMouseUp={onMouseUp}
  />
);

export default Arc;
