import {
  AttachmentObjectDescription,
  BiterampObjectDescription,
  ElasticObjectDescription,
  GingivaObjectDescription,
  GridObjectDescription,
  IGridFeatureConfig,
  IIPR,
  IPRObjectDescription,
  IStage,
  ITreatmentPlan,
  JawType,
  ObjectDescription,
  ObjectTypes,
  PonticObjectDescription,
  ToothObjectDescription,
  ToothRootObjectDescription
} from '@data/models';
import { getNameByFile } from './getNameByFile';
import { Dictionary } from '@types';
import { RawFile } from '../types';
import { cloneDeep } from 'lodash';
import { ConfigProvider } from '@data/providers';


const getJawTypeByGingivaName = (name: string): JawType | undefined => {
  // @ts-ignore
  const [_, type] = name.match(/-[lu]-/);
  switch (type) {
    case 'l':
      return JawType.Lower;
    case 'u':
      return JawType.Upper;
  }
  return undefined;
};

const getJawTypeByToothName = (name: string): JawType | undefined => {
  // @ts-ignore
  const [_, quarter] = name.match(/-([0-9])([0-9])/);
  switch (quarter) {
    case '1':
    case '2':
      return JawType.Upper;
    case '3':
    case '4':
      return JawType.Lower;
  }

  return undefined;
};

export const getGingivaObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): GingivaObjectDescription => {
  const gingivaName = getNameByFile(file);
  const jawType = getJawTypeByGingivaName(gingivaName);

  return {
    id: gingivaName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Gingiva,
    jaw: jawType || JawType.Upper,
    visible: false,
    transform
  };
};

export const getToothObject = (
  file: string,
  transform: number[] | null,
  occlusions: number[] | null,
  jaw?: JawType,
  data?: any
): ToothObjectDescription => {
  const toothName = getNameByFile(file);
  const jawType = getJawTypeByToothName(toothName);

  return {
    id: toothName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Tooth,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper,
    occlusionVisible: false,
    occlusionDistances: occlusions
  };
};

export const getToothRootObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): ToothRootObjectDescription => {
  const toothRootName = getNameByFile(file);
  const jawType = getJawTypeByToothName(toothRootName);

  return {
    id: toothRootName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.ToothRoot,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper
  };
};

export const getAttachmentObject = (
  file: string,
  transform: number[] | null,
  toothId: number,
  jaw?: JawType,
  data?: any
): AttachmentObjectDescription => {
  const attachmentName = getNameByFile(file);
  const jawType = getJawTypeByToothName(attachmentName);

  return {
    id: attachmentName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Attachment,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper,
    toothId
  };
};

export const getElasticObject = (
  id: string,
  points: number[][],
  number: number,
  elasticType: 'Button' | 'Hook',
  jaw?: JawType
): ElasticObjectDescription => {

  return {
    id,
    points,
    type: ObjectTypes.Elastic,
    number,
    visible: false,
    transform: null,
    jaw: jaw || JawType.Upper,
    elasticType,
    toothId: number
  };
};

export const getBiterampObject = (
  file: string,
  transform: number[] | null,
  toothId: number,
  jaw?: JawType,
  data?: any
): BiterampObjectDescription => {
  const name = getNameByFile(file);
  const jawType = getJawTypeByToothName(name);

  return {
    id: name,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Biteramp,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper,
    toothId
  };
};

export const getPonticObject = (
  file: string,
  transform: number[] | null,
  occlusions: number[] | null,
  toothId: number,
  jaw?: JawType,
  data?: any
): PonticObjectDescription => {
  const ponticName = getNameByFile(file);

  return {
    id: ponticName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Pontic,
    visible: false,
    transform,
    jaw: JawType.Upper,
    toothId,
    occlusionVisible: false,
    occlusionDistances: occlusions
  };
};

export const getIPRObject = (
  id: string,
  label: string,
  jaw?: JawType
): IPRObjectDescription => {
  const labelValue = parseFloat(label);

  return {
    id,
    type: ObjectTypes.IPR,
    visible: false,
    label: labelValue ? labelValue.toFixed(1) : '',
    jaw: jaw || JawType.Upper,
    transform: null,
    firstToothName: '',
    secondToothName: '',
  };
};

export const getGridObject = (
  size: number
): GridObjectDescription => {
  return {
    id: `grid-${size}`,
    type: ObjectTypes.Grid,
    visible: true,
    transform: null,
    jaw: JawType.Upper,
    size
  };
};

export const getObjectByFile = (file: string, data: any, toothId: number): ObjectDescription => {
  const name = getNameByFile(file);

  if (name.includes('gingiva')) {
    return getGingivaObject(file, null, undefined, data);
  }

  if (name.includes('attach')) {
    return getAttachmentObject(file, null, toothId, undefined, data);
  }

  if (name.includes('biteramp')) {
    return getBiterampObject(file, null, toothId, undefined, data);
  }

  if (name.includes('pontic')) {
    return getPonticObject(file, null, null, toothId,undefined, data);
  }

  if (name.includes('root')) {
    return getToothRootObject(file, null, undefined, data);
  }

  return getToothObject(file, null, null, undefined, data);
};

export const getAllObjects = (
  plan: ITreatmentPlan,
  files: RawFile[],
  stages: IStage[],
  configProvider: ConfigProvider
): Dictionary<ObjectDescription> => {
  const baseObjects: Dictionary<ObjectDescription> = plan.files
    .reduce((result: Dictionary<ObjectDescription>, file: string) => {
      const filename = getNameByFile(file.replace('.stl', '.drc'));
      const [foundFile] = files.filter((item) => getNameByFile(item.filename) === filename);
      const obj = getObjectByFile(file, foundFile.data, 0);
      result[obj.id] = obj;
      obj.visible = false;
      return result;
    }, {});

  const gridFeatureConfig = configProvider.getFeatureConfig<IGridFeatureConfig>('grid');
  if (gridFeatureConfig && gridFeatureConfig.enabled) {
    gridFeatureConfig.sizes.forEach((size: number) => {
      const gridObject = getGridObject(size);
      baseObjects[gridObject.id] = gridObject;
    });
  }

  const elasticObjects = stages
    .filter((stage) => stage.enabled)
    .flatMap((stage: IStage) => {
      const createElasticObject = (elastic: any, jawType: JawType) => {
        let points;
        if (elastic.points) {
          // This is a previous version of exporter with flipped X axis.
          // By this, we need to flip X axis (first number in array[3]).
          points = elastic.points.map((point: number[]) => [-point[0], point[1], point[2]]);
        } else {
          // This is a new version of exporter. We don't need to do anything with coords.
          points = elastic.coords;
        }
        return getElasticObject(
          `${elastic.id}--${stage.id}`,
          points,
          elastic.number,
          elastic.type,
          jawType
        );
      };

      const result: ObjectDescription[] = [];

      if (stage.upper && stage.upper.elastics) {
        result.push(...stage.upper.elastics.map((elastic) => createElasticObject(elastic, JawType.Upper)));
      }

      if (stage.lower && stage.lower.elastics) {
        result.push(...stage.lower.elastics.map((elastic) => createElasticObject(elastic, JawType.Lower)));
      }

      return result;
    });

  const iprObjects = stages
    .filter((stage) => stage.enabled)
    .flatMap((stage: IStage) => {
      const createIprObject = (ipr: IIPR, index: number, jawType: JawType) => {
        return getIPRObject(
          `ipr-${jawType === JawType.Upper ? 'u' : 'l'}-${stage.id}-${index+1}`,
          String(ipr.spacing),
          jawType
        );
      };

      const result: ObjectDescription[] = [];

      if (stage.upper && stage.upper.iprs) {
        result.push(...stage.upper.iprs.map((ipr, index) => createIprObject(ipr, index, JawType.Upper)));
      }

      if (stage.lower && stage.lower.iprs) {
        result.push(...stage.lower.iprs.map((ipr, index) => createIprObject(ipr, index, JawType.Lower)));
      }

      return result;
    });

  const toothOverlayObjects = Object
    .values(baseObjects)
    .filter((obj) => obj.type === ObjectTypes.Tooth)
    .map((obj) => {
      const toothOverlayDesc = cloneDeep(obj);
      toothOverlayDesc.type = ObjectTypes.ToothOverlay;
      toothOverlayDesc.visible = true;
      toothOverlayDesc.id = `overlay-${toothOverlayDesc.id}`;
      return toothOverlayDesc;
    });

  const toothRootOverlayObjects = Object
    .values(baseObjects)
    .filter((obj) => obj.type === ObjectTypes.ToothRoot)
    .map((obj) => {
      const toothOverlayDesc = cloneDeep(obj);
      toothOverlayDesc.type = ObjectTypes.ToothRootOverlay;
      toothOverlayDesc.visible = true;
      toothOverlayDesc.id = `overlay-${toothOverlayDesc.id}`;
      return toothOverlayDesc;
    });

  return {
    ...baseObjects,
    ...iprObjects.reduce((res: Dictionary<ObjectDescription>, desc) => {
      res[desc.id] = desc;
      return res;
    }, {}),
    ...elasticObjects.reduce((res: Dictionary<ObjectDescription>, desc) => {
      res[desc.id] = desc;
      return res;
    }, {}),
    ...toothOverlayObjects.reduce((res: Dictionary<ObjectDescription>, desc) => {
      res[desc.id] = desc;
      return res;
    }, {}),
    ...toothRootOverlayObjects.reduce((res: Dictionary<ObjectDescription>, desc) => {
      res[desc.id] = desc;
      return res;
    }, {})
  };
};
