import { STLLoader as ThreeSTLLoader } from 'three/examples/jsm/loaders/STLLoader';
import BaseLoader from '../BaseLoader';

export class STLLoader extends BaseLoader {
  constructor(manager: any) {
    super(manager);

    this.loader = new ThreeSTLLoader(manager);
  }
}
