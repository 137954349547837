import {
  Box3,
  Euler,
  Matrix4,
  Object3D,
  Scene,
  Vector3
} from 'three';
import { ObjectDescription, ObjectTypes } from '@data/models';
import { Dictionary } from '@types';


class BaseObject {
  protected _mesh: Object3D;
  protected _initialPosition: Vector3;
  protected _initialScale: Vector3;
  protected _initialRotation: Euler;
  protected _type: ObjectTypes;

  constructor (mesh: Object3D, type: ObjectTypes) {
    this._mesh = mesh;
    this._type = type;

    this._initialPosition = new Vector3();
    this._initialScale = new Vector3();
    this._initialRotation = new Euler();
  }

  get mesh () {
    return this._mesh;
  }

  get type (): ObjectTypes {
    return this._type;
  }

  get visible (): boolean {
    return this._mesh.visible;
  }

  set visible (value: boolean) {
    this._mesh.visible = value;
  }

  set id (value: string) {
    this._mesh.name = value;
  }

  async update (description: ObjectDescription, objects: Dictionary<BaseObject>) {
    if (description.transform) {
      this.resetTransformation();

      const matrix = new Matrix4();
      matrix.fromArray(description.transform);
      matrix.transpose();
      this._mesh.applyMatrix4(matrix);
    }

    this._mesh.visible = description.visible;
  }

  async updateByAnimation (objects: Dictionary<BaseObject>): Promise<void> {

  }

  setInitial (position: Vector3, rotation: Euler, scale: Vector3) {
    this._initialPosition = position;
    this._initialRotation = rotation;
    this._initialScale = scale;
  }

  resetTransformation () {
    this._mesh.position.set(
      this._initialPosition.x,
      this._initialPosition.y,
      this._initialPosition.z
    );
    this._mesh.scale.set(
      this._initialScale.x,
      this._initialScale.y,
      this._initialScale.z
    );
    this._mesh.rotation.set(
      this._initialRotation.x,
      this._initialRotation.y,
      this._initialRotation.y,
      this._initialRotation.order
    );

    this._mesh.updateMatrix();
  }

  addToScene (scene: Scene) {
    scene.add(this._mesh);
  }

  removeFromScene (scene: Scene) {
    this._mesh.visible = false;
    scene.remove(this._mesh);
  }

  getCenter (): Vector3 {
    const boundingBox = new Box3();
    boundingBox.setFromObject(this._mesh);

    const center = new Vector3();
    boundingBox.getCenter(center);

    return center;
  }
}

export default BaseObject;
