import { Camera, Euler, Vector2 } from 'three';
import { EPSILON } from '@data/constants';


const MAX_ROTATON_ANGLES = {
  x: {
    // Vertical from bottom to top.
    enabled: true,
    from: -Math.PI / 2,
    to: Math.PI / 2,
  },
  y: {
    // Horizontal from left to right.
    enabled: false,
    from: Math.PI / 4,
    to: Math.PI / 4,
  },
};

/**
 * ObjectControls
 * @constructor
 * @param camera - reference to the camera.
 * @param domElement - reference to the renderer's dom element.
 * @param objectToMove - reference the object to control.
 */
class ObjectControls {

  private _flag: any;
  private _mesh: any;

  private _maxDistance = 15;
  private _minDistance = 6;
  private _zoomSpeed = 0.5;
  private _rotationSpeed = 0.01;
  private _rotationSpeedTouchDevices = 0.01;
  private _isDragging = false;
  private _verticalRotationEnabled = true;
  private _horizontalRotationEnabled = true;
  private _zoomEnabled = true;
  private _mouseFlags = { MOUSEDOWN: 0, MOUSEMOVE: 1 };
  private _previousMousePosition = { x: 0, y: 0 };
  private _prevZoomDiff: { X: number | null, Y: number | null } = { X: null, Y: null };
  /**
   * CurrentTouches
   * length 0 : no zoom
   * length 2 : is zoomming
   */
  currentTouches: any[] = [];

  _dampingFactor = 0.05;
  rotation: Euler = new Euler();

  constructor(private camera: Camera, private domElement: HTMLCanvasElement, private objectToMove: any) {
    this._mesh = objectToMove;

    this.rotation = objectToMove.rotation.clone();

    this.domElement.addEventListener('mousedown', this.mouseDown.bind(this), false);
    this.domElement.addEventListener('mousemove', this.mouseMove.bind(this), false);
    this.domElement.addEventListener('mouseup', this.mouseUp.bind(this), false);

    domElement.addEventListener('wheel', this.wheel.bind(this), false);

    domElement.addEventListener('touchstart', this.onTouchStart.bind(this), false);
    domElement.addEventListener('touchmove', this.onTouchMove.bind(this), false);
    domElement.addEventListener('touchend', this.onTouchEnd.bind(this), false);
  }

  zoomIn() {
    this.camera.position.z -= this._zoomSpeed;
  }

  zoomOut() {
    this.camera.position.z += this._zoomSpeed;
  }

  rotateVertical(deltaMove: Vector2) {
    this.rotation.x += deltaMove.y * this._rotationSpeed;
  }

  rotateVerticalTouch(deltaMove: Vector2) {
    this.rotation.x += deltaMove.y * this._rotationSpeedTouchDevices;
  }

  rotateHorizontal(deltaMove: Vector2) {
    this.rotation.y += deltaMove.x * this._rotationSpeed;
  }

  rotateHorizontalTouch(deltaMove: Vector2) {
    this.rotation.y += deltaMove.x * this._rotationSpeedTouchDevices;
  }

  isWithinMaxAngle(delta: number, axe: keyof typeof MAX_ROTATON_ANGLES) {
    if (MAX_ROTATON_ANGLES[axe].enabled) {
      return this.isRotationWithinMaxAngles(delta, axe);
    }
    return true;
  }

  isRotationWithinMaxAngles(delta: number, axe: keyof typeof MAX_ROTATON_ANGLES) {
    const from = MAX_ROTATON_ANGLES[axe].from;
    const current = this.rotation[axe];
    const next = current + delta;
    const to = MAX_ROTATON_ANGLES[axe].to;

    return from < next && next < to;
  }

  resetMousePosition() {
    this._previousMousePosition = { x: 0, y: 0 };
  }

  mouseDown(e: any) {
    if (e.button === 0) {
      this._isDragging = true;
      this._flag = this._mouseFlags.MOUSEDOWN;

      this._previousMousePosition = { x: e.offsetX, y: e.offsetY };
    }
  }

  mouseMove(e: any) {
    if (this._isDragging) {
      const deltaMove = {
        x: e.offsetX - this._previousMousePosition.x,
        y: e.offsetY - this._previousMousePosition.y,
      };

      this._previousMousePosition = { x: e.offsetX, y: e.offsetY };

      if (this._horizontalRotationEnabled && deltaMove.x != 0) {
        // && (Math.abs(deltaMove.x) > Math.abs(deltaMove.y))) {
        // enabling this, the mesh will rotate only in one specific direction
        // for mouse movement
        if (!this.isWithinMaxAngle(deltaMove.x * this._rotationSpeed, 'y'))
          return;
        this.rotateHorizontal(new Vector2(deltaMove.x, deltaMove.y));
        this._flag = this._mouseFlags.MOUSEMOVE;
      }

      if (this._verticalRotationEnabled && deltaMove.y != 0) {
        // &&(Math.abs(deltaMove.y) > Math.abs(deltaMove.x)) //
        // enabling this, the mesh will rotate only in one specific direction for
        // mouse movement
        if (!this.isWithinMaxAngle(deltaMove.y * this._rotationSpeed, 'x'))
          return;
        this.rotateVertical(new Vector2(deltaMove.x, deltaMove.y));
        this._flag = this._mouseFlags.MOUSEMOVE;
      }
    }
  }

  mouseUp(e: MouseEvent) {
    if (e.button === 0) {
      this._isDragging = false;
    }
  }

  wheel(e: any) {
    if (!this._zoomEnabled) return;
    const delta = e.wheelDelta ? e.wheelDelta : e.deltaY * -1;
    if (delta > 0 && this.camera.position.z > this._minDistance) {
      this.zoomIn();
    } else if (delta < 0 && this.camera.position.z < this._maxDistance) {
      this.zoomOut();
    }
  }

  onTouchStart(e: any) {
    e.preventDefault();
    this._flag = this._mouseFlags.MOUSEDOWN;
    if (e.touches.length === 2) {
      this._prevZoomDiff.X = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
      this._prevZoomDiff.Y = Math.abs(e.touches[0].clientY - e.touches[1].clientY);
      this.currentTouches = new Array(2);
    } else {
      this._previousMousePosition = { x: e.touches[0].pageX, y: e.touches[0].pageY };
    }
  }

  onTouchEnd(e: any) {
    this._prevZoomDiff.X = null;
    this._prevZoomDiff.Y = null;

    /* If you were zooming out, currentTouches is updated for each finger you
     * leave up the screen so each time a finger leaves up the screen,
     * currentTouches length is decreased of a unit. When you leave up both 2
     * fingers, currentTouches.length is 0, this means the zoomming phase is
     * ended.
     */
    if (this.currentTouches.length > 0) {
      this.currentTouches.pop();
    } else {
      this.currentTouches = [];
    }
    e.preventDefault();

    this.resetMousePosition();
  }

  onTouchMove(e: any) {
    e.preventDefault();
    this._flag = this._mouseFlags.MOUSEMOVE;
    // Touch zoom.
    // If two pointers are down, check for pinch gestures.
    if (e.touches.length === 2 && this._zoomEnabled) {
      this.currentTouches = new Array(2);
      // Calculate the distance between the two pointers.
      const curDiffX = Math.abs(e.touches[0].clientX - e.touches[1].clientX);
      const curDiffY = Math.abs(e.touches[0].clientY - e.touches[1].clientY);

      if (this._prevZoomDiff && this._prevZoomDiff.X! > 0 && this._prevZoomDiff.Y! > 0) {
        if (
          curDiffX > this._prevZoomDiff.X! &&
          curDiffY > this._prevZoomDiff.Y! &&
          this.camera.position.z > this._minDistance
        ) {
          this.zoomIn();
        } else if (
          curDiffX < this._prevZoomDiff.X! &&
          this.camera.position.z < this._maxDistance &&
          curDiffY < this._prevZoomDiff.Y!
        ) {
          this.zoomOut();
        }
      }
      // Cache the distance for the next move event.
      this._prevZoomDiff.X = curDiffX;
      this._prevZoomDiff.Y = curDiffY;

      // Touch Rotate.
    } else if (this.currentTouches.length === 0) {
      this._prevZoomDiff.X = null;
      this._prevZoomDiff.Y = null;
      const deltaMove = {
        x: e.touches[0].pageX - this._previousMousePosition.x,
        y: e.touches[0].pageY - this._previousMousePosition.y,
      };
      this._previousMousePosition = { x: e.touches[0].pageX, y: e.touches[0].pageY };

      if (this._horizontalRotationEnabled && deltaMove.x != 0) {
        if ( !this.isWithinMaxAngle(deltaMove.x * this._rotationSpeedTouchDevices, 'y') ) return;
        this.rotateHorizontalTouch(new Vector2(deltaMove.x, deltaMove.y));
      }

      if (this._verticalRotationEnabled && deltaMove.y != 0) {
        if ( !this.isWithinMaxAngle(deltaMove.y * this._rotationSpeedTouchDevices, 'x') ) return;
        this.rotateVerticalTouch(new Vector2(deltaMove.x, deltaMove.y));
      }
    }
  }

  update() {
    // Reduce unnecessary updates due to delta <= EPSILON.
    let deltaX = (this.rotation.x - this._mesh.rotation.x) * this._dampingFactor;
    deltaX = Math.abs(deltaX) > EPSILON ? deltaX : 0.0;
    let deltaY = (this.rotation.y - this._mesh.rotation.y) * this._dampingFactor;
    deltaY = Math.abs(deltaY) > EPSILON ? deltaY : 0.0;

    this._mesh.rotation.x += deltaX;
    this._mesh.rotation.y += deltaY;

    if (this._mesh.rotation.y < Math.PI) {
      this._mesh.rotation.y = Math.PI * 2.0 + this._mesh.rotation.y;
      this.rotation.y = Math.PI * 2.0 + this.rotation.y;
    }

    if (this._mesh.rotation.y > Math.PI) {
      this._mesh.rotation.y = this._mesh.rotation.y - Math.PI * 2.0;
      this.rotation.y = this.rotation.y - Math.PI * 2.0;
    }
  }
}

export { ObjectControls };
