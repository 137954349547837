import { SpotLight as ThreeSpotLight } from 'three';
import BaseLight from './BaseLight';
import { ColorConverter } from '@utils';
import { LightTypes, SpotLightDescription } from '@data/models';


export class SpotLight extends BaseLight {
  constructor(description: SpotLightDescription) {
    super(description.id, description.name, LightTypes.Spot);

    this._light = new ThreeSpotLight(
      ColorConverter.numberToThreeColor(description.color),
      description.intensity,
      description.distance,
      description.angle,
      description.penumbra,
      description.decay
    );

    this._light.position.set(description.position.x, description.position.y, description.position.z);
  }
}
