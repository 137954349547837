import { HDRCubeTextureLoader as ThreeHDRLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader';
import BaseLoader from '../BaseLoader';

export class HDRLoader extends BaseLoader {
  constructor (manager?: any) {
    super(manager);

    this.loader = new ThreeHDRLoader(manager);
  }
}
