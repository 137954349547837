import { Checkbox } from '@view/components';
import React from 'react';
import { cn } from '@utils';
import styles from './styles.scss';

type MenuCheckboxProps = {
  onChange?: () => void;
  id: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  className?: string;
  checked: boolean;
  disabled?: boolean;
};

const MenuCheckbox = ({
  onChange,
  id,
  className,
  children,
  icon,
  checked,
  disabled,
}: MenuCheckboxProps) => {
  return (
    <label
      htmlFor={id}
      className={cn(
        styles.menuCheckbox,
        disabled && 'pointer-events-none opacity-60',
        className
      )}
    >
      <div className="w-[20px] flex-shrink-0 flex justify-center items-center">{icon}</div>
      <div className={styles.menuCheckbox_label}>
        {children}
        <Checkbox id={id} checked={checked} onChange={onChange} />
      </div>
    </label>
  );
};

export default MenuCheckbox;
