import React from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { cn } from '@utils';
import styles from './styles.scss';

interface IToggleProps {
  className?: string;
  label: React.ReactNode | JSX.Element | string;
  value: boolean;
  onChange: (value: boolean) => void;
  labelClassName?: string;
}

const Toggle = ({
  className,
  label,
  value,
  onChange,
  labelClassName,
}: IToggleProps) => {
  return (
    <Switch.Group>
      <div className={classNames(styles.toggle, className)}>
        <Switch.Label className={cn(styles.toggle_label, labelClassName)}>
          {label}
        </Switch.Label>

        <Switch
          checked={value}
          onChange={onChange}
          className={classNames(styles.toggle_switcher, {
            [styles.__enabled]: value,
            [styles.__disabled]: !value,
          })}
        >
          <span
            className={classNames(styles.toggle_switcher_cursor, {
              [styles.__enabled]: value,
              [styles.__disabled]: !value,
            })}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
