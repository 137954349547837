import React from 'react';
import styles from './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';

interface IErrorCoverProps {
  error?: string | React.JSX.Element;
}

const ErrorCover = ({ error }: IErrorCoverProps) => {
  if (!error) {
    return null;
  }

  return (
    <Modal bodyClassName={styles.errorCover} isOpen rounded>
      <FontAwesomeIcon icon={faWarning} size="3x" />
      <div className={styles.errorCover_message}>{error}</div>
    </Modal>
  );
};

export default ErrorCover;
