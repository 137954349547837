export const KeyID = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight'
};

export const KeyCode = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  OPTION: 18,
  CAPS_LOCK: 20,
  ESC: 27,
  SPACE: 32,

  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,

  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,

  SEMICOLON: 59,

  EQUAL: 61,

  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  G: 71,
  H: 72,
  I: 73,
  J: 74,
  K: 75,
  L: 76,
  M: 77,
  N: 78,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  X: 88,
  Y: 89,
  Z: 90,

  MINUS: 173,

  COMMA: 188,
  DOT: 190,
  SLASH: 191,
  BACKQUOTE: 192,
  BRACKET_LEFT: 219,
  BACKSLASH: 220,
  BRACKET_RIGHT: 221,
  QUOTE: 222,

  COMMAND: 224,
};
