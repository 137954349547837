import { LightDescription, LightTypes } from '@data/models';


const LIGHT_MARGIN = 500;
const LIGHT_INTENSITY = 1.0;

export const defaultLightsDescription: LightDescription[] = [
  {
    id: '1',
    name: 'ambient_light',
    type: LightTypes.Ambient,
    color: 0x404040,
    intensity: 0.3,
  },
  {
    id: '2',
    name: 'hemisphere',
    type: LightTypes.Hemisphere,
    intensity: 0.5,
    skyColor: 0xe6e6e8,
    groundColor: 0xe8e8e8,
  },
  {
    id: '40',
    name: 'torch_light_1',
    type: LightTypes.Torch,
    color: 0xffdfdf,
    distance: 1000,
    angle: 1.03,
    penumbra: 1,
    decay: 1,
    intensity: LIGHT_INTENSITY,
    position: {
      x: 0,
      y: 0,
      z: LIGHT_MARGIN
    },
  },
];
