import ToothRotMDIcon from './Rot M-D.svg?react';
import ToothAngMDIcon from './Ang M-D.svg?react';
import ToothTranMDIcon from './Tran M-D.svg?react';
import ToothTranEIIcon from './Tran E-I.svg?react';
import ToothTranBLIcon from './Tran B-L.svg?react';
import ToothTorqBLIcon from './Torq B-L.svg?react';

export {
  ToothRotMDIcon,
  ToothAngMDIcon,
  ToothTranMDIcon,
  ToothTranBLIcon,
  ToothTranEIIcon,
  ToothTorqBLIcon,
};
