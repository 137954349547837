import { Box3, Object3D, Scene, Sphere, Vector3 } from 'three';
import { getAspectRatio } from './utils';
import { ColorConverter, EventEmitter } from '@utils';
import { Renderer } from './Renderer';
import {
  CameraDescription,
  ObjectDescription,
  OrbitControlDescription,
  PerspectiveCameraDescription,
  SceneDescription
} from '@data/models';
import { PerspectiveCamera } from './cameras';
import BaseManager from './BaseManager';
import { Dictionary } from '@types';
import LightsManager from './lights';
import ObjectsManager from './objects';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { OrbitControl } from './controls';


export class WebGLScene extends EventEmitter {
  private readonly _scene: Scene;
  private readonly _root: Object3D;
  private readonly _renderer: Renderer;
  private readonly _camera: PerspectiveCamera;
  private readonly _orbitControl: OrbitControl;
  private readonly _managers: Dictionary<BaseManager<any>>;
  private readonly _stats: Stats | null;
  private readonly _clientId: string;

  constructor(
    description: SceneDescription,
    canvas: HTMLCanvasElement,
    onCameraChange: (desc: CameraDescription) => void,
    onControlChanged: (desc: OrbitControlDescription) => void,
    allObjects: Dictionary<ObjectDescription>,
    clientId: string
  ) {
    super();

    this._clientId = clientId;
    this._scene = new Scene();
    this._scene.name = description.name;
    this._scene.background = ColorConverter.numberToThreeColor(description.background);

    this._root = new Object3D();
    this._scene.add(this._root);

    this._camera = new PerspectiveCamera(
      this._scene,
      description.camera as PerspectiveCameraDescription,
      getAspectRatio(description.width, description.height)
    );

    this._renderer = new Renderer(
      description.renderer,
      description.width,
      description.height,
      this._scene,
      canvas,
      this._camera
    );

    this._renderer.createBackground().then(() => {
      this._scene.environment = this._renderer.getBackground();
    });

    this._orbitControl = new OrbitControl(this._camera, this._renderer.getRenderer(), this._root);

    this._renderer.addEventListener(Renderer.Event.CameraChanged, 'onCameraChange', onCameraChange);
    this._orbitControl.addEventListener(OrbitControl.Event.ControlChanged, 'onControlChanged', onControlChanged);
    this._orbitControl.addEventListener(
      OrbitControl.Event.ControlChanged,
      'updateByAnimation',
      this.updateByAnimation.bind(this)
    );

    this._managers = {
      lights: new LightsManager(this._scene, this._root, this._renderer),
      objects: new ObjectsManager(this, allObjects),
    };

    this._stats = null;
    // this._stats = Stats();
  }

  async update (description: SceneDescription) {
    this._camera.update(description.camera as PerspectiveCameraDescription);
    await this._managers.lights.update(description.lights);
    await this._managers.objects.update(description.objects);

    this._root.position.set(description.position.x, description.position.y, description.position.z);

    this._orbitControl.update(description.orbitControl);

    this.calculateBounding();
  }

  async updateByAnimation (): Promise<void> {
    await (this._managers.lights as LightsManager).updateTorchLights();
    await this._managers.objects.updateByAnimation();
  }

  animate = () => {
    requestAnimationFrame(this.animate);

    if (this._stats) {
      this._stats.update();
    }

    this.render();
  };

  render() {
    this._renderer.render();
    this._orbitControl.render();
  }

  resize(width: number, height: number) {
    this._camera.resize(width, height);
    this._renderer.resize(width, height);
  }

  calculateBounding (): { sphere: Sphere, bbox: Box3 } {
    const bbox = new Box3().setFromObject(this._scene);
    const center = new Vector3();
    bbox.getCenter(center);

    const sphere = new Sphere();
    bbox.getBoundingSphere(sphere);

    return { sphere, bbox };
  }

  async initialize() {
    for (const [_, manager] of Object.entries(this._managers)) {
      await manager.initialize();
    }
  }

  getStats (): Stats | null {
    return this._stats;
  }

  getRootObject (): Object3D {
    return this._root;
  }

  getRenderer (): Renderer {
    return this._renderer;
  }

  getScene (): Scene {
    return this._scene;
  }

  getClientId (): string {
    return this._clientId;
  }

  getCamera (): PerspectiveCamera {
    return this._camera;
  }
}
