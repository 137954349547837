import React from 'react';
import styles from './styles.scss';
import { TeethMovementData } from '@data/models';
import { DentalNotationType } from '@data/providers/DentalNotationProvider/Models';
import { Sidebar } from '@view/layouts';
import Header from './Header';
import Table from './Table';

const upperTeeth: string[] = [
  '18',
  '17',
  '16',
  '15',
  '14',
  '13',
  '12',
  '11',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
];

const lowerTeeth: string[] = [
  '48',
  '47',
  '46',
  '45',
  '44',
  '43',
  '42',
  '41',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
];

export interface ITeethMovementProps {
  onClose: () => void;
  data: TeethMovementData | null;
  currentStage: string;
  upperJawVisible: boolean;
  lowerJawVisible: boolean;
  toothNumbering: DentalNotationType;
  isOpen: boolean;
}

const TeethMovement = ({
  onClose,
  data,
  currentStage,
  upperJawVisible,
  lowerJawVisible,
  toothNumbering,
  isOpen,
}: ITeethMovementProps) => {
  if (!data) {
    return (
      <Sidebar className="z-[60]" isOpen={isOpen} size="md">
        <Header isLabelsHidden onClose={onClose} />
        <div className={styles.teethMovement_container}>No data</div>
      </Sidebar>
    );
  }

  const currentStageData = data[currentStage];

  return (
    <Sidebar isOpen={isOpen} size="md">
      <div className={styles.teethMovement}>
        <Header onClose={onClose} />
        <div className={styles.teethMovement_container}>
          <Table
            type="upperJaw"
            isJawVisible={upperJawVisible}
            teeth={upperTeeth}
            currentStageData={currentStageData}
            currentStage={currentStage}
            toothNumbering={toothNumbering}
          />
          <Table
            type="lowerJaw"
            isJawVisible={lowerJawVisible}
            teeth={lowerTeeth}
            currentStageData={currentStageData}
            currentStage={currentStage}
            toothNumbering={toothNumbering}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default TeethMovement;
