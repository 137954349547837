import React from 'react';
import styles from './styles.scss';

interface IBadgeProps {
  children: React.JSX.Element | string;
}

const Badge = ({ children }: IBadgeProps) => {
  return <span className={styles.badge}>{children}</span>;
};

export default Badge;
