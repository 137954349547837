import BaseClient from '../BaseClient';
import { ITreatmentPlanMeta, ITreatmentPlanVersion } from '@data/models';
import { Maybe } from '@utils';


class FXClient extends BaseClient {
  private readonly _baseURL: string | undefined;

  constructor () {
    super();

    this._baseURL = BaseClient.getClientApiUrl();
  }

  async getToken (sessionId: string): Promise<string | null> {
    const response = await fetch(`${this._baseURL}/tokens/login-external`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        // ...this._headers
      },
      body: JSON.stringify({ sessionId })
    });

    const result = await response.json();

    return result.response;
  }

  async getTreatmentPlanVersions (token: string, planId: string): Promise<ITreatmentPlanMeta> {
    const result = await fetch(`${this._baseURL}/treatment-planner/treatment-plan-details/${planId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        // ...this._headers,
        'access-token': token
      }
    });

    return result.json();
  }

  async getPlanDetails (token: string, versionId: string | number): Promise<ITreatmentPlanVersion> {
    const result = await fetch(`${this._baseURL}/treatment-planner/treatment-plan-version-details/${versionId}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        // ...this.headers,
        'access-token': token
      }
    });

    return result.json();
  }

  async getViewerAsset (url: string): Promise<Maybe<Blob>> {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        // ...this._headers
      }
    });

    const result = this.validateResponseCode(response);

    if (result.isPresent()) {
      return Maybe.some<Blob>(await result.value.blob());
    } else {
      return Maybe.error<Blob>(result.error);
    }
  }
}

export default FXClient;
