import React from 'react';
import { Badge, Image } from '@view/components';
import { ReleaseNotesProvider } from '@data/providers';
import VisionLogo from './images/Vision_logo.svg?react';
import styles from './styles.scss';
import { useSession } from '@data/atoms/session';
import { cn } from '@utils';
import { useSBSGetCompanyQuery } from '@data/services/supabase';
import { useGetCompanyColorScheme } from '../../../../hooks/useGetCompanyColorScheme';

interface IHeaderBrandProps {
  onBrandClick?: () => void;
}

const HeaderBrand = ({ onBrandClick }: IHeaderBrandProps) => {
  const releaseNotesProvider = new ReleaseNotesProvider();
  const [session] = useSession();

  const getCompanyQuery = useSBSGetCompanyQuery({
    options: { enabled: !!session.companyId },
    companyId: session.companyId,
  });

  const companyColorScheme = useGetCompanyColorScheme(getCompanyQuery.data);

  const headerLogo = !companyColorScheme ? (
    <VisionLogo />
  ) : !companyColorScheme.logo ? (
    <VisionLogo className={cn(companyColorScheme?.light && 'drop-shadow-lg')} />
  ) : (
    <Image
      className="max-h-10"
      url={companyColorScheme.logo}
      alt="company logo"
    />
  );

  return (
    <div className={styles.headerBrand} onClick={onBrandClick}>
      <div className={cn(styles.headerBrand_image, 'sm:flex-shrink')}>
        {headerLogo}
      </div>
      {!companyColorScheme?.logo && (
        <div
          className={cn(
            styles.headerBrand_label,
            companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
          )}
        >
          {'Viewer'}
        </div>
      )}
      {releaseNotesProvider.isNeedToDisplayNotification() && (
        <Badge>{'Update'}</Badge>
      )}
    </div>
  );
};

export default HeaderBrand;
