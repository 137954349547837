enum ErrorType {
  None = 0,
  Unexpected = 1,
  ForbiddenAccess = 2,
  NotFound = 3,
}

class ErrorDesc {
  private readonly _errorType: ErrorType;
  private readonly _message: string | null;

  constructor (errorType: ErrorType, message?: string) {
    this._errorType = errorType;
    this._message = message || null;
  }

  get type () {
    return this._errorType;
  }

  get message () {
    return this._message;
  }
}

export { ErrorDesc, ErrorType };
