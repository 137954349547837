import { WebGLChecker } from './WebGLChecker';

const getWebGLContext = (canvas: HTMLCanvasElement): WebGLRenderingContext | null => {
  return canvas.getContext('webgl', { antialias: true, preserveDrawingBuffer: true });
};

const getWebGL2Context = (canvas: HTMLCanvasElement): WebGLRenderingContext | null => {
  if (WebGLChecker.isWebGL2Available()) {
    return canvas.getContext('webgl2', { antialias: true, preserveDrawingBuffer: true });
  }

  console.warn(`${WebGLChecker.getWebGL2ErrorMessage()}. Setup WebGL mode.`);
  return getWebGLContext(canvas);
};

export const getContext = (canvas: HTMLCanvasElement, contextType: string) => {
  switch (contextType) {
    case 'webgl':
      return getWebGLContext(canvas);
    case 'webgl2':
      return getWebGL2Context(canvas);
    default:
      throw new Error(`Invalid context type of scene: "${contextType}". Check config file.`);
  }
};
