import {useEffect, useState} from 'react';

const useWindowInnerDimensions = () => {
  const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
  const calculateWindowDimensions = () => {
    setDimensions(prevState => ({...prevState, width: window.innerWidth, height: window.innerHeight}));
  }
  useEffect(() => {
    addEventListener("resize", calculateWindowDimensions);
    return () => removeEventListener("resize", calculateWindowDimensions);
  }, [])

  return dimensions;
};

export default useWindowInnerDimensions;