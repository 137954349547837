import { EventEmitter } from '@utils';
import { Object3D, Renderer, Vector3 } from 'three';
import { PerspectiveCamera } from '../cameras';
import { cloneDeep, isEqual } from 'lodash';
import { OrbitControlDescription } from '@data/models';
import { ObjectControls } from './utils/ObjectControls';
import { OrbitControls } from 'three-stdlib';

const EPSILON_DIGITS = 3;

const roundValue = (num: number, decimals = 2) => {
  const scaling = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * scaling) / scaling;
};

export class OrbitControl extends EventEmitter {
  private _controls: OrbitControls;
  private _objectControls: ObjectControls;
  private _camera: PerspectiveCamera;
  private _root: Object3D;

  private _description: OrbitControlDescription = {
    polarAngle: 0,
    azimuthAngle: 0,
    target: {
      x: 0, y: 0, z: 0
    }
  };

  static get Event () {
    return {
      OnMove: 'OnMove',
      ControlChanged: 'ControlChanged',
    };
  }

  constructor (camera: PerspectiveCamera, renderer: Renderer, root: Object3D) {
    super();

    this._root = root;
    this._camera = camera;
    this._controls = new OrbitControls(camera.getCamera(), renderer.domElement);
    this._objectControls = new ObjectControls(camera.getCamera(), renderer.domElement, root);

    this._controls.zoomSpeed = 0.5;
    this._controls.minDistance = 0;
    this._controls.maxDistance = 500;
    this._controls.dampingFactor = 0.05;
    this._controls.enableDamping = true;
    this._controls.enableRotate = false;
  }

  update (description: OrbitControlDescription) {
    if (isEqual(this._description, description)) {
      return;
    }

    this._objectControls.rotation.set(description.polarAngle, description.azimuthAngle, 0.0);

    this._controls.target = new Vector3(description.target.x, description.target.y, description.target.z);

    this._controls.update();

    this._description = cloneDeep(description);
  }

  buildDescription(): OrbitControlDescription {
    return {
      polarAngle: roundValue(this._root.rotation.x, EPSILON_DIGITS),
      azimuthAngle: roundValue(this._root.rotation.y, EPSILON_DIGITS),
      target: {
        x: roundValue(this._controls.target.x, EPSILON_DIGITS),
        y: roundValue(this._controls.target.y, EPSILON_DIGITS),
        z: roundValue(this._controls.target.z, EPSILON_DIGITS),
      }
    };
  }

  render () {
    const newDescription = this.buildDescription();

    if (!isEqual(newDescription, this._description)) {
      this.emit(OrbitControl.Event.ControlChanged, newDescription);

      this._description = cloneDeep(newDescription);
    }

    this._objectControls.update();

    this._controls.setAzimuthalAngle(0.0);
    this._controls.setPolarAngle(Math.PI / 2.0);

    this._controls.update();
  }
}
