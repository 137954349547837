import React from 'react';
import { Modal } from '@view/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import RELEASE_NOTES from '@releaseNotes';
import moment from 'moment';
import styles from './styles.scss';
import { ReleaseNotesProvider } from '@data/providers';

interface IReleaseNotesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReleaseNotesModal = ({ isOpen, onClose }: IReleaseNotesModalProps) => {
  const releaseNotesProvider = new ReleaseNotesProvider();

  releaseNotesProvider.updateLastShownVersion();

  return (
    <Modal
      bodyClassName={styles.releaseNotesModal}
      isOpen={isOpen}
      onClose={onClose}
      rounded
      title="Release notes"
      withBackground
    >
      {RELEASE_NOTES.map((section) => (
        <div className={styles.releaseNotesModal_section} key={section.id}>
          <div className={styles.releaseNotesModal_section_title}>
            {section.title}
          </div>

          {section.releaseDate && (
            <div>
              {`Release date: ${moment(section.releaseDate).format(
                'MMMM Do YYYY'
              )}`}
            </div>
          )}

          {section.description && (
            <div className={styles.releaseNotesModal_section_description}>
              {section.description}
            </div>
          )}

          <div className={styles.releaseNotesModal_section_notes}>
            {section.features && section.features.length > 0 && (
              <div className={styles.releaseNotesModal_section_notes_part}>
                <div
                  className={styles.releaseNotesModal_section_notes_part_title}
                >
                  {'New features'}
                </div>

                <div
                  className={styles.releaseNotesModal_section_notes_part_items}
                >
                  {section.features.map((item) => (
                    <div
                      className={
                        styles.releaseNotesModal_section_notes_part_items_item
                      }
                      key={item}
                    >
                      <FontAwesomeIcon icon={faCircle} size="xs" />
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {section.bugs && section.bugs.length > 0 && (
              <div className={styles.releaseNotesModal_section_notes_part}>
                <div
                  className={styles.releaseNotesModal_section_notes_part_title}
                >
                  {'Bugfixes'}
                </div>

                <div
                  className={styles.releaseNotesModal_section_notes_part_items}
                >
                  {section.bugs.map((item) => (
                    <div
                      className={
                        styles.releaseNotesModal_section_notes_part_items_item
                      }
                      key={item}
                    >
                      <FontAwesomeIcon icon={faCircle} size="xs" />
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </Modal>
  );
};

export default ReleaseNotesModal;
