import Sidebar from '@view/layouts/Sidebar';
import { CloseButton, Loader } from '@view/components';
import React from 'react';
import { PatientInfo, PatientNumberOfAligners } from '@data/models';
import styles from './styles.scss';
import { cn } from '@utils';

type PatientInfoProps = {
  isOpen: boolean;
  onClose: () => void;
  patientInfo?: PatientInfo;
  upperStagesCount?: number;
  lowerStagesCount?: number;
};

const PatientInfoSidebar = ({
  isOpen,
  onClose,
  patientInfo,
  upperStagesCount,
  lowerStagesCount
}: PatientInfoProps) => {

  const getNumberOfAlignersString = () => {
    if (!upperStagesCount && !lowerStagesCount) {
      return <Loader size="md" />;
    }

    const upper = upperStagesCount ? upperStagesCount : '-';
    const lower = lowerStagesCount ? lowerStagesCount : '-';

    return `${upper} / ${lower}`;
  };

  return (
    <Sidebar className="z-[60]" isOpen={isOpen} size="sm">
      <div className={styles.patientInfo_header}>
        <span className={styles.patientInfo_headerTitle}>Patient Info</span>
        <CloseButton size="sm" onClick={onClose} />
      </div>
      <div className="px-4">
        <div className={styles.patientInfo_group}>
          <span className={styles.patientInfo_groupTitle}>First name</span>
          <span className={styles.patientInfo_groupDesc}>
            {patientInfo?.firstName}
          </span>
        </div>
        <div className={styles.patientInfo_group}>
          <span className={styles.patientInfo_groupTitle}>Last name</span>
          <span className={styles.patientInfo_groupDesc}>
            {patientInfo?.lastName}
          </span>
        </div>
        <div className={styles.patientInfo_group}>
          <span className={styles.patientInfo_groupTitle}>Case id</span>
          <span className={styles.patientInfo_groupDesc}>
            {patientInfo?.caseAliasId}
          </span>
        </div>
        <div className={styles.patientInfo_group}>
          <span className={styles.patientInfo_groupTitle}>
            Number of Aligners (U/L)
          </span>
          <span className={cn(styles.patientInfo_groupDesc, 'flex')}>
            {getNumberOfAlignersString()}
          </span>
        </div>
      </div>
    </Sidebar>
  );
};

export default PatientInfoSidebar;
