export const Colors = {
  White: 0xffffff,
  LightGray: 0xeeeeee,
  Black: 0x000000,
  Red: 0xb33044,
  Green: 0x44b330,
  Blue: 0x3044b3,
  Yellow: 0xffff00,
  Cyan: 0x00eaff,
};
