import { ITreatmentPlan } from '@data/models';
import { Dictionary } from '@types';


export const getStagesCount = (plan: ITreatmentPlan): Dictionary<number> => {
  const upperStages = plan.stages
    .map((stage) => stage.upper)
    .filter((stage) => stage);
  const lowerStages = plan.stages
    .map((stage) => stage.lower)
    .filter((stage) => stage);

  const firstStageId = parseInt(plan.stages[0].id);

  // Minus 1 because stage 0 is initial stage, not aligner.
  // If we have only one 'stage', it means only initial stage without correction.
  return {
    upperStagesCount: firstStageId + upperStages.length - 1,
    lowerStagesCount: firstStageId + lowerStages.length - 1
  };
};
