import { EventEmitter } from '@utils';
import {
  PMREMGenerator,
  Scene,
  WebGLRenderer,
  Texture,
  EquirectangularReflectionMapping
} from 'three';
import { getContext } from './utils';
import { PerspectiveCameraDescription, RendererDescription } from '@data/models';
import { PerspectiveCamera } from './cameras';
import { TextureLoader } from '@data/loaders';
import { Nullable } from '@types';

export class Renderer extends EventEmitter {
  private readonly _scene: Scene;
  private readonly _context: WebGLRenderingContext | null;
  private readonly _renderer: WebGLRenderer;
  private readonly _camera: PerspectiveCamera;
  private readonly _pmremGenerator: PMREMGenerator;
  private _envMap: Nullable<Texture>;
  private _background: Nullable<Texture>;

  public static Event = {
    CameraChanged: 'CameraChanged'
  };

  constructor(
    description: RendererDescription,
    width: number,
    height: number,
    scene: Scene,
    canvas: HTMLCanvasElement,
    camera: PerspectiveCamera
  ) {
    super();

    this._scene = scene;
    this._camera = camera;
    this._context = getContext(canvas, description.contextType);

    this._envMap = null;
    this._background = null;

    if (!this._context) {
      throw new Error('Can\'t create context');
    }

    this._renderer = new WebGLRenderer({
      canvas,
      context: this._context,
      antialias: description.antialias,
    });

    // this._renderer.physicallyCorrectLights = true;
    // this._renderer.toneMapping = ACESFilmicToneMapping;
    this._renderer.toneMappingExposure = 0.5;
    // this._renderer.outputEncoding = sRGBEncoding;

    this._pmremGenerator = new PMREMGenerator(this._renderer);
    this._pmremGenerator.compileEquirectangularShader();

    this._renderer.setPixelRatio(description.pixelRatio);
    this._renderer.setSize(width, height);
  }

  async createBackground () {
    const textureLoader = new TextureLoader();

    // const hdrMap = await textureLoader.load('/assets/textures/env.hdr') as CubeTexture;
    // const hdrCubeRenderTarget = this._pmremGenerator.fromCubemap(hdrMap);

    const exrMap = await textureLoader.load('/assets/textures/env.exr') as Texture;
    exrMap.mapping = EquirectangularReflectionMapping;
    const exrCubeRenderTarget = this._pmremGenerator.fromEquirectangular(exrMap);

    // this._envMap = hdrCubeRenderTarget
    //   ? hdrCubeRenderTarget.texture
    //   : null;

    this._envMap = exrCubeRenderTarget
      ? exrCubeRenderTarget.texture
      : null;
    this._background = exrMap;
  }

  onCameraChange(description: PerspectiveCameraDescription) {
    this.render();
    this.emit(Renderer.Event.CameraChanged, description);
  }

  render() {
    this._renderer.render(this._scene, this._camera.getCamera());
  }

  resize(width: number, height: number) {
    this._renderer.setSize(width, height);
  }

  getRenderer (): WebGLRenderer {
    return this._renderer;
  }

  getCamera (): PerspectiveCamera {
    return this._camera;
  }

  getPMREMGenerator (): PMREMGenerator {
    return this._pmremGenerator;
  }

  getEnvMap (): Nullable<Texture> {
    return this._envMap;
  }

  getBackground (): Nullable<Texture> {
    return this._background;
  }
}
