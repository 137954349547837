import React, { useRef, useState } from 'react';
import { Button } from '@view/components';
import { IGridFeatureConfig, IViewSettings } from '@data/models';
import styles from './styles.scss';
import { ConfigProvider } from '@data/providers';
import { ISceneData } from '@data/providers/SceneDataProvider';
import GridPanel from './GridPanel';
import { BooleanKeys } from '@types';
import { cn } from '@utils';
import { useCompanyColorScheme } from 'src/hooks/useCompanyColorScheme';

export type GridOption = {
  value: number | null;
  label: string;
};

type viewSettingsButton = {
  value: BooleanKeys<IViewSettings>;
  label: string;
};

interface IViewSettingsProps {
  configProvider: ConfigProvider;
  viewSettings: IViewSettings;
  toggleViewSetting: (setting: BooleanKeys<IViewSettings>) => void;
  className?: string;
  sceneData?: ISceneData;
  setViewSetting: (setting: keyof IViewSettings, value: any) => void;
  companyColorScheme?: ReturnType<typeof useCompanyColorScheme>[0];
}

const settings: viewSettingsButton[] = [
  { value: 'overlay', label: 'Overlay' },
  { value: 'attachments', label: 'Attachments' },
  { value: 'biteRamps', label: 'Bite ramps' },
  { value: 'elastics', label: 'Elastics' },
  { value: 'pontics', label: 'Pontics' },
  { value: 'iprs', label: 'Separation' },
  { value: 'occlusions', label: 'Contacts' },
  { value: 'hideGum', label: 'Gum' },
];

const ViewSettings = ({
  configProvider,
  viewSettings,
  toggleViewSetting,
  className,
  sceneData,
  companyColorScheme,
  setViewSetting,
}: IViewSettingsProps) => {
  const [gridPanelOpen, setGridPanelState] = useState<boolean>(false);
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const gridOptions: GridOption[] = [{ value: null, label: 'Grid off' }];

  const overlayFeatureEnabled = configProvider.isFeatureEnabled('overlay');
  const hideGumFeatureEnabled = configProvider.isFeatureEnabled('hideGum');
  const featureFlags = {
    overlayFeatureEnabled,
    hideGumFeatureEnabled,
  };
  const gridFeatureConfig =
    configProvider.getFeatureConfig<IGridFeatureConfig>('grid');

  if (gridFeatureConfig?.enabled) {
    gridFeatureConfig.sizes.forEach((size: number) => {
      gridOptions.push({ value: size, label: `${size} mm` });
    });
  }

  const handleGridSwitcherMouseEnter = () => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    setGridPanelState(true);
  };

  const handleGridSwitcherMouseLeave = () => {
    closeTimeoutRef.current = setTimeout(() => {
      setGridPanelState(false);
    }, 600);
  };

  const handleGridPanelClick = (gridSize: number | null) => {
    if (gridSize) {
      setViewSetting('grid', true);
      setViewSetting('gridSize', gridSize);
    } else {
      setViewSetting('grid', false);
    }
  };

  const handleGridButtonClick = () => {
    const currentSelectedOption = !viewSettings.grid
      ? null
      : viewSettings.gridSize;
    // Grid options from 'gridFeatureConfig'
    const options = gridOptions.map((v) => v.value);
    // Determining and switching to next grid option
    const index = options.indexOf(currentSelectedOption);
    const newOption = options[(index + 1) % options.length];
    handleGridPanelClick(newOption);
  };

  return (
    <div className={cn(styles.viewSettingsBar_group, className)}>
      <div className="relative">
        {gridFeatureConfig?.enabled && (
          <Button
            active={viewSettings.grid || gridPanelOpen}
            className={cn(styles.viewSettingsBar_button, 'min-h-full')}
            onMouseEnter={handleGridSwitcherMouseEnter}
            onMouseLeave={handleGridSwitcherMouseLeave}
            onClick={handleGridButtonClick}
          >
            <img
              src="/assets/images/grid_icon.svg"
              className={cn(
                companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
              )}
              alt="View Settings"
            />
          </Button>
        )}

        {gridPanelOpen && (
          <GridPanel
            onClose={() => {
              setGridPanelState(false);
            }}
            onMouseEnter={handleGridSwitcherMouseEnter}
            onMouseLeave={handleGridSwitcherMouseLeave}
            gridState={viewSettings.grid}
            gridSize={viewSettings.gridSize}
            gridOptions={gridOptions}
            onGridPanelClick={handleGridPanelClick}
          />
        )}
      </div>
      {settings
        .filter(({ value: setting }) => {
          const isOverlayFeatureDisabled =
            setting === 'overlay' && !featureFlags.overlayFeatureEnabled;
          const isOcclusionsDisabled =
            setting === 'occlusions' &&
            !(sceneData && sceneData.occlusions.length > 0);
          const isHideGumDisabled =
            setting === 'hideGum' && !featureFlags.hideGumFeatureEnabled;
          const isSettingEnabled = !(
            isHideGumDisabled ||
            isOverlayFeatureDisabled ||
            isOcclusionsDisabled
          );

          return isSettingEnabled;
        })
        .map((setting) => {
          const isHideGum = setting.value === 'hideGum';

          return (
            <Button
              key={setting.value}
              tooltip={setting.label}
              active={!isHideGum ? viewSettings?.[setting.value] : !viewSettings?.[setting.value]}
              className={cn(styles.viewSettingsBar_button)}
              onClick={() => toggleViewSetting(setting.value)}
            >
              <img
                src={`/assets/images/${setting.value}_icon.svg`}
                className={cn(
                  companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                )}
                alt="View Settings"
              />
            </Button>
          );
        })}
    </div>
  );
};

export default ViewSettings;
