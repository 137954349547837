import { Point, PointPolar } from '@data/models';
import { EPSILON } from '@data/constants';


class GeometryUtil {
  static degToRad (value: number): number {
    return (value * Math.PI) / 180;
  }

  static radToDeg (value: number): number {
    return (value * 180) / Math.PI;
  }

  static toPoint (point: PointPolar, center: Point): Point {
    const phiRad = GeometryUtil.degToRad(point.phi);

    return {
      x: center.x + (point.radius * Math.cos(phiRad)),
      y: center.x + (point.radius * Math.sin(phiRad))
    };
  }

  static getAngleRadBetween (point1: Point, point2: Point, center: Point) {
    // It means that we have two vectors: { center, point1 } and { center, point2 }.
    // This function will calculate angle between these two vectors.

    const vector1 = { x: point1.x - center.x, y: point1.y - center.y };
    const vector2 = { x: point2.x - center.x, y: point2.y - center.y };

    const scalarProduct = vector1.x * vector2.x + vector1.y * vector2.y;
    const module1 = Math.sqrt(vector1.x ** 2 + vector1.y ** 2);
    const module2 = Math.sqrt(vector2.x ** 2 + vector2.y ** 2);
    const cosPhi = scalarProduct / (module1 * module2);
    return Math.acos(cosPhi);
  }

  static getPolarPointByCenter (point: Point, center: Point): PointPolar {
    const offsetX = point.x - center.x;
    const offsetY = point.y - center.y;

    const r = Math.sqrt(offsetX ** 2 + offsetY ** 2);
    let phi = 0;

    if (offsetX > 0 && offsetY >= 0) {
      phi = Math.atan(offsetY / offsetX);
    } else if (offsetX > 0 && offsetY < 0) {
      phi = Math.atan(offsetY / offsetX) + 2 * Math.PI;
    } else if (offsetX < 0) {
      phi = Math.atan(offsetY / offsetX) + Math.PI;
    } else if (Math.abs(offsetX) <= EPSILON && offsetY > 0) {
      phi = Math.PI / 2;
    } else if (Math.abs(offsetX) <= EPSILON && offsetY < 0) {
      phi = (3 * Math.PI) / 2;
    } else {
      phi = 0;
    }

    return {
      radius: r,
      phi: 2 * Math.PI - phi
    };
  }
}

export default GeometryUtil;
