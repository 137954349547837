import React, { useState } from 'react';
import { Badge, Link, Modal } from '@view/components';
import Logo from './images/vision_logo_info.svg?react';
import classNames from 'classnames';
import styles from './styles.scss';
import { ReleaseNotesModal } from '@view/layouts';
import { ReleaseNotesProvider } from '@data/providers';

interface IManufacturerProps {
  isOpen: boolean;
  onClose: () => void;
}

const Manufacturer = ({ isOpen, onClose }: IManufacturerProps) => {
  const [isReleaseNotesOpen, setIsReleaseNotesOpen] = useState<boolean>(false);
  const releaseNotesProvider = new ReleaseNotesProvider();

  return (
    <Modal
      bodyClassName={styles.manufacturer}
      isOpen={isOpen}
      onClose={onClose}
      title="Softsmile Vision Web Viewer"
      rounded
    >
      <div className={styles.manufacturer_group}>
        <div className={styles.manufacturer_group_label}>{'Manufacturer'}</div>

        <div className={styles.manufacturer_group_text}>
          <div
            className={classNames(
              styles.manufacturer_group_text_row,
              styles.__bold
            )}
          >
            {'SoftSmile, Inc.'}
          </div>
        </div>
      </div>

      <div className={styles.manufacturer_group}>
        <div className={styles.manufacturer_group_label}>{'Address'}</div>

        <div className={styles.manufacturer_group_text}>
          <div className={styles.manufacturer_group_text_row}>
            {'16192 Coastal Highway, Lewes'}
          </div>
          <div className={styles.manufacturer_group_text_row}>
            {'County of Sussex, DE, 19958, US'}
          </div>
          <div className={styles.manufacturer_group_text_row}>
            <Link target="_blank" to="https://www.softsmile.com">
              {'www.softsmile.com'}
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.manufacturer_group}>
        <div className={styles.manufacturer_group_label}>{'Email'}</div>

        <div className={styles.manufacturer_group_text}>
          <div className={styles.manufacturer_group_text_row}>
            <Link to="mailto:support@softsmile.com">
              {'support@softsmile.com'}
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.manufacturer_group}>
        <div className={styles.manufacturer_group_label}>{'UDI'}</div>

        <div className={styles.manufacturer_group_text}>
          <div className={styles.manufacturer_group_text_row}>
            {'00860009273702'}
          </div>
        </div>
      </div>

      <div className={styles.manufacturer_group}>
        <div className={styles.manufacturer_group_label}>{'REF'}</div>

        <div className={styles.manufacturer_group_text}>
          <div className={styles.manufacturer_group_text_row}>{'Vision 1'}</div>
        </div>
      </div>

      <div className={styles.manufacturer_group}>
        <div className={styles.manufacturer_group_label}>{'Version'}</div>

        <div className={styles.manufacturer_group_text}>
          <div className={styles.manufacturer_group_text_row}>
            <span>{`${CONFIG.VERSION}`}</span>
          </div>

          <div className={styles.manufacturer_group_text_row}>
            <span
              className={styles.manufacturer_group_text_row_link}
              onClick={() => {
                setIsReleaseNotesOpen(true);
              }}
            >
              {'Release notes'}
            </span>
            {releaseNotesProvider.isNeedToDisplayNotification() && (
              <Badge>{'Update'}</Badge>
            )}
          </div>
        </div>

        <ReleaseNotesModal
          isOpen={isReleaseNotesOpen}
          onClose={() => {
            setIsReleaseNotesOpen(false);
          }}
        />
      </div>

      <div className={styles.manufacturer_logo}>
        <Logo />
      </div>
    </Modal>
  );
};

export default Manufacturer;
