import cookie from 'cookie';

class ReleaseNotesProvider {
  constructor () {

  }

  isNeedToDisplayNotification () {
    const cookieValues = cookie.parse(document.cookie);

    return cookieValues.lastShownVersion !== CONFIG.VERSION;
  }

  updateLastShownVersion () {
    /*
     1. By the https://developer.chrome.com/blog/cookie-max-age-expires/#:~:text=Learn%20more-,As%20of%20Chrome%20release%20M104%20(August%202022)%20cookies%20can%20no,when%20the%20browsing%20session%20ends.
     max-age of cookie is <= 400 days.
     2. For using cookies in a iframe case, we need to set same-site=none and secure=true
     */
    document.cookie = cookie.serialize('lastShownVersion', CONFIG.VERSION, {
      sameSite: 'none',
      secure: true,
      maxAge: 1000 * 60 * 60 * 24 * 400
    });
  }
}

export default ReleaseNotesProvider;
