import { AmbientLight as ThreeAmbientLight } from 'three';
import BaseLight from './BaseLight';
import { ColorConverter } from '@utils';
import { AmbientLightDescription, LightTypes } from '@data/models';

export class AmbientLight extends BaseLight {
  constructor (description: AmbientLightDescription) {
    super(description.id, description.name, LightTypes.Ambient);

    this._light = new ThreeAmbientLight(ColorConverter.numberToThreeColor(description.color), description.intensity);
    this._light.name = description.name;
  }
}
