import {DentalNotationHelper} from '@data/providers/DentalNotationProvider/DentalNotationHelper';

export class PrimaryTeethNotationHelper {
  public static readonly MIN_NUMBER_IN_QUADRANT = 1;
  public static readonly MAX_NUMBER_IN_QUADRANT = 5;

  constructor() {}

  private static getQuadrantRaw(toothNumber: number): number {
    return Math.floor(toothNumber / 10) - 4;
  }

  private static getNumberInQuadrantRaw(toothNumber: number): number {
    return toothNumber % 10;
  }

  private static validateToothNumberInQuadrant(toothNumberInQuadrant: number) {
    if (!this.isValidToothNumberInQuadrant(toothNumberInQuadrant)) throw new Error(`Tooth number in quadrant should be between 1 and 8. ${toothNumberInQuadrant} is given`);
  }

  static getJawQuadrant(toothNumber: number): number {
    const quadrant = this.getQuadrantRaw(toothNumber);
    DentalNotationHelper.validateToothQuadrant(quadrant);
    return quadrant;
  }

  static getNumberInQuadrant(toothNumber: number): number {
    const toothNumberInQuadrant = this.getNumberInQuadrantRaw(toothNumber);
    this.validateToothNumberInQuadrant(toothNumberInQuadrant);
    return toothNumberInQuadrant;
  }

  static isValidToothNumberInQuadrant(toothNumberInQuadrant: number) {
    return this.MIN_NUMBER_IN_QUADRANT <= toothNumberInQuadrant && toothNumberInQuadrant <= this.MAX_NUMBER_IN_QUADRANT;
  }

  static isValidPrimaryToothNumber(toothNumber: number) {
    return DentalNotationHelper.isValidToothQuadrant(this.getQuadrantRaw(toothNumber))
      && this.isValidToothNumberInQuadrant(this.getNumberInQuadrantRaw(toothNumber));
  }
}