import React from 'react';
import { CloseButton } from '@view/components';
import styles from './styles.scss';

type MenuHeaderProps = {
  onClose: () => void;
};

const MenuHeader = ({ onClose }: MenuHeaderProps) => {
  return (
    <div className={styles.menuHeader}>
      <span className={styles.menuHeader_title}>Menu</span>
      <CloseButton size="sm" onClick={onClose} />
    </div>
  );
};

export default MenuHeader;
