export enum GizmoViewOrientation {
  Top, Left, Right, Front, Bottom, Back
}

export interface IViewSettings {
  upperJawVisible: boolean;
  lowerJawVisible: boolean;
  attachments: boolean;
  pontics: boolean;
  iprs: boolean;
  elastics: boolean;
  biteRamps: boolean;
  grid: boolean;
  overlay: boolean;
  gridSize: number;
  occlusions: boolean;
  hideGum: boolean;
}
