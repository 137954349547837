import { IStage, ITreatmentPlan } from '@data/models';
import { cloneDeep } from 'lodash';


export const getFilledStages = (plan: ITreatmentPlan): IStage[] => {
  const firstStageId = parseInt(plan.stages[0].id);
  const stagesDisabled: IStage[] = Array.from({ length: firstStageId }, (_, i) => ({
    id: String(i),
    enabled: false,
    upper: null,
    lower: null
  }));

  const stagesNew: IStage[] = [];

  for (let i = 0; i < plan.stages.length; i++) {
    const newStage = cloneDeep(plan.stages[i]);
    newStage.enabled = true;
    if (!plan.stages[i].lower) {
      newStage.lower = cloneDeep(stagesNew[i - 1].lower);
      newStage.lower?.teeth?.forEach(tooth => {
        tooth.occlusion =  tooth.occlusion?.replace(/-\d+-/, `-${i}-`);
      });
    }
    if (!plan.stages[i].upper) {
      newStage.upper = cloneDeep(stagesNew[i - 1].upper);
      newStage.upper?.teeth?.forEach(tooth => {
        tooth.occlusion =  tooth.occlusion?.replace(/-\d+-/, `-${i}-`);
      });
    }
    stagesNew.push(newStage);
  }

  return [...stagesDisabled, ...stagesNew];
};
