import React from 'react';
import HeaderBrand from './Brand';
import HeaderMenu from './Menu';
import styles from './styles.scss';
import { cn } from '@utils';
import { useCompanyColorScheme } from '../../../hooks/useCompanyColorScheme';

interface IHeaderProps {
  children: React.ReactNode | null;
  onBrandClick?: () => void;
  colorScheme?: ReturnType<typeof useCompanyColorScheme>[0] | undefined;
}

const Header = ({
  children,
  onBrandClick,
  colorScheme,
}: IHeaderProps) => {
  return (
    <div
      className={cn(styles.header, 'bg-primary-color')}
      style={{ background: colorScheme?.gradient }}
    >
      <HeaderBrand onBrandClick={onBrandClick} />
      <div className={styles.header_content}>{children}</div>
      <HeaderMenu />
    </div>
  );
};

export default Header;
