import { Dictionary, Nullable } from '@types';
import { Texture, TextureLoader as ThreeTextureLoader } from 'three';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import { EXRLoader } from '@data/loaders/TextureLoader/EXRLoader';
import { HDRLoader } from '@data/loaders/TextureLoader/HDRLoader';


class TextureLoader {
  private _loaders: Dictionary<any>;
  private _textureLoader: ThreeTextureLoader;

  constructor () {
    this._loaders = {};
    this._textureLoader = new ThreeTextureLoader();

    this._loaders['tga'] = new TGALoader();
    this._loaders['jpg'] = this._textureLoader;
    this._loaders['exr'] = new EXRLoader();
    this._loaders['hdr'] = new HDRLoader();
  }

  async loadTexture (url: string): Promise<Nullable<Texture>> {
    return new Promise((resolve) => {
      this._textureLoader.load(
        url,
        (texture: Texture) => resolve(texture)
      );
    });
  }

  async load (url: string): Promise<Nullable<Texture>> {
    const [fileExtensionResult] = url.split('?')[0].split('.').reverse();

    if (!fileExtensionResult) {
      return null;
    }

    const fileExtension = fileExtensionResult.toLowerCase();
    const loader = this._loaders[fileExtension];

    if (!loader) {
      return null;
    }

    return new Promise((resolve) => {
      loader.load(
        url,
        (texture: Texture) => resolve(texture)
      );
    });
  }
}

export default TextureLoader;
