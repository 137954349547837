import {DentalNotationType} from '@data/providers/DentalNotationProvider/Models';
import {ToothlikeFdiNameProvider} from '@data/providers/DentalNotationProvider/ToothlikeFdiNameProvider';
import { ToothlikePalmerNameProvider } from './ToothlikePalmerNameProvider';
import {ToothlikeUniversalNameProvider} from '@data/providers/DentalNotationProvider/ToothlikeUniversalNameProvider';

export class ToothlikeNameProviderFactory {
  constructor() {}

  static create(dentalNotationType: DentalNotationType) {
    switch (dentalNotationType) {
      case DentalNotationType.Fdi:
        return new ToothlikeFdiNameProvider();
      case DentalNotationType.Palmer:
        return new ToothlikePalmerNameProvider();
      case DentalNotationType.Universal:
        return new ToothlikeUniversalNameProvider();
      default:
        return new ToothlikeFdiNameProvider();
    }
  }
}