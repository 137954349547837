import { BufferGeometry, Float32BufferAttribute, Vector2 } from 'three';

export const computeGingivaUV = (geometry: BufferGeometry, uvScale: Vector2): void => {
  const buffer = geometry.attributes.position.array;
  const uvs = [];

  for (let i = 0; i < buffer.length; i += geometry.attributes.position.itemSize) {
    const x = buffer[i];
    const y = buffer[i + 1];
    const z = buffer[i + 2];

    const r = Math.sqrt(x * x + z * z);
    const phi = Math.atan2(x, z);

    let uvX = uvScale.x * Math.abs(phi * r);
    if (uvX >= 1.0) {
      uvX -= 1.0;
    }
    let uvY = uvScale.y * Math.abs(y);
    if (uvY >= 1.0) {
      uvY -= 1.0;
    }

    uvs.push(uvX);
    uvs.push(uvY);
  }

  geometry.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
};
