import React from 'react';
import { cn } from '@utils';
import styles from './styles.scss';
import { isMobile } from 'react-device-detect';

export type SidebarProps = {
  isOpen: boolean;
  wrapperClassName?: string;
  className?: string;
  size: 'sm' | 'md' | 'lg';
  children: React.ReactNode;
};

const Sidebar = ({
  isOpen,
  wrapperClassName,
  className,
  size,
  children,
}: SidebarProps) => {
  return (
    <>
      <div className={cn(styles.sideBarLayout, className)}>
        <div
          className={cn(
            styles.sideBarLayout_content,
            isOpen ? 'translate-x-0' : '-translate-x-full',
            size === 'sm' && 'max-w-[196px]',
            size === 'md' && 'max-w-[380px]',
            size === 'lg' && !isMobile && 'max-w-[720px]',
            size === 'lg' && isMobile && 'max-w-screen'
          )}
        >
          <div className={cn(styles.sideBarLayout_wrapper, wrapperClassName)}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
