import React from 'react';
import styles from './styles.scss';
import { Point } from '@data/models';
import TextLabel from '../TextLabel';
import Line from '../Line';
import { GeometryUtil } from '@utils';

interface IInfoCircleProps {
  center: Point;
  radius: number;
  stagesUpper: number;
  stagesLower: number;
  onShowStartStageClick: (e: React.MouseEvent) => void;
  onShowFinalStageClick: (e: React.MouseEvent) => void;
}

const InfoCircle = ({
  center,
  radius,
  stagesUpper,
  stagesLower,
  onShowFinalStageClick,
  onShowStartStageClick,
}: IInfoCircleProps) => {
  const topLabelPosition = { radius: radius * 0.75, phi: -90 };
  const bottomLabelPosition = { radius: radius * 0.65, phi: 90 };

  const topDividerStartPosition = { radius, phi: -150 };
  const topDividerEndPosition = { radius, phi: -30 };
  const bottomDividerStartPosition = { radius, phi: 150 };
  const bottomDividerEndPosition = { radius, phi: 30 };

  const upperNumberPosition = { radius: radius * 0.5, phi: -160 };
  const upperLabelPosition = { radius: radius * 0.5, phi: -200 };
  const lowerNumberPosition = { radius: radius * 0.5, phi: -20 };
  const lowerLabelPosition = { radius: radius * 0.5, phi: 20 };

  return (
    <g
      x={center.x - radius}
      y={center.y - radius}
      width={radius * 2}
      height={radius * 2}
    >
      <circle cx={center.x} cy={center.y} r={radius} fill="white" />
      <TextLabel
        className={styles.knob_infoCircle_topLabel}
        position={GeometryUtil.toPoint(topLabelPosition, center)}
        onClick={onShowStartStageClick}
      >
        {'Original'}
      </TextLabel>

      <Line
        className={styles.knob_infoCircle_divider}
        start={GeometryUtil.toPoint(topDividerStartPosition, center)}
        end={GeometryUtil.toPoint(topDividerEndPosition, center)}
        width={1}
      />

      <TextLabel
        className={styles.knob_infoCircle_upperNumber}
        position={GeometryUtil.toPoint(upperNumberPosition, center)}
      >
        {stagesUpper}
      </TextLabel>
      <TextLabel
        className={styles.knob_infoCircle_upperLabel}
        position={GeometryUtil.toPoint(upperLabelPosition, center)}
      >
        {'Upper'}
      </TextLabel>

      <Line
        className={styles.knob_infoCircle_divider}
        start={GeometryUtil.toPoint(bottomDividerStartPosition, center)}
        end={GeometryUtil.toPoint(bottomDividerEndPosition, center)}
        width={1}
      />

      <TextLabel
        className={styles.knob_infoCircle_lowerNumber}
        position={GeometryUtil.toPoint(lowerNumberPosition, center)}
      >
        {stagesLower}
      </TextLabel>
      <TextLabel
        className={styles.knob_infoCircle_lowerLabel}
        position={GeometryUtil.toPoint(lowerLabelPosition, center)}
      >
        {'Lower'}
      </TextLabel>

      <TextLabel
        className={styles.knob_infoCircle_bottomLabel}
        position={GeometryUtil.toPoint(bottomLabelPosition, center)}
        onClick={onShowFinalStageClick}
      >
        {'Final'}
      </TextLabel>
    </g>
  );
};

export default InfoCircle;
