import React from 'react';
import { cn } from '@utils';
import styles from './styles.scss';

type MenuRadiobuttonProps = {
  id: string;
  children: React.ReactNode;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value: number;
};

const MenuRadiobutton = ({
  id,
  children,
  checked,
  onChange,
  value,
}: MenuRadiobuttonProps) => {
  return (
    <label htmlFor={id} className={styles.label}>
      <div className={styles.label_wrapper}>
        {children}
        <input
          id={id}
          name="grid-group"
          type="radio"
          value={value}
          checked={checked}
          onChange={onChange}
          className={cn(styles.label_radio)}
        />
      </div>
    </label>
  );
};

export default MenuRadiobutton;
