import React from 'react';
import {
  ToothAngMDIcon,
  ToothRotMDIcon,
  ToothTorqBLIcon,
  ToothTranBLIcon,
  ToothTranEIIcon,
  ToothTranMDIcon,
} from 'assets/images/teeth-movement-table';
import { cn } from '@utils';
import styles from './styles.scss';
import { CloseButton } from '@view/components';

type HeaderProps = {
  onClose: () => void;
  isLabelsHidden?: boolean;
};

const Header = ({ onClose, isLabelsHidden }: HeaderProps) => (
  <div className={cn(styles.teethMovementHeader)}>
    <div className={cn(styles.teethMovementHeader_top)}>
      <button
        type="button"
        className={cn(styles.teethMovementHeader_top_backButton)}
        onClick={onClose}
      >
        <span className="sr-only">Close panel</span>
        <img width={16} src="/assets/images/arrow_left.svg" alt="Close icon" />
      </button>
      <h2 className={cn(styles.teethMovementHeader_top_title)}>
        Tooth Movement Table
      </h2>
      <CloseButton className="hidden sm:block" onClick={onClose} size="sm" />
    </div>
    <div
      className={cn(
        styles.teethMovementHeader_bottom,
        isLabelsHidden && 'hidden'
      )}
    >
      <div className={cn(styles.teethMovementHeader_bottom_container)}>
        <div className={cn(styles.teethMovementHeader_bottom_cell)}>
          <div className={cn(styles.teethMovementHeader_bottom_iconWrapper)}>
            <ToothTranEIIcon />
          </div>
          <div className={cn(styles.teethMovementHeader_bottom_labelWrapper)}>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              Tran
            </span>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              E/I
            </span>
          </div>
        </div>

        <div className={cn(styles.teethMovementHeader_bottom_cell)}>
          <div className={cn(styles.teethMovementHeader_bottom_iconWrapper)}>
            <ToothTranBLIcon />
          </div>
          <div className={cn(styles.teethMovementHeader_bottom_labelWrapper)}>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              Tran
            </span>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              B/L
            </span>
          </div>
        </div>

        <div className={cn(styles.teethMovementHeader_bottom_cell)}>
          <div className={cn(styles.teethMovementHeader_bottom_iconWrapper)}>
            <ToothTranMDIcon />
          </div>
          <div className={cn(styles.teethMovementHeader_bottom_labelWrapper)}>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              Tran
            </span>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              M/D
            </span>
          </div>
        </div>

        <div className={cn(styles.teethMovementHeader_bottom_cell)}>
          <div className={cn(styles.teethMovementHeader_bottom_iconWrapper)}>
            <ToothRotMDIcon />
          </div>
          <div className={cn(styles.teethMovementHeader_bottom_labelWrapper)}>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              Rot
            </span>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              M/D
            </span>
          </div>
        </div>

        <div className={cn(styles.teethMovementHeader_bottom_cell)}>
          <div className={cn(styles.teethMovementHeader_bottom_iconWrapper)}>
            <ToothAngMDIcon />
          </div>
          <div className={cn(styles.teethMovementHeader_bottom_labelWrapper)}>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              Ang
            </span>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              M/D
            </span>
          </div>
        </div>

        <div className={cn(styles.teethMovementHeader_bottom_cell)}>
          <div className={cn(styles.teethMovementHeader_bottom_iconWrapper)}>
            <ToothTorqBLIcon />
          </div>
          <div className={cn(styles.teethMovementHeader_bottom_labelWrapper)}>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              Torq
            </span>
            <span className={cn(styles.teethMovementHeader_bottom_label)}>
              B/L
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Header;
