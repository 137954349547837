import React from 'react';
import styles from './styles.scss';
import { cn } from '@utils';

type CloseButtonProps = {
  onClick: () => void;
  size: 'sm' | 'lg';
  className?: string;
};

const CloseButton = ({ onClick, size, className }: CloseButtonProps) => {
  return (
    <button
      type="button"
      className={cn(styles.closeButton_button, className)}
      onClick={onClick}
    >
      <span className="sr-only">Close panel</span>
      {size === 'sm' && (
        <img src="/assets/images/close_icon.svg" alt="Close icon" />
      )}
      {size === 'lg' && (
        <img src="/assets/images/close_icon_lg.svg" alt="Close icon" />
      )}
    </button>
  );
};

export default CloseButton;
