import React, { ButtonHTMLAttributes, useId, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.scss';
import { Tooltip } from 'react-tooltip';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  tooltip?: string;
};

const Button = ({ active, tooltip, ...props }: ButtonProps) => {
  const elementId = useId();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <>
      <button
        {...props}
        disabled={props.disabled}
        data-tooltip-id={elementId}
        data-tooltip-content={tooltip}
        onMouseEnter={(event) => {
          if (tooltip) setIsTooltipOpen(true);
          props.onMouseEnter?.(event);
        }}
        onMouseLeave={(event) => {
          if (tooltip) setIsTooltipOpen(false);
          props.onMouseLeave?.(event);
        }}
        onTouchStart={(event) => {
          if (tooltip) {
            setIsTooltipOpen(true);
            setTimeout(() => {
              setIsTooltipOpen(false);
            }, 1000);
          }
          props.onTouchStart?.(event);
        }}
        className={classNames(
          styles.button,
          {
            [styles.__active]: active,
          },
          props.className
        )}
        onClick={props.onClick}
      >
        {props.children}
      </button>
      {tooltip && (
        <Tooltip
          id={elementId}
          isOpen={isTooltipOpen}
          place="bottom"
          className="!font-medium !text-sm !bg-active-color !rounded !px-2 !py-1 z-50"
        />
      )}
    </>
  );
};

export default Button;
