import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from '@view/pages';
import './i18n/i18n';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

export default App;
