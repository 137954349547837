import { FileLoader as ThreeFileLoader } from 'three';


const loader = new ThreeFileLoader();

class FileLoader {
  static async load (url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      loader.load(
        url,
        (data) => resolve(data),
        (xhr) => {},
        (error) => reject(error)
      );
    });
  }
}

export default FileLoader;
