import React from 'react';
import styles from './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ILoadingCoverProps {
  isLoading: boolean;
  message?: string;
}

const LoadingCover = ({ isLoading, message }: ILoadingCoverProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.loadingCover}>
      <div className={styles.loadingCover_content}>
        <FontAwesomeIcon icon={faSpinner} size="4x" className={'fa-spin'} />
        {message && (
          <div className={styles.loadingCover_message}>{message}</div>
        )}
      </div>
    </div>
  );
};

export default LoadingCover;
