export class WebGLChecker {
  static isWebGLAvailable() {
    try {
      const canvas = document.createElement('canvas');

      return Boolean(
        window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
      );
    } catch (e) {
      return false;
    }
  }

  static isWebGL2Available() {
    try {
      const canvas = document.createElement('canvas');

      return Boolean(
        window.WebGL2RenderingContext && canvas.getContext('webgl2')
      );
    } catch (e) {
      return false;
    }
  }

  static getWebGLErrorMessage() {
    return WebGLChecker.getErrorMessage('WebGL', window.WebGLRenderingContext);
  }

  static getWebGL2ErrorMessage() {
    return WebGLChecker.getErrorMessage('WebGL', window.WebGL2RenderingContext);
  }

  static getErrorMessage(name: string, context: unknown) {
    if (context) {
      return `Your graphics card does not seem to support ${name}`;
    }

    return `Your browser does not seem to support ${name}`;
  }
}
