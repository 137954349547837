import { GeometryUtil } from '@utils';
import { Point } from '@data/models';


export const getArcDescription = (
  center: Point,
  radius: number,
  width: number,
  startDeg: number,
  endDeg: number
) => {
  const innerStart = GeometryUtil.toPoint({ radius, phi: endDeg }, center);
  const innerEnd = GeometryUtil.toPoint({ radius, phi: startDeg }, center);
  const outerStart = GeometryUtil.toPoint({ radius: radius + width, phi: endDeg }, center);
  const outerEnd = GeometryUtil.toPoint({ radius: radius + width, phi: startDeg }, center);

  const largeArcFlag = endDeg - startDeg <= 180 ? '0' : '1';

  return [
    `M ${outerStart.x} ${outerStart.y}`,
    `A ${radius + width} ${radius + width} 0 ${largeArcFlag} 0 ${outerEnd.x} ${outerEnd.y}`,
    `L ${innerEnd.x} ${innerEnd.y}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${innerStart.x} ${innerStart.y}`,
    `L ${outerStart.x} ${outerStart.y}`
  ].join(' ');
};
