import { DRACOLoader } from './DRACOLoader';
import BaseLoader from '../BaseLoader';
import { STLLoader } from './STLLoader';
import { EXRLoader } from './EXRLoader';


class GeometryLoader {
  private loaders: Map<string, BaseLoader>;

  constructor(loadingManager?: any) {
    this.loaders = new Map();

    this.loaders.set('drc', new DRACOLoader(loadingManager));
    this.loaders.set('stl', new STLLoader(loadingManager));
    this.loaders.set('exr', new EXRLoader(loadingManager));
  }

  load(url: string) {
    const [fileExtensionResult] = url.split('?')[0].split('.').reverse();

    if (!fileExtensionResult) {
      return null;
    }

    const fileExtension = fileExtensionResult.toLowerCase();
    const loader = this.loaders.get(fileExtension);

    if (!loader) {
      return null;
    }

    return loader.load(url);
  }

  loadByType (url: string, type: string) {
    const loader = this.loaders.get(type.toLowerCase());

    if (!loader) {
      return null;
    }

    return loader.load(url);
  }
}

export default GeometryLoader;
