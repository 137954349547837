import { ErrorDesc, ErrorType } from '@utils/ErrorDesc';


class Maybe<T> {
  private _value: T | null;
  private _error: ErrorDesc | null;

  constructor () {
    this._value = null;
    this._error = null;
  }

  static some<T> (value: T): Maybe<T> {
    const res = new Maybe<T>();
    res.value = value;
    return res;
  }

  static error<T> (value: ErrorDesc): Maybe<T> {
    const res = new Maybe<T>();
    res.error = value;
    return res;
  }

  // These two gets converts nullable type to strict type, because by design developer need to check state
  // by 'isPresent()' method previously. And by this, we don't need to convert (T | null) to T in needed place.
  get value (): T {
    return this._value as T;
  }

  get error (): ErrorDesc {
    return this._error as ErrorDesc;
  }

  set value (val: T) {
    this._value = val;
  }

  set error (val: ErrorDesc) {
    this._error = val;
  }

  isPresent (): boolean {
    return this._value !== null && (this._error === null || this._error.type === ErrorType.None);
  }
}

export default Maybe;
