import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import styles from './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

interface IModalProps {
  bodyClassName?: string;
  children: React.ReactNode | JSX.Element | string | number;
  icon?: React.ReactNode | JSX.Element;
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  withBackground?: boolean;
  rounded?: boolean;
}

const Modal = ({
  bodyClassName,
  children,
  icon,
  isOpen,
  onClose,
  title,
  withBackground = false,
  rounded = false,
}: IModalProps) => {
  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className={styles.modal} onClose={onCloseHandler}>
        {withBackground && (
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={styles.modal_background} />
          </Transition.Child>
        )}

        <div className={styles.modal_body}>
          <div className={styles.modal_body_wrap}>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(styles.modal_panel, {
                  [styles.__rounded]: rounded,
                })}
              >
                <div className={styles.modal_panel_header}>
                  {icon && (
                    <div className={styles.modal_panel_header_icon}>{icon}</div>
                  )}

                  {title && (
                    <Dialog.Title
                      as="h2"
                      className={styles.modal_panel_header_title}
                    >
                      {title}
                    </Dialog.Title>
                  )}

                  {onClose && (
                    <div
                      className={styles.modal_panel_header_close}
                      onClick={onClose}
                    >
                      <FontAwesomeIcon icon={faClose} size="lg" />
                    </div>
                  )}
                </div>

                <div
                  className={classNames(styles.modal_panel_body, bodyClassName)}
                >
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
