import BaseObject from './BaseObject';
import {
  Euler,
  LineSegments,
  Object3D,
  Sprite,
  SpriteMaterial,
  Vector2,
  Vector3
} from 'three';
import { IPRObjectDescription, JawType, MaterialTypes, ObjectTypes } from '@data/models';
import { Dictionary, Nullable } from '@types';
import { GeometryProvider, MaterialsProvider } from '@data/providers';
import { WebGLScene } from '../WebGLScene';


const getRotationAngle = (position: Vector3) => {
  const baseVector = new Vector2(0, 1);
  const positionVector = new Vector2(position.x, position.z);

  const scalarProduct = baseVector.x * positionVector.x + baseVector.y * positionVector.y;
  const baseVectorL = baseVector.length();
  const positionVectorL = positionVector.length();
  const cosAlpha = scalarProduct / (baseVectorL * positionVectorL);
  const alpha = Math.acos(cosAlpha);

  return position.x >= 0 ? alpha : -alpha;
};

class IPRObject extends BaseObject {
  private _rootObject: Nullable<Object3D> = null;
  private _firstTooth: Nullable<BaseObject> = null;
  private _secondTooth: Nullable<BaseObject> = null;

  constructor (obj: Object3D) {
    super(obj, ObjectTypes.IPR);
  }

  static getPositionBetweenTeeth (firstTooth?: BaseObject, secondTooth?: BaseObject) {
    if (!firstTooth || !secondTooth) {
      return null;
    }

    const rootRotation = firstTooth.mesh.parent!.quaternion.clone();

    const firstCenter = firstTooth.getCenter();
    const secondCenter = secondTooth.getCenter();

    let center = new Vector3();
    center.x = (secondCenter.x + firstCenter.x) / 2;
    center.y = (secondCenter.y + firstCenter.y) / 2;
    center.z = (secondCenter.z + firstCenter.z) / 2;

    center = center.applyQuaternion(rootRotation.invert());

    const rotationY = getRotationAngle(center);

    const rotation = new Euler();
    rotation.x = 0;
    rotation.y = rotationY;
    rotation.z = 0;

    return {
      center,
      rotation
    };
  }

  static async create (
    description: IPRObjectDescription,
    scene: WebGLScene,
    geometryProvider: GeometryProvider,
    materialsProvider: MaterialsProvider,
    objects: Dictionary<BaseObject>
  ) {
    const result = new Object3D();
    result.name = description.id;

    // Create line
    const line = new LineSegments(
      geometryProvider.getGeometryByDescription(description),
      materialsProvider.getMaterial(MaterialTypes.IPRLine)
    );
    result.add(line);

    // Create label sprite
    const endPoint = description.jaw === JawType.Upper
      ? new Vector3(0, 8, 15)
      : new Vector3(0, -8, 15);
    const sprite = new Sprite(materialsProvider.getMaterial(MaterialTypes.IPRLabel, description.label) as SpriteMaterial);
    sprite.scale.set(5,3,1);
    sprite.position.set(endPoint.x, endPoint.y, endPoint.z);

    result.add(sprite);

    // Initial position
    const position = result.position.clone();
    const rotation = result.rotation.clone();
    const scale = result.scale.clone();

    const firstTooth = objects[description.firstToothName];
    const secondTooth = objects[description.secondToothName];

    // Positioning
    const iprPosition = IPRObject.getPositionBetweenTeeth(
      firstTooth,
      secondTooth
    );

    if (iprPosition) {
      result.position.set(
        iprPosition.center.x,
        iprPosition.center.y,
        iprPosition.center.z
      );
      result.rotation.set(
        iprPosition.rotation.x,
        iprPosition.rotation.y,
        iprPosition.rotation.z,
        iprPosition.rotation.order
      );
    }

    const res = new IPRObject(result);
    res._firstTooth = firstTooth;
    res._secondTooth = secondTooth;
    res.setRootObject(scene.getRootObject());
    res.setInitial(position, rotation, scale);
    scene.getRootObject().add(res.mesh);

    res.visible = description.visible;
    return res;
  }

  setRootObject (obj: Object3D) {
    this._rootObject = obj;
  }

  async update (description: IPRObjectDescription, objects: Dictionary<BaseObject>) {
    const iprPosition = IPRObject.getPositionBetweenTeeth(
      objects[description.firstToothName],
      objects[description.secondToothName]
    );

    if (iprPosition) {
      this._mesh.position.set(
        iprPosition.center.x,
        iprPosition.center.y,
        iprPosition.center.z
      );
      this._mesh.rotation.set(
        iprPosition.rotation.x,
        iprPosition.rotation.y,
        iprPosition.rotation.z,
        iprPosition.rotation.order
      );
    }

    this._mesh.visible = description.visible;
  }

  async updateByAnimation (objects: Dictionary<BaseObject>): Promise<void> {
    if (!this._firstTooth || !this._secondTooth) {
      return;
    }

    if (!this.visible) {
      return;
    }

    const iprPosition = IPRObject.getPositionBetweenTeeth(
      this._firstTooth,
      this._secondTooth
    );

    if (iprPosition) {
      this._mesh.position.set(
        iprPosition.center.x,
        iprPosition.center.y,
        iprPosition.center.z
      );
      this._mesh.rotation.set(
        iprPosition.rotation.x,
        iprPosition.rotation.y,
        iprPosition.rotation.z,
        iprPosition.rotation.order
      );
    }
  }
}

export default IPRObject;
