import React from 'react';
import classNames from 'classnames';
import styles from './styles.scss';

interface IPageProps {
  className?: string;
  children: React.ReactNode | null;
}

const Page = ({ className, children }: IPageProps) => (
  <div className={classNames(styles.page, className)}>{children}</div>
);

export default Page;
