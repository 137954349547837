import React from 'react';
import styles from './styles.scss';
import { cn } from '@utils';

type DividerProps = {
  className?: string;
};

const Divider = ({ className }: DividerProps) => {
  return (
    <div className={cn(styles.divider, className)}>
      <div className={styles.divider_line} />
    </div>
  );
};

export default Divider;
