import BaseObject from './BaseObject';
import {
  BufferAttribute,
  Matrix4,
  Mesh,
} from 'three';
import {MaterialTypes, ObjectTypes, ToothObjectDescription} from '@data/models';
import { GeometryProvider, MaterialsProvider } from '@data/providers';
import { WebGLScene } from '../WebGLScene';
import {Dictionary} from '@types';

class ToothObject extends BaseObject {
  constructor(mesh: Mesh) {
    super(mesh, ObjectTypes.Tooth);
  }

  static async create(
    description: ToothObjectDescription,
    scene: WebGLScene,
    geometryProvider: GeometryProvider,
    materialsProvider: MaterialsProvider
  ) {
    const mesh = new Mesh(
      geometryProvider.getGeometryByDescription(description),
      materialsProvider.getMaterial(MaterialTypes.Tooth)
    );

    const position = mesh.position.clone();
    const rotation = mesh.rotation.clone();
    const scale = mesh.scale.clone();

    if (description.transform) {
      const matrix = new Matrix4();
      matrix.fromArray(description.transform);
      matrix.transpose();
      mesh.applyMatrix4(matrix);
    }

    ToothObject.updateOcclusions(mesh as Mesh, description);

    const obj = new ToothObject(mesh);
    obj.setInitial(position, rotation, scale);
    scene.getRootObject().add(obj.mesh);

    obj.id = description.id;
    obj.visible = description.visible;

    return obj;
  }



  async update (description: ToothObjectDescription, objects: Dictionary<BaseObject>) {
    ToothObject.updateOcclusions(this._mesh as Mesh, description);

    if (description.transform) {
      this.resetTransformation();
      const matrix = new Matrix4();
      matrix.fromArray(description.transform);
      matrix.transpose();
      this._mesh.applyMatrix4(matrix);
    }

    this._mesh.visible = description.visible;
  }

  static updateOcclusions(mesh: Mesh, description: any) {
    if (description.occlusionVisible && description.occlusionDistances?.length) {
      mesh.geometry.setAttribute("hasDistance", new BufferAttribute(new Float32Array(new Array(description.occlusionDistances.length).fill(1)), 1));
      mesh.geometry.setAttribute("distance", new BufferAttribute(new Float32Array(description.occlusionDistances), 1));
    } else {
      mesh.geometry.deleteAttribute("hasDistance");
    }
  }

}

export default ToothObject;
