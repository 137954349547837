import React from 'react';
import styles from './styles.scss';
import ViewTopImage from './images/top.svg?react';
import ViewFrontImage from './images/front.svg?react';
import ViewLeftImage from './images/left.svg?react';
import ViewRightImage from './images/right.svg?react';
import ViewBottomImage from './images/bottom.svg?react';

interface ISceneGizmoProps {
  onShowTop: () => void;
  onShowLeft: () => void;
  onShowRight: () => void;
  onShowFront: () => void;
  onShowBottom: () => void;
}

const SceneGizmo = ({
  onShowBottom,
  onShowFront,
  onShowLeft,
  onShowRight,
  onShowTop,
}: ISceneGizmoProps) => {
  return (
    <div className={styles.sceneGizmo}>
      <div />
      <div className={styles.sceneGizmo_button} onClick={onShowTop}>
        <ViewTopImage />
      </div>
      <div />

      <div className={styles.sceneGizmo_button} onClick={onShowLeft}>
        <ViewRightImage />
      </div>
      <div className={styles.sceneGizmo_button} onClick={onShowFront}>
        <ViewFrontImage />
      </div>
      <div className={styles.sceneGizmo_button} onClick={onShowRight}>
        <ViewLeftImage />
      </div>

      <div />
      <div className={styles.sceneGizmo_button} onClick={onShowBottom}>
        <ViewBottomImage />
      </div>
      <div />
    </div>
  );
};

export default SceneGizmo;
