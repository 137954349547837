import { SpotLight as ThreeSpotLight, Object3D, Vector3, Scene, SpotLightHelper } from 'three';
import BaseLight from './BaseLight';
import { ColorConverter } from '@utils';
import { LightTypes, TorchLightDescription } from '@data/models';

export class TorchLight extends BaseLight {
  protected _light: ThreeSpotLight;

  constructor(description: TorchLightDescription) {
    super(description.id, description.name, LightTypes.Torch);

    this._light = new ThreeSpotLight(
      ColorConverter.numberToThreeColor(description.color),
      description.intensity,
      description.distance,
      description.angle,
      description.penumbra,
      description.decay
    );

    this._light.position.set(description.position.x, description.position.y, description.position.z);
  }

  update (position: Vector3, target: Object3D) {
    // const newPosition = new Vector3();
    // newPosition.copy(position);
    // newPosition.normalize();
    // newPosition.multiplyScalar(-100);
    //
    // this._light.position.copy(newPosition);
    // this._light.target = target;
  }
}
