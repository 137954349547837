import { useEffect } from 'react';
import Color from 'color';
import { useSBSGetCompanyQuery } from '@data/services/supabase';
import useGetSBSCompanyQuery from '@data/services/supabase/api/queries/use-sbs-get-company-query';
import { useGetCompanyColorScheme } from './useGetCompanyColorScheme';

const root = document.documentElement;

type Params = Omit<
  Parameters<typeof useGetSBSCompanyQuery>[0],
  'subdomain' | 'options'
>;

const useCompanyColorScheme = ({ queryKeys }: { queryKeys: Params }) => {
  const getCompanyQuery = useSBSGetCompanyQuery({
    options: { enabled: !!queryKeys.companyId },
    companyId: queryKeys.companyId,
  });
  const companyColorScheme = useGetCompanyColorScheme(getCompanyQuery.data);

  useEffect(() => {
    if (companyColorScheme) {
      if (companyColorScheme.primary) {
        root.style.setProperty('--primary-color', companyColorScheme.primary);
      }
      if (companyColorScheme.secondary) {
        root.style.setProperty(
          '--secondary-color',
          companyColorScheme.secondary
        );
        root.style.setProperty(
          '--active-color',
          Color(`rgb(${companyColorScheme.secondary})`)
            .lightness(80)
            .rgb()
            .array()
            .map(Math.ceil)
            .join(' ')
        );
      }
    }
  }, [companyColorScheme]);

  return [companyColorScheme, getCompanyQuery.isLoading] as const;
};

export { useCompanyColorScheme };
