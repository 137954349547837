export default function addDashesToUUID(uuid: string) {
  // Ensure the UUID is a string
  uuid = uuid.toString();

  // Check if the length is correct for a UUID without dashes
  if (uuid.length !== 32) {
    throw new Error(
      'Invalid UUID format. UUID must be 32 characters long without dashes.'
    );
  }

  // Insert dashes at the appropriate positions
  return (
    uuid.slice(0, 8) +
    '-' +
    uuid.slice(8, 12) +
    '-' +
    uuid.slice(12, 16) +
    '-' +
    uuid.slice(16, 20) +
    '-' +
    uuid.slice(20)
  );
}
