import React, { useState } from 'react';
import { cn } from '@utils';
import PreviewMode from './PreviewMode';
import styles from './styles.scss';
import { PatientPhotoType, PhotosFileKindEnum } from '@data/models';
import { t } from 'i18next';

type PhotoProps = {
  fileKind: PhotosFileKindEnum;
  url: string;
  isViewPortPhotoEnabled: boolean;
  setSelectedPhoto?: React.Dispatch<
    React.SetStateAction<PatientPhotoType | undefined>
  >;
  selectedPhoto?: PatientPhotoType;
  onRadioChange?: () => void;
};

const Photo = ({
  fileKind,
  url,
  isViewPortPhotoEnabled,
  selectedPhoto,
  setSelectedPhoto,
  onRadioChange,
}: PhotoProps) => {
  const [isPreviewModeOpen, setIsPreviewModeOpen] = useState<boolean>(false);

  const handlePhotoClick = () => {
    if (!isViewPortPhotoEnabled) {
      setIsPreviewModeOpen(true);
    }
  };

  const handlePreviewModeClose = () => {
    setIsPreviewModeOpen(false);
  };

  return (
    <>
      {isPreviewModeOpen && (
        <PreviewMode
          isPreviewModeOpen={isPreviewModeOpen}
          onPreviewModeClose={handlePreviewModeClose}
          url={url}
        />
      )}
      <div>
        <img
          onClick={handlePhotoClick}
          src={url}
          className={styles.patientPhoto_img}
          alt={`Patient ${fileKind} photo`}
        />
        <div className={styles.patientPhoto_footer}>
          <input
            id={fileKind}
            name="photo-group"
            type="radio"
            value={fileKind}
            checked={selectedPhoto?.fileKind === fileKind}
            onChange={(event) => {
              onRadioChange?.();
              setSelectedPhoto?.({
                fileKind: event.target.value as PhotosFileKindEnum,
                url: url,
              });
            }}
            className={cn(
              styles.patientPhoto_radio,
              isViewPortPhotoEnabled ? 'inline-block' : 'hidden'
            )}
          />
          <label className={styles.patientPhoto_label} htmlFor={fileKind}>
            {t(fileKind)}
          </label>
        </div>
      </div>
    </>
  );
};

export default Photo;
