import React from 'react';
import styles from './styles.scss';

interface ILinkProps {
  children: string;
  target?: string;
  to: string;
}

const Link = ({ children, target, to }: ILinkProps) => {
  return (
    <a className={styles.link} target={target} href={to}>
      {children}
    </a>
  );
};

export default Link;
