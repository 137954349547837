import { DRACOLoader as ThreeDRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import BaseLoader from '../BaseLoader';

export class DRACOLoader extends BaseLoader {
  constructor(manager: any) {
    super(manager);

    this.loader = new ThreeDRACOLoader(manager);
    this.loader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.5/');
    this.loader.preload();
  }
}
