import { PatientPhotosProps, PatientPhotoType } from '@data/models';
import { Sidebar } from '@view/layouts';
import React, { useState } from 'react';
import PatientPhotosFooter from './PatientPhotosFooter';
import PatientPhotosHeader from './PatientPhotosHeader';
import Photo from './Photo';
import styles from './styles.scss';
import ViewportPhoto from './ViewportPhoto';

const PatientPhotos = ({
  isOpen,
  onClose,
  photos,
  setIsViewPortPhotoEnabled,
  isViewPortPhotoEnabled,
  colorScheme,
}: PatientPhotosProps) => {
  const [opacitySliderValue, setOpacitySliderValue] = useState<any>(0.5);
  const [selectedPhoto, setSelectedPhoto] = useState<
    PatientPhotoType | undefined
  >(photos[0]);
  const [viewportKey, setViewportKey] = useState(0);

  const resetViewportPhoto = () => {
    setViewportKey((prevKey) => prevKey + 1);
  };

  const handlePatientPhotosClose = () => {
    onClose();
  };

  const handleRadioChange = () => {
    resetViewportPhoto();
  };

  const handleOpacityChange = (value: number | number[]) => {
    setOpacitySliderValue(value);
  };

  const opacity = 1 - opacitySliderValue;

  return (
    <>
      {isViewPortPhotoEnabled && selectedPhoto?.url && (
        <ViewportPhoto
          key={viewportKey}
          opacity={opacity}
          url={selectedPhoto?.url}
          title={selectedPhoto?.fileKind ?? 'Viewport Photo'}
          onCloseIconClick={() => setIsViewPortPhotoEnabled(false)}
          colorScheme={colorScheme}
        />
      )}
      <Sidebar size="sm" isOpen={isOpen}>
        <div className={styles.patientPhotos}>
          <PatientPhotosHeader
            onPatientPhotosClose={handlePatientPhotosClose}
            isViewPortPhotoEnabled={isViewPortPhotoEnabled}
            setIsViewPortModeEnabled={setIsViewPortPhotoEnabled}
          />
          <div className={styles.patientPhotos_photos}>
            {photos.map((photo) => {
              return (
                <Photo
                  key={photo.fileKind}
                  fileKind={photo.fileKind}
                  url={photo.url}
                  isViewPortPhotoEnabled={isViewPortPhotoEnabled}
                  selectedPhoto={selectedPhoto}
                  setSelectedPhoto={setSelectedPhoto}
                  onRadioChange={handleRadioChange}
                />
              );
            })}
          </div>
          {isViewPortPhotoEnabled && (
            <PatientPhotosFooter
              opacityValue={opacitySliderValue}
              onOpacityChange={handleOpacityChange}
            />
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default PatientPhotos;
