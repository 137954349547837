import { AmbientLight } from './AmbientLight';
import { HemisphereLight } from './HemisphereLight';
import { DirectionalLight } from './DirectionalLight';
import { PointLight } from './PointLight';
import { SpotLight } from './SpotLight';
import { TorchLight } from './TorchLight';
import BaseManager from '../BaseManager';
import { differenceBy } from 'lodash';
import {
  AmbientLightDescription,
  DirectionalLightDescription,
  HemisphereLightDescription,
  LightDescription,
  LightTypes,
  PointLightDescription,
  SpotLightDescription,
  TorchLightDescription
} from '@data/models';
import { Dictionary } from '@types';
import { Object3D, Scene } from 'three';
import BaseLight from './BaseLight';
import { Renderer } from '../Renderer';


class LightsManager extends BaseManager<LightDescription> {
  private readonly _lights: Dictionary<BaseLight>;
  private readonly _types: Dictionary<any>;
  private readonly _rootObject: Object3D;
  private readonly _renderer: Renderer;

  constructor (scene: Scene, root: Object3D, renderer: Renderer) {
    super(scene);

    this._rootObject = root;
    this._renderer = renderer;
    this._lights = {};
    this._types = {
      [LightTypes.Ambient]: (description: LightDescription) =>
        new AmbientLight(description as AmbientLightDescription),
      [LightTypes.Hemisphere]: (description: LightDescription) =>
        new HemisphereLight(description as HemisphereLightDescription),
      [LightTypes.Point]: (description: LightDescription) =>
        new PointLight(description as PointLightDescription),
      [LightTypes.Directional]: (description: LightDescription) =>
        new DirectionalLight(description as DirectionalLightDescription),
      [LightTypes.Spot]: (description: LightDescription) =>
        new SpotLight(description as SpotLightDescription),
      [LightTypes.Torch]: (description: LightDescription) =>
        new TorchLight(description as TorchLightDescription)
    };
  }

  async createItem (description: LightDescription) {
    const light = this._types[description.type](description);

    light.addToScene(this._scene);
    this._lights[description.id] = light;
  }

  removeItem (id: string) {
    const light = this._lights[id];

    if (!light) {
      return;
    }

    light.removeFromScene(this._scene);
    delete this._lights[id];
  }

  getChanges (descriptions: LightDescription[]) {
    const lights: { id: string }[] = Object.values(this._lights).map((light) => ({
      id: light.id
    }));

    return {
      removed: differenceBy(lights, descriptions, 'id'),
      added: differenceBy(descriptions, lights, 'id'),
    };
  }

  async updateTorchLights () {
    const torchLighs = Object
      .values(this._lights)
      .filter((light) => light.type === LightTypes.Torch) as TorchLight[];

    torchLighs.forEach((light) => {
      light.update(this._renderer.getCamera().getCamera().position, this._rootObject);
    });
  }

  async update (descriptions: LightDescription[]): Promise<void> {
    const changes = this.getChanges(descriptions);

    await Promise.all([
      ...changes.added.map((description) => this.createItem(description)),
      ...changes.removed.map((desc) => this.removeItem(desc.id)),
    ]);
  }

  getById (id: string) {
    return this._lights[id];
  }
}

export default LightsManager;
