import React from 'react';
import { StepSlider } from '@view/components';
import styles from './styles.scss';

type PatientPhotosFooterProps = {
  opacityValue: number;
  onOpacityChange: (value: number | number[]) => void;
};

const PatientPhotosFooter = ({
  opacityValue,
  onOpacityChange,
}: PatientPhotosFooterProps) => {
  const formattedOpacityString = Math.trunc(opacityValue * 100) + '%';

  return (
    <div className={styles.photosFooter}>
      <div className={styles.photosFooter_title}>
        <span>Transparency</span>
        <span>{formattedOpacityString}</span>
      </div>
      <StepSlider
        value={opacityValue}
        onChange={onOpacityChange}
        min={0}
        max={1}
        step={0.01}
      />
    </div>
  );
};

export default PatientPhotosFooter;
