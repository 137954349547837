import { CameraType, PerspectiveCameraDescription } from '@data/models';


export const defaultPerspectiveCameraDescription: PerspectiveCameraDescription = {
  type: CameraType.Perspective,
  name: 'Perspective Camera',
  fov: 30,
  near: 1,
  far: 10000,
  zoom: 1.8,
  position: {
    x: 0,
    y: 0,
    z: 300,
  },
  target: {
    x: 0,
    y: 0,
    z: 0,
  },
  up: {
    x: 0,
    y: 1,
    z: 0,
  },
};
