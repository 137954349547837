import { cn } from '@utils';
import styles from './styles.scss';
import React from 'react';

type CheckboxProps = {
  onChange?: () => void;
  id: string;
  checked?: boolean;
};

const Checkbox = ({ onChange, id, checked }: CheckboxProps) => {
  return (
    <input
      id={id}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      className={cn(styles.customCheckbox)}
    />
  );
};

export default Checkbox;
