import BaseObject from './BaseObject';
import { GridHelper, Material, Object3D } from 'three';
import { GridObjectDescription, ObjectTypes } from '@data/models';
import { WebGLScene } from '../WebGLScene';
import { GeometryProvider, MaterialsProvider } from '@data/providers';
import { GeometryUtil } from '@utils';


class GridObject extends BaseObject {
  constructor (mesh: Object3D) {
    super(mesh, ObjectTypes.Grid);
  }

  static async create(
    description: GridObjectDescription,
    scene: WebGLScene,
    geometryProvider: GeometryProvider,
    materialsProvider: MaterialsProvider
  ) {
    const wholeSize = 500;
    const divisions = wholeSize / description.size;
    const mesh = new GridHelper(wholeSize, divisions, 0x344952, 0x344952);

    mesh.renderOrder = 999;
    (mesh.material as Material).depthTest = false;
    (mesh.material as Material).opacity = 0.8;

    mesh.rotation.set(GeometryUtil.degToRad(90), 0, 0);

    const obj = new GridObject(mesh);
    obj.visible = description.visible;
    scene.getScene().add(obj.mesh);

    return obj;
  }
}

export default GridObject;
