import { EventEmitter } from '@utils';


export class BaseControl extends EventEmitter {
  public control: any;

  constructor() {
    super();

    this.control = null;
  }
}
