import axios, { AxiosInstance } from 'axios';
import { ErrorDesc, ErrorType, Maybe } from '@utils';


class BaseClient {
  protected _headers: {[key: string]: string};
  protected _instance: AxiosInstance;

  static getClientApiUrl () {
    if (!process.env.CLIENT_CONFIGS) {
      throw new Error('Missing client configuration in process environment');
    }

    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get('client_id') || 'LCL';

    if (!clientId) {
      throw new Error('Missing client_id in search params');
    }

    const configs = JSON.parse(process.env.CLIENT_CONFIGS);

    if (!configs) {
      throw new Error('Incorrect client configuration in process environment');
    }

    if (!configs[clientId]) {
      throw new Error(`Missing config for the client ${clientId}`);
    }

    return configs[clientId];
  }

  constructor () {
    this._headers = {
      'Access-Control-Allow-Origin': '*'
    };

    this._instance = axios.create({
      baseURL: BaseClient.getClientApiUrl(),
      headers: { ...this._headers }
    });
  }

  get instance() {
    return this._instance;
  }

  get headers () {
    return this._headers;
  }

  validateResponseCode (response: Response): Maybe<Response> {
    switch (response.status) {
      case 400:
      case 403:
        return Maybe.error<Response>(new ErrorDesc(
          ErrorType.ForbiddenAccess,
          'Forbidden access'
        ));
      case 404:
        return Maybe.error<Response>(new ErrorDesc(
          ErrorType.NotFound,
          'Resource was not found by url.'
        ));
      case 200:
        return Maybe.some<Response>(response);
      default:
        return Maybe.error<Response>(new ErrorDesc(
          ErrorType.Unexpected,
          `Unexpected network error. Status code: ${response.status}.`
        ));
    }
  }
}

export default BaseClient;
