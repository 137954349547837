import React from 'react';
import { Point } from '@data/models';


interface ILineProps {
  className?: string;
  start: Point;
  end: Point;
  width: number;
}

const Line = ({
  className,
  start,
  end,
  width
}: ILineProps) => (
  <line className={className} x1={start.x} y1={start.y} x2={end.x} y2={end.y} strokeWidth={width} />
);

export default Line;
