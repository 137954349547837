export enum JawType {
  Upper = 'upper',
  Lower = 'lower'
}

export enum ContextType {
  WebGL = 'webgl',
  WebGL2 = 'webgl2',
}

export enum CameraType {
  Perspective = 'perspective',
  Orthographic = 'orthographic'
}

export type PerspectiveCameraDescription = {
  type: CameraType.Perspective;
  name: string;
  fov: number;
  near: number;
  far: number;
  zoom: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
  target: {
    x: number;
    y: number;
    z: number;
  };
  up: {
    x: number;
    y: number;
    z: number;
  };
};

export type OrthographicCameraDescription = {
  type: CameraType.Orthographic;
  name: string;
  width: number;
  height: number;
  near: number;
  far: number;
  zoom: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
  target: {
    x: number;
    y: number;
    z: number;
  };
  up: {
    x: number;
    y: number;
    z: number;
  };
};

export type OrbitControlDescription = {
  azimuthAngle: number;
  polarAngle: number;

  target: {
    x: number;
    y: number;
    z: number;
  };
}

export type CameraDescription =
  | PerspectiveCameraDescription
  | OrthographicCameraDescription;

export type RendererDescription = {
  pixelRatio: number;
  contextType: ContextType;
  antialias: boolean;
};

export enum LightTypes {
  Ambient = 'ambient',
  Hemisphere = 'hemisphere',
  Point = 'point',
  Directional = 'directional',
  Spot = 'spot',
  Torch = 'torch'
}

export interface ILightDescription {
  id: string;
  name: string;
  type: LightTypes;
  intensity: number;
}

export interface AmbientLightDescription extends ILightDescription {
  color: number;
  type: LightTypes.Ambient;
}

export interface HemisphereLightDescription extends ILightDescription {
  skyColor: number;
  groundColor: number;
  type: LightTypes.Hemisphere;
}

export interface PointLightDescription extends ILightDescription {
  color: number;
  distance: number;
  decay: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
  type: LightTypes.Point;
}

export interface SpotLightDescription extends ILightDescription {
  color: number;
  angle: number;
  penumbra: number;
  decay: number;
  distance: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
  type: LightTypes.Spot;
}

export interface DirectionalLightDescription extends ILightDescription {
  color: number;
  position: {
    x: number,
    y: number,
    z: number
  };
  type: LightTypes.Directional;
}

export interface TorchLightDescription extends ILightDescription {
  color: number;
  position: {
    x: number,
    y: number,
    z: number
  };
  angle: number;
  penumbra: number;
  decay: number;
  distance: number;
  type: LightTypes.Torch;
}

export type LightDescription =
  | AmbientLightDescription
  | HemisphereLightDescription
  | PointLightDescription
  | SpotLightDescription
  | DirectionalLightDescription
  | TorchLightDescription;

export enum ObjectTypes {
  Generic = 'generic',
  Tooth = 'tooth',
  ToothRoot = 'tooth-root',
  Gingiva = 'gingiva',
  IPR = 'ipr',
  Attachment = 'attachment',
  Biteramp = 'biteramp',
  Pontic = 'pontic',
  Elastic = 'elastic',
  Grid = 'grid',
  ToothOverlay = 'tooth-overlay',
  ToothRootOverlay = 'tooth-root-overlay'
}

export enum MaterialTypes {
  Generic = 'generic',
  Tooth = 'tooth',
  ToothOverlay = 'tooth-overlay',
  ToothRootOverlay = 'tooth-root-overlay',
  Gingiva = 'gingiva',
  IPRLabel = 'ipr_label',
  IPRLine = 'ipr_line',
  Attachment = 'attachment',
  Biteramp = 'biteramp',
  Pontic = 'pontic',
  Elastic = 'elastic',
}

export type GenericObjectDescription = {
  id: string;
  type: ObjectTypes.Generic;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
}

export type GingivaObjectDescription = {
  id: string;
  type: ObjectTypes.Gingiva;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
};

export type ToothObjectDescription = {
  id: string;
  type: ObjectTypes.Tooth;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
  occlusionDistances: number[] | null;
  occlusionVisible: boolean;
};

export type ToothRootObjectDescription = {
  id: string;
  type: ObjectTypes.ToothRoot;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
};

export type ToothOverlayObjectDescription = {
  id: string;
  type: ObjectTypes.ToothOverlay;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
}

export type ToothRootOverlayObjectDescription = {
  id: string;
  type: ObjectTypes.ToothRootOverlay;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
}

export type AttachmentObjectDescription = {
  id: string;
  type: ObjectTypes.Attachment;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
  toothId: number;
};

export type ElasticObjectDescription = {
  id: string;
  type: ObjectTypes.Elastic;
  visible: boolean;
  transform: number[] | null;
  points: number[][];
  number: number;
  elasticType: 'Button' | 'Hook';
  jaw: JawType;
  toothId: number;
};

export type BiterampObjectDescription = {
  id: string;
  type: ObjectTypes.Biteramp;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
  toothId: number;
};

export type PonticObjectDescription = {
  id: string;
  type: ObjectTypes.Pontic;
  url: string;
  visible: boolean;
  transform: number[] | null;
  jaw: JawType;
  toothId: number;
  occlusionDistances: number[] | null;
  occlusionVisible: boolean;
};

export type IPRObjectDescription = {
  id: string;
  type: ObjectTypes.IPR;
  visible: boolean;
  label: string;
  transform: number[] | null;
  jaw: JawType;
  firstToothName: string;
  secondToothName: string;
};

export type GridObjectDescription = {
  id: string;
  type: ObjectTypes.Grid;
  visible: boolean;
  jaw: JawType;
  transform: number[] | null;
  size: number;
}

export type ObjectDescription =
  | GenericObjectDescription
  | GingivaObjectDescription
  | ToothObjectDescription
  | ToothRootObjectDescription
  | ToothOverlayObjectDescription
  | ToothRootOverlayObjectDescription
  | AttachmentObjectDescription
  | ElasticObjectDescription
  | BiterampObjectDescription
  | PonticObjectDescription
  | IPRObjectDescription
  | GridObjectDescription;

export type SceneDescription = {
  name: string;
  background: number;
  width: number;
  height: number;
  position: { x: number, y: number, z: number}
  camera: CameraDescription;
  orbitControl: OrbitControlDescription;
  renderer: RendererDescription;
  lights: LightDescription[];
  objects: ObjectDescription[];
};


