type Dictionary<T> = {
  [key: string]: T;
};

type EventCallback = (...args: any[]) => void;

class EventEmitter {
  private callbacks: Dictionary<Dictionary<EventCallback>>;

  constructor() {
    this.callbacks = {};
  }

  emit(event: string, ...payload: any[]) {
    Object.values(this.callbacks[event] || {}).forEach((cb) => cb(...payload));

    return this;
  }

  clearEventListeners() {
    this.callbacks = {};

    return this;
  }

  addEventListener(event: string, name: string, cb: EventCallback) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = {};
    }

    this.callbacks[event][name] = cb;

    return this;
  }

  removeEventListener(event: string, name: string) {
    if (this.callbacks[event]) {
      delete this.callbacks[event][name];
    }

    return this;
  }

  hasEventListener(event: string, name: string) {
    return (
      this.callbacks[event] !== null &&
      typeof this.callbacks[event] !== 'undefined' &&
      this.callbacks[event][name] !== null &&
      typeof this.callbacks[event][name] !== 'undefined'
    );
  }
}

export default EventEmitter;
