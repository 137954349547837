import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'styles/install.scss';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'styles/tailwind.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Can\'t find root element. Please check the code.');
}

const root = createRoot(container);

const queryClient = new QueryClient();
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools position={'right'} />
    </QueryClientProvider>
  </BrowserRouter>
);
