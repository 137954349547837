import { EPSILON } from '@data/constants';
import { Sphere } from 'three';


const isEqualFloat = (a: number, b: number, epsilon: number): boolean => Math.abs(a - b) <= epsilon;

export const isEqualSpheres = (a: Sphere, b: Sphere, epsilon: number = EPSILON) => {
  return isEqualFloat(a.radius, b.radius, epsilon)
    && isEqualFloat(a.center.x, b.center.x, epsilon)
    && isEqualFloat(a.center.y, b.center.y, epsilon)
    && isEqualFloat(a.center.z, b.center.z, epsilon);
};
