import React, { useEffect, useState } from 'react';
import { Point } from '@data/models';
import Arc from '../Arc';
import styles from './styles.scss';
import { GeometryUtil } from '@utils';
import Line from '@view/components/Knob/Line';

const getStageByMousePosition = (
  point: Point,
  center: Point,
  size: number,
  radius: number,
  fullDeg: number,
  tickStepDeg: number,
  scaleWidth: number,
  offset: Point
): number | null => {
  const offsetX = offset.x;
  const offsetY = offset.y;

  const resultX = point.x - offsetX;
  const resultY = point.y - offsetY;

  if (resultX < 0 || resultY < 0) {
    return null;
  }

  const polarPoint = GeometryUtil.getPolarPointByCenter(
    { x: point.x - offsetX, y: point.y - offsetY },
    center
  );

  const halfScaleWidth = scaleWidth / 2;
  if (
    polarPoint.radius < radius - halfScaleWidth ||
    polarPoint.radius > radius + halfScaleWidth
  ) {
    return null;
  }

  // We need to extend clickable zone by 'extender', because we should detect clicks below start and end ticks.
  const cornerExtenderDeg = tickStepDeg / 2;
  const activePart = fullDeg + cornerExtenderDeg * 2;

  // The part below needed for offset result phi value to interval [0, fullDeg]
  // Pre:
  // y ^ 90 deg
  //   |
  //   |
  // 0 +--------> 0 deg
  //            x
  // We need to 'rotate' scale for correct detecting result stage, because we are using arc, not full circle. This arc
  // has two parts in deg: [-225, 0] [360, 315], clockwise direction.
  // 1. Make second part [360, 315] as negative part [0, -45].
  let resultPhi = GeometryUtil.radToDeg(polarPoint.phi);
  resultPhi = resultPhi > 225 + cornerExtenderDeg ? resultPhi - 360 : resultPhi;

  // 2. Add 45deg to PHI to create the desired interval [0, fullDeg]
  resultPhi += 45 + cornerExtenderDeg;

  // 3. For all values after 'fullDeg' we need to save max value as 'fullDeg'.
  resultPhi = resultPhi >= activePart ? 0 : activePart - resultPhi;

  if (resultPhi < 0 || resultPhi > activePart) {
    return null;
  }

  return Math.abs(Math.round((resultPhi - tickStepDeg / 2) / tickStepDeg));
};

interface IScaleProps {
  size: number;
  center: Point;
  radius: number;
  stagesAll: number;
  stageCurrent: number;
  onStageClick: (stage: number) => void;
  offset: Point;
}

const Scale = ({
  size,
  center,
  radius,
  stagesAll,
  stageCurrent,
  onStageClick,
  offset,
}: IScaleProps) => {
  // We are using arc on 270 deg (fullDeg). Start deg is -225, because:
  // y ^ -90 deg
  //   |
  //   |
  // 0 +--------> 0 deg
  //   |        x
  //   |
  //   | -270 / 90 deg
  // (counterclockwise / clockwise)
  //
  // 1. Full circle 360 deg - 270 deg = 90 deg, unfilled part
  // 2. 90 deg / 2 = 45 deg: parts to the left and right of the y-axis
  // 3. Start deg = -270deg + 45deg = -225deg.
  //    End deg = 0 + 45deg = 45deg OR startDeg + fullDeg = -225 + 270 = 45deg.
  const startDeg = -225;
  const fullDeg = 270;

  const scaleWidth = size >= 300 ? 16 : 10;
  const fullWidth = size >= 300 ? 35 : 30;

  const tickSmallWidth = size >= 300 ? 7 : 4;
  const tickBigWidth = size >= 300 ? 12 : 7;
  const tickStartR = radius - tickSmallWidth / 2;
  const tickEndR = radius + tickSmallWidth / 2;
  const tickBigStartR = radius - tickBigWidth / 2;
  const tickBigEndR = radius + tickBigWidth / 2;
  const tickStepDeg = stagesAll > 1 ? fullDeg / (stagesAll - 1) : 0;
  const tickStepPaddingDeg = 0;

  const scaleStartDeg = startDeg + tickStepPaddingDeg;

  const ticks = Array.from({ length: stagesAll }, (item, index) => {
    let phi: number = startDeg;
    if (index === 0) {
      phi = startDeg;
    } else if (index === stagesAll - 1) {
      phi = startDeg + fullDeg;
    } else {
      phi = startDeg + tickStepPaddingDeg + tickStepDeg * index;
    }
    const isBigTick = index !== 0 && index !== stagesAll - 1 && index % 5 === 0;

    const start = {
      radius: isBigTick ? tickBigStartR : tickStartR,
      phi,
    };
    const end = {
      radius: isBigTick ? tickBigEndR : tickEndR,
      phi,
    };
    const tickCenter = { radius, phi };

    return {
      center: GeometryUtil.toPoint(tickCenter, center),
      start: GeometryUtil.toPoint(start, center),
      end: GeometryUtil.toPoint(end, center),
      stage: index,
    };
  });

  let filledPart = 0;
  if (stagesAll === 1) {
    filledPart = 0;
  } else if (stagesAll > 1) {
    filledPart = (stageCurrent / (stagesAll - 1)) * fullDeg;
  }
  // const filledPart = (stageCurrent / stagesAll) * fullDeg + 5;

  const cornerDotStartPosition = GeometryUtil.toPoint(
    { radius, phi: scaleStartDeg },
    center
  );

  const currentStageDotPosition = GeometryUtil.toPoint(
    { radius, phi: startDeg + tickStepPaddingDeg + tickStepDeg * stageCurrent },
    center
  );

  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    const onMouseDown = () => {
      setIsDragging(true);
    };

    const onMouseUp = () => {
      setIsDragging(false);
    };

    const onMouseMove = (event: any) => {
      if (!isDragging) {
        return;
      }

      const stage = getStageByMousePosition(
        { x: event.clientX, y: event.clientY },
        center,
        size,
        radius,
        fullDeg,
        tickStepDeg,
        fullWidth,
        offset
      );

      if (stage === null) {
        return;
      }

      if (stage !== stageCurrent) {
        onStageClick(stage);
      }
    };

    const onMouseClick = (event: any) => {
      const stage = getStageByMousePosition(
        { x: event.clientX, y: event.clientY },
        center,
        size,
        radius,
        fullDeg,
        tickStepDeg,
        fullWidth,
        offset
      );

      if (stage === null) {
        return;
      }

      if (stage !== stageCurrent) {
        onStageClick(stage);
      }
    };

    const onTouchMove = (event: any) => {
      const touch = event.touches[0];
      if (!touch) {
        return;
      }

      if (!isDragging) {
        return;
      }

      const stage = getStageByMousePosition(
        { x: touch.clientX, y: touch.clientY },
        center,
        size,
        radius,
        fullDeg,
        tickStepDeg,
        fullWidth,
        offset
      );

      if (stage === null) {
        return;
      }

      if (stage !== stageCurrent) {
        onStageClick(stage);
      }
    };

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('click', onMouseClick);

    document.addEventListener('touchstart', onMouseDown);
    document.addEventListener('touchend', onMouseUp);
    document.addEventListener('touchmove', onTouchMove);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('click', onMouseClick);

      document.removeEventListener('touchstart', onMouseDown);
      document.removeEventListener('touchend', onMouseUp);
      document.removeEventListener('touchmove', onTouchMove);
    };
  }, [isDragging, offset]);

  return (
    <g x={center.x - radius} y={center.y - radius}>
      <circle
        className={styles.knob_scale_cornerDot}
        cx={cornerDotStartPosition.x}
        cy={cornerDotStartPosition.y}
        r={scaleWidth / 2}
      />
      <Arc
        className={styles.knob_scale_arc}
        center={center}
        radius={radius}
        startDeg={scaleStartDeg}
        endDeg={scaleStartDeg + filledPart}
        width={scaleWidth}
      />
      <circle
        className={styles.knob_scale_currentStageCornerDot}
        cx={currentStageDotPosition.x}
        cy={currentStageDotPosition.y}
        r={scaleWidth / 2}
      />
      {ticks.map((tick, index) => (
        <g className={styles.knob_scale_tick} key={`${index}_${tick.center.x}`}>
          <Line
            className={styles.knob_scale_tick_line}
            start={tick.start}
            end={tick.end}
            width={1}
          />
        </g>
      ))}
      <circle
        className={styles.knob_scale_currentStageDot}
        cx={currentStageDotPosition.x}
        cy={currentStageDotPosition.y}
        r={size >= 300 ? scaleWidth / 2 - 3 : scaleWidth / 2}
      />
    </g>
  );
};

export default Scale;
