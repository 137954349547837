import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Divider,
  ErrorCover,
  LoadingCover,
  PatientInfoSidebar,
} from '@view/components';
import {
  Header,
  LeftBar,
  Manufacturer,
  Page,
  ViewSettings,
} from '@view/layouts';
import {
  MobileMenu,
  PatientPhotos,
  SceneWidget,
  TeethMovement,
} from '@view/widgets';
import {
  GizmoViewOrientation,
  IViewSettings,
  PatientPhotoType,
  PatientInfo, IOcclusionFeatureConfig,
} from '@data/models';
import styles from './styles.scss';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import ToothMovementIcon from 'assets/images/tmt_icon.svg?react';
import { ConfigProvider, DataProvider, useProvider } from '@data/providers';
import { ISceneData } from '@data/providers/SceneDataProvider';
import { addDashesToUUID, cn, extractCompanyId } from '@utils';
import { useCompanyColorScheme } from 'src/hooks/useCompanyColorScheme';
import { useSession } from '@data/atoms/session';
import { BooleanKeys } from '@types';
import Histogram from '@view/components/OcclusionHistogram';

const HomePage = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMobileSideBarOpen, setIsMobileSideBarState] =
    useState<boolean>(false);
  const [isViewPortPhotoEnabled, setIsViewPortPhotoEnabled] =
    useState<boolean>(false);
  const [isManufacturerOpen, setManufacturerState] = useState<boolean>(false);
  const [isPatientPhotosOpen, setIsPatientPhotosOpen] =
    useState<boolean>(false);
  const [isPatientInfoOpen, setIsPatientInfoOpen] = useState<boolean>(false);
  const [isTeethMovementOpen, setTeethMovementState] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<IViewSettings>({
    upperJawVisible: true,
    lowerJawVisible: true,
    attachments: true,
    pontics: true,
    iprs: true,
    elastics: true,
    biteRamps: true,
    grid: false,
    gridSize: 5,
    overlay: false,
    occlusions: false,
    hideGum: false,
  });

  const parameters = useMemo(
    () => QueryString.parse(location.search),
    [location.search]
  );
  const [patientInfo, setPatientInfo] = useState<PatientInfo>();
  const [session, setSession] = useSession();
  const [assetUrl, setAssetUrl] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string>('common');
  const [sceneData, setSceneData] = useState<ISceneData>();

  const [photos, setPhotos] = useState<PatientPhotoType[]>([]);
  const [treatmentPlanUrl, setTreatmentPlanUrl] = useState<
    string | undefined
  >();

  const [configProvider, setConfigProvider] = useProvider(ConfigProvider, clientId);

  const [companyColorScheme, isCompanyColorSchemeLoading] =
    useCompanyColorScheme({
      queryKeys: { companyId: session.companyId },
    });

  const handleClosePatientPhotos = () => setIsPatientPhotosOpen(false);
  const patientPhotosToggle = () =>
    setIsPatientPhotosOpen((prevState) => !prevState);
  const isPhotos = photos && photos.length > 0;
  const hasTeethMovement = Boolean(sceneData?.teethMovement);

  useEffect(() => {
    const load = async () => {
      const dataProvider = new DataProvider();
      const data = await dataProvider.getDataByParameters(parameters);

      if (data) {
        let companyId = '';
        try {
          companyId = addDashesToUUID(extractCompanyId(data.assetUrl) || '');
        } catch (e) {
          /* empty */
        }

        if (session.companyId == null) {
          setSession((s) => {
            return {
              ...s,
              companyId,
            };
          });
        }
        setPatientInfo(JSON.parse(data.patientInfoParam));
        setAssetUrl(data.assetUrl);
        setClientId(data.clientId);
        setConfigProvider(new ConfigProvider(data.clientId));
        setTreatmentPlanUrl(data.treatmentPlanUrl);
      }

      setIsLoading(false);
    };

    load();
  }, [parameters]);

  useEffect(() => {
    const storeInComponentState = (event: MessageEvent<any>) => {
      if (event.data.type === 'photos') {
        setPhotos(event.data.data);
      }
    };
    window.addEventListener('message', storeInComponentState);

    return () => window.removeEventListener('message', storeInComponentState);
  }, [isLoading]);

  const toggleViewSetting = (setting: BooleanKeys<IViewSettings>) => {
    setViewSettings((prev) => {
      return { ...prev, [setting]: !prev?.[setting] };
    });
  };

  const setViewSetting = (setting: keyof IViewSettings, value: any) => {
    setViewSettings((prev) => {
      return { ...prev, [setting]: value };
    });
  };

  const gizmoViewOrientationChanged = (side: GizmoViewOrientation) => {
    if (side === GizmoViewOrientation.Top) {
      setViewSettings({
        ...viewSettings,
        lowerJawVisible: false,
        upperJawVisible: true,
      });
    } else if (side === GizmoViewOrientation.Bottom) {
      setViewSettings({
        ...viewSettings,
        lowerJawVisible: true,
        upperJawVisible: false,
      });
    } else {
      setViewSettings({
        ...viewSettings,
        lowerJawVisible: true,
        upperJawVisible: true,
      });
    }
  };

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Page className={styles.homePage}>
      <PatientInfoSidebar
        isOpen={isPatientInfoOpen}
        onClose={() => setIsPatientInfoOpen((prevState) => !prevState)}
        patientInfo={patientInfo}
        upperStagesCount={sceneData?.upperStagesCount}
        lowerStagesCount={sceneData?.lowerStagesCount}
      />
      <MobileMenu
        isOpen={isMobileSideBarOpen}
        onClose={() => setIsMobileSideBarState(false)}
        configProvider={configProvider}
        viewSettings={viewSettings}
        toggleViewSetting={toggleViewSetting}
        setViewSetting={setViewSetting}
        isTeethMovement={hasTeethMovement}
        isPhotos={isPhotos}
        isPatientInfo={!!patientInfo}
        isOcclusionsEnabled={sceneData && sceneData.occlusions.length > 0}
        onTeethMovementTableOpen={() => setTeethMovementState((prev) => !prev)}
        onPhotosOpen={() => setIsPatientPhotosOpen((prev) => !prev)}
        onPatientInfoOpen={() => setIsPatientInfoOpen((prev) => !prev)}
        onTreatmentPlanClick={() => {
          if (treatmentPlanUrl) {
            openInNewTab(treatmentPlanUrl);
          }
        }}
        isTreatmentPlanPdf={!!treatmentPlanUrl}
      />

      {isPhotos && (
        <PatientPhotos
          photos={photos}
          isOpen={isPatientPhotosOpen}
          onClose={handleClosePatientPhotos}
          setIsViewPortPhotoEnabled={setIsViewPortPhotoEnabled}
          isViewPortPhotoEnabled={isViewPortPhotoEnabled}
          colorScheme={companyColorScheme}
        />
      )}
      {!isCompanyColorSchemeLoading && (
        <Header
          colorScheme={companyColorScheme}
          onBrandClick={() => {
            setManufacturerState(true);
          }}
        >
          <div
            className={cn(styles.homePage_buttons, styles.homePage_onlyDesktop)}
          >
            {!!patientInfo && (
              <Button
                tooltip="Patient Info"
                active={isPatientInfoOpen}
                className={styles.homePage_headerButton}
                onClick={() => setIsPatientInfoOpen((prevState) => !prevState)}
              >
                <img
                  className={cn(
                    companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                  )}
                  src="/assets/images/patientInfo_icon.svg"
                  alt="Upper Jaw"
                />
              </Button>
            )}
            {treatmentPlanUrl && (
              <Button
                tooltip="Treatment Plan PDF"
                onClick={() => openInNewTab(treatmentPlanUrl)}
                className={styles.homePage_headerButton}
              >
                <img
                  className={cn(
                    companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                  )}
                  src="/assets/images/treatment-plan-icon.svg"
                  alt="Treatment Plan Pdf Icon"
                />
              </Button>
            )}
            {isPhotos && (
              <Button
                tooltip="Patient photos"
                active={isPatientPhotosOpen}
                className={styles.homePage_headerButton}
                onClick={patientPhotosToggle}
              >
                <img
                  className={cn(
                    companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                  )}
                  src="/assets/images/photos_icon.svg"
                  alt="Patient Photos"
                />
              </Button>
            )}
            {hasTeethMovement && (
              <Button
                tooltip="Tuning Table"
                active={isTeethMovementOpen}
                className={styles.homePage_headerButton}
                onClick={() => setTeethMovementState(!isTeethMovementOpen)}
              >
                <ToothMovementIcon
                  className={cn(
                    companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                  )}
                />
              </Button>
            )}
          </div>
          <Divider className={styles.homePage_onlyDesktop} />
          <div className={styles.homePage_buttons}>
            <Button
              tooltip="Upper jaw"
              active={viewSettings.upperJawVisible}
              className={cn(styles.homePage_headerButton)}
              onClick={() => {
                if (
                  viewSettings.upperJawVisible &&
                  !viewSettings.lowerJawVisible
                ) {
                  toggleViewSetting('upperJawVisible');
                  toggleViewSetting('lowerJawVisible');
                } else {
                  toggleViewSetting('upperJawVisible');
                }
              }}
            >
              <img
                className={cn(
                  companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                )}
                src="/assets/images/upper_jaw.svg"
                alt="Upper Jaw"
              />
            </Button>
            <Button
              tooltip="Lower jaw"
              active={viewSettings.lowerJawVisible}
              className={styles.homePage_headerButton}
              onClick={() => {
                if (
                  !viewSettings.upperJawVisible &&
                  viewSettings.lowerJawVisible
                ) {
                  toggleViewSetting('upperJawVisible');
                  toggleViewSetting('lowerJawVisible');
                } else {
                  toggleViewSetting('lowerJawVisible');
                }
              }}
            >
              <img
                src="/assets/images/lower_jaw.svg"
                className={cn(
                  companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                )}
                alt="Lower Jaw"
              />
            </Button>
            <Button
              active={isMobileSideBarOpen}
              className={cn(styles.homePage_headerButton, 'flex md:hidden')}
              onClick={() => setIsMobileSideBarState((prevState) => !prevState)}
            >
              <img
                src="/assets/images/mobileMenu_icon.svg"
                className={cn(
                  companyColorScheme?.light && 'invert-[.75] drop-shadow-lg'
                )}
                alt="Lower Jaw"
              />
            </Button>
          </div>
          <Divider className={styles.homePage_onlyDesktop} />
          <ViewSettings
            viewSettings={viewSettings}
            configProvider={configProvider}
            setViewSetting={setViewSetting}
            toggleViewSetting={toggleViewSetting}
            sceneData={sceneData}
            className={styles.homePage_onlyDesktop}
            companyColorScheme={companyColorScheme}
          />
        </Header>
      )}
      <div className={styles.homePage_content}>
        <LeftBar
          onLogoClick={() => {
            setManufacturerState(true);
          }}
        />

        {assetUrl && (
          <SceneWidget
            clientId={clientId}
            viewSettings={viewSettings}
            assetUrl={assetUrl}
            gizmoViewOrientationChanged={gizmoViewOrientationChanged}
            setSceneData={setSceneData}
            isViewPortPhotoEnabled={isViewPortPhotoEnabled}
            renderTeethMovementTable={(data) => {
              return (
                <TeethMovement
                  isOpen={isTeethMovementOpen}
                  onClose={() => setTeethMovementState(false)}
                  data={data.teethMovement}
                  currentStage={String(data.stage)}
                  upperJawVisible={viewSettings.upperJawVisible}
                  lowerJawVisible={viewSettings.lowerJawVisible}
                  toothNumbering={data.toothNumbering}
                />
              );
            }}
          />
        )}

        <Manufacturer
          isOpen={isManufacturerOpen}
          onClose={() => {
            setManufacturerState(false);
          }}
        />

        {viewSettings.occlusions && <Histogram configProvider={configProvider.getFeatureConfig<IOcclusionFeatureConfig>("occlusions")!}/>}

        <LoadingCover
          isLoading={isLoading || isCompanyColorSchemeLoading}
          message="Loading data..."
        />
        <ErrorCover
          error={
            !isLoading && !isCompanyColorSchemeLoading && !assetUrl
              ? 'No data for load. Please check URL.'
              : undefined
          }
        />
      </div>
    </Page>
  );
};

export default HomePage;
