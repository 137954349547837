import BaseManager from '../BaseManager';
import { differenceBy } from 'lodash';
import {
  AttachmentObjectDescription,
  BiterampObjectDescription,
  ElasticObjectDescription,
  GenericObjectDescription,
  GingivaObjectDescription,
  GridObjectDescription,
  IPRObjectDescription,
  ObjectDescription,
  ObjectTypes,
  PonticObjectDescription,
  ToothRootOverlayObjectDescription,
  ToothObjectDescription,
  ToothOverlayObjectDescription,
  ToothRootObjectDescription
} from '@data/models';
import { Dictionary } from '@types';
import GenericObject from './GenericObject';
import GingivaObject from './GingivaObject';
import ToothObject from './ToothObject';
import ToothOverlayObject from './ToothOverlayObject';
import ToothRootObject from './ToothRootObject';
import AttachmentObject from './AttachmentObject';
import PonticObject from './PonticObject';
import IPRObject from './IPRObject';
import BaseObject from './BaseObject';
import BiterampObject from './BiterampObject';
import ElasticObject from './ElasticObject';
import GridObject from './GridObject';
import { GeometryProvider, MaterialsProvider } from '@data/providers';
import { WebGLScene } from '../WebGLScene';
import ToothRootOverlayObject from '@view/widgets/Scene/WebGLScene/objects/ToothRootOverlayObject';


export class ObjectsManager extends BaseManager<ObjectDescription> {
  private readonly _objects: Dictionary<BaseObject>;
  private readonly _types: Dictionary<any>;

  private readonly _materialsProvider: MaterialsProvider;
  private readonly _geometryProvider: GeometryProvider;

  constructor (scene: WebGLScene, allObjects: Dictionary<ObjectDescription>) {
    super(scene);

    this._objects = {};
    this._materialsProvider = new MaterialsProvider(scene.getRenderer().getEnvMap(), allObjects, scene.getClientId());
    this._geometryProvider = new GeometryProvider(allObjects);

    this._types = {
      [ObjectTypes.Generic]: async (description: ObjectDescription) => {
        return await GenericObject.create(
          description as GenericObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.Tooth]: async (description: ObjectDescription) => {
        return await ToothObject.create(
          description as ToothObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.ToothOverlay]: async (description: ObjectDescription) => {
        return await ToothOverlayObject.create(
          description as ToothOverlayObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.ToothRootOverlay]: async (description: ObjectDescription) => {
        return await ToothRootOverlayObject.create(
          description as ToothRootOverlayObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.ToothRoot]: async (description: ObjectDescription) => {
        return await ToothRootObject.create(
          description as ToothRootObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.Gingiva]: async (description: ObjectDescription) => {
        return await GingivaObject.create(
          description as GingivaObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.Attachment]: async (description: ObjectDescription) => {
        return await AttachmentObject.create(
          description as AttachmentObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.Elastic]: async (description: ObjectDescription) => {
        return await ElasticObject.create(
          description as ElasticObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.Biteramp]: async (description: ObjectDescription) => {
        return await BiterampObject.create(
          description as BiterampObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.Pontic]: async (description: ObjectDescription) => {
        return await PonticObject.create(
          description as PonticObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      },
      [ObjectTypes.IPR]: async (description: ObjectDescription) => {
        return await IPRObject.create(
          description as IPRObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider,
          this._objects
        );
      },
      [ObjectTypes.Grid]: async (description: ObjectDescription) => {
        return await GridObject.create(
          description as GridObjectDescription,
          this._scene,
          this._geometryProvider,
          this._materialsProvider
        );
      }
    };
  }

  async initialize() {
    await this._geometryProvider.init();
    await this._materialsProvider.init();
  }

  async createItem(description: ObjectDescription) {
    this._objects[description.id] = await this._types[description.type](description, this._objects);
  }

  async removeItem(id: string) {
    if (!this._objects[id]) {
      return;
    }

    this._objects[id].removeFromScene(this._scene.getScene());
    delete this._objects[id];
  }

  async updateItem(description: ObjectDescription) {
    if (!this._objects[description.id]) {
      return;
    }

    await this._objects[description.id].update(description, this._objects);
  }

  getChanges(descriptions: ObjectDescription[]) {
    const objects = Object.keys(this._objects).map((id: string) => ({ id }));

    const removed = differenceBy(objects, descriptions, 'id');
    const added = differenceBy(descriptions, objects, 'id');
    const iprAdded = added.filter((description) => description.type === ObjectTypes.IPR);
    const elasticAdded = added.filter((description) => description.type === ObjectTypes.Elastic);
    const commonAdded = added.filter((description) =>
      description.type !== ObjectTypes.IPR && description.type !== ObjectTypes.Elastic
    );
    const updated = differenceBy(descriptions, [...removed, ...added], 'id');
    const iprUpdated = updated.filter((description) => description.type === ObjectTypes.IPR);
    const elasticUpdated = updated.filter((description) => description.type === ObjectTypes.Elastic);
    const commonUpdated = updated.filter((description) =>
      description.type !== ObjectTypes.IPR && description.type !== ObjectTypes.Elastic
    );

    return {
      removed,
      commonAdded,
      iprAdded,
      elasticAdded,
      updated,
      iprUpdated,
      elasticUpdated,
      commonUpdated
    };
  }

  async update(descriptions: ObjectDescription[]): Promise<void> {
    const changes = this.getChanges(descriptions);

    await Promise.all([
      ...changes.commonAdded.map((description) => this.createItem(description)),
      ...changes.removed.map((description) => this.removeItem(description.id)),
      ...changes.commonUpdated.map((description) => this.updateItem(description))
    ]);

    await Promise.all(changes.iprAdded.map((description) => this.createItem(description)));
    await Promise.all(changes.iprUpdated.map((description) => this.updateItem(description)));

    await Promise.all(changes.elasticAdded.map((description) => this.createItem(description)));
    await Promise.all(changes.elasticUpdated.map((description) => this.updateItem(description)));
  }

  async updateByAnimation (): Promise<void> {
    const iprs = Object
      .values(this._objects)
      .filter((obj) => obj.type === ObjectTypes.IPR && obj.visible);

    await Promise.all(iprs.map((ipr) => ipr.updateByAnimation(this._objects)));

    const grids = Object
      .values(this._objects)
      .filter((obj) => obj.type === ObjectTypes.Grid);

    const cameraPosition = this._scene.getCamera().getCamera().position.clone();

    grids.forEach((gridObject) => {
      gridObject.mesh.position.x = cameraPosition.x;
      gridObject.mesh.position.y = cameraPosition.y;
    });
  }
}
