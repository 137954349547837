import React from 'react';
import Logo from 'assets/images/softsmile_logo_rotated.svg?react';
import styles from './styles.scss';

interface ILeftBarProps {
  onLogoClick: () => void;
}

const LeftBar = ({ onLogoClick }: ILeftBarProps) => {
  return (
    <div className={styles.leftBar}>
      <div className={styles.leftBar_content}></div>
      <div className={styles.leftBar_logo} onClick={onLogoClick}>
        <Logo />
      </div>
    </div>
  );
};

export default LeftBar;
