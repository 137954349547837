import { useQuery } from '@tanstack/react-query';
import getCompany from '../sqls/get-company.sql';
import { getSubdomain } from '@utils';

export default function useGetSBSCompanyQuery({
  companyId,
  options,
}: {
  companyId: string | undefined;
  options: any;
}) {
  return useQuery({
    queryKey: [
      '__supabase',
      'companies',
      { subdomain: getSubdomain(), companyId },
    ],
    queryFn: () => getCompany({ subdomain: getSubdomain(), companyId }),
    retryOnMount: false, // Otherwise we get infinite requests on error
    staleTime: Infinity,
    ...options,
  });
}
