import { PerspectiveCamera as ThreePerspectiveCamera, Scene, Vector3 } from 'three';
import { PerspectiveCameraDescription } from '@data/models';
import { getAspectRatio } from '../utils';
import { cloneDeep, isEqual } from 'lodash';

export class PerspectiveCamera {
  private readonly _name: string;
  private _description: PerspectiveCameraDescription;
  private readonly _camera: ThreePerspectiveCamera;
  private readonly _scene: Scene;

  constructor (scene: Scene, description: PerspectiveCameraDescription, aspect: number) {
    this._scene = scene;
    this._description = cloneDeep(description);
    this._name = description.name;
    this._camera = new ThreePerspectiveCamera(description.fov, aspect, description.near, description.far);

    this._camera.zoom = description.zoom;
    this._camera.position.set(description.position.x, description.position.y, description.position.z);
    this._camera.lookAt(new Vector3(description.target.x, description.target.y, description.target.z));

    this._camera.updateProjectionMatrix();
  }

  update (description: PerspectiveCameraDescription) {
    if (isEqual(this._description, description)) {
      return;
    }

    // this._camera.zoom = description.zoom;
    // this._camera.position.set(description.position.x, description.position.y, description.position.z);
    // this._camera.up = new Vector3(description.up.x, description.up.y, description.up.z);
    // this._camera.lookAt(new Vector3(description.target.x, description.target.y, description.target.z));
    //
    // this._camera.updateProjectionMatrix();

    this._description = cloneDeep(description);
  }

  get camera () {
    return this._camera;
  }

  getCamera () {
    return this._camera;
  }

  resize (width: number, height: number) {
    this._camera.aspect = getAspectRatio(width, height);
    this._camera.updateProjectionMatrix();
  }

  getDescription () {
    return this._description;
  }

  remove () {
    this._scene.remove(this._camera);
  }
}
