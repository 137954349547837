import React from 'react';
import { useCompanyColorScheme } from '../../hooks/useCompanyColorScheme';

export type PatientInfo = {
  firstName: string;
  lastName: string;
  caseAliasId: string;
};

export enum PhotosFileKindEnum {
  FrontPhoto = 'FrontPhoto',
  SmilePhoto = 'SmilePhoto',
  LeftSidePhoto = 'LeftSidePhoto',
  RightSidePhoto = 'RightSidePhoto',
  SidePhoto = 'SidePhoto',
  UpperJawPhoto = 'UpperJawPhoto',
  LowerJawPhoto = 'LowerJawPhoto',
  FrontSidePhoto = 'FrontSidePhoto',
  PanoramicXRay = 'PanoramicXRay',
  LateralXRay = 'LateralXRay',
  FrontXRay = 'FrontXRay',
}

export type PatientPhotoType = {
  fileKind: PhotosFileKindEnum;
  url: string;
};

export type PatientPhotosProps = {
  isOpen: boolean;
  onClose: () => void;
  photos: PatientPhotoType[];
  setIsViewPortPhotoEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isViewPortPhotoEnabled: boolean;
  colorScheme?: ReturnType<typeof useCompanyColorScheme>[0] | undefined;
};