import { RawFile } from '../types';
import {CanvasTexture, Color} from 'three';
import {getNameByFile} from '@data/providers/Viewer/utils';
import {IOcclusionFeatureConfig} from '@data/models';

const COLORING_PALETTE = (config: IOcclusionFeatureConfig) => {
  return config.coloringPalette.split(",");
}

const COLOR_COUNT = (config: IOcclusionFeatureConfig) => {
  return COLORING_PALETTE(config).length + 2;
}

const TEX_COORD_FOR_MAX_DISTANCE = (config: IOcclusionFeatureConfig) => {
  return 1.0 - 1 / COLOR_COUNT(config);
}
const TEX_COORD_FOR_MIN_DISTANCE = (config: IOcclusionFeatureConfig) => {
  return 1 / COLOR_COUNT(config);
}

export const getOcclusionDistancesFromFilename = async (filename: string, occlusionFiles: RawFile[], config?: IOcclusionFeatureConfig) => {
  const file = occlusionFiles.find(o => getNameByFile(o.filename) === filename);
  return getOcclusionDistances(file, config!);
}

export const getOcclusionDistances = async (file: RawFile | undefined, config: IOcclusionFeatureConfig) => {
  const values = [];
  if (file) {
    const arrayBuffer = await new Blob([file?.data as ArrayBuffer]).arrayBuffer();
    const dataView = new DataView(arrayBuffer);

    const valuesLength = dataView.getInt32(0, true);
    let offset = 4;

    for (let j = 0; j < valuesLength; j++) {
      const value = dataView.getFloat32(offset, true);
      offset += 4;
      values.push(value);
    }
  }
  return distancesToTexCoords(values, config);
}

const distancesToTexCoords = (distances: number[], config: IOcclusionFeatureConfig) =>
{
  const distanceTexCoords: number[] = [];

  for (let i = 0; i < distances.length; ++i) {
    distanceTexCoords[i] = distanceToTexCoord(distances[i], config);
  }
  return distanceTexCoords;
}

export const getOcclusionsColorMapTexture = (config: IOcclusionFeatureConfig) => {
  const textureWidth = 512;
  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  const gradient = ctx!.createLinearGradient(0, 0, canvas.width, canvas.height);

  for (let x = 0; x < textureWidth; ++x) {
    const texCoord = x / (textureWidth - 1);
    const distance = +texCoordToDistance(texCoord, config).toFixed(2);
    const color = getColorByDistance(distance, config);
    gradient.addColorStop(texCoord, `#${color.getHexString()}`);
  }
  ctx!.fillStyle = gradient;
  ctx!.fillRect(0, 0, canvas.width, canvas.height);
  return new CanvasTexture(canvas);
}

export const getColorTextureHistogram = (width: number, height: number, config: IOcclusionFeatureConfig) => {
  const textureWidth = 512;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const gradient = ctx!.createLinearGradient(0, 0, canvas.width, canvas.height);

  for (let x = 0; x < textureWidth; ++x) {
    const texCoord = x / (textureWidth - 1);
    const distance = +texCoordToDistance(texCoord, config);
    const color = getColorByDistance(distance, config);
    gradient.addColorStop(texCoord, `#${color.getHexString()}`);
  }
  ctx!.fillStyle = gradient;
  ctx!.fillRect(0, 0, canvas.width, canvas.height);
  return canvas;
}

export const getDepths = (configProvider: IOcclusionFeatureConfig) => {
  const depthStepInMm = (configProvider.coloringMaxThresholdInMm
      - configProvider.coloringMinThresholdInMm)
    / COLORING_PALETTE(configProvider).length;

  const depthsCount = COLOR_COUNT(configProvider) - 1;
  const depths = [];
  for (let i = 0 ; i < depthsCount; i++)
  {
    depths.push(
      {
        depth: i * depthStepInMm,
        position: (depthsCount - i) / COLOR_COUNT(configProvider)
      });
  }
  return depths;
};

const texCoordToDistance = (texCoord: number, config: IOcclusionFeatureConfig) =>
{
  const normalizedDistance = lerpInvert(TEX_COORD_FOR_MIN_DISTANCE(config), TEX_COORD_FOR_MAX_DISTANCE(config), texCoord);
  return lerpUnclamped(config.coloringMinThresholdInMm, config.coloringMaxThresholdInMm, normalizedDistance);
}

const getColorByDistance = (distanceMm: number, config: IOcclusionFeatureConfig) =>
{
  const relativeDistanceMm = distanceMm - config.coloringMinThresholdInMm;
  const _binWidthMm = (config.coloringMaxThresholdInMm - config.coloringMinThresholdInMm) / COLORING_PALETTE(config).length;
  const normalizedDistance = (relativeDistanceMm / _binWidthMm) - 0.5;

  const binIndex = Math.floor(normalizedDistance);
  const normalizedBinDistance = normalizedDistance - binIndex;

  const currentColor: Color = getColorByBinIndex(
    binIndex,
    config.coloringNegativeOutOfRangeColor,
    config.coloringPositiveOutOfRangeColor,
    COLORING_PALETTE(config)
  );
  const nextColor: Color = getColorByBinIndex(
    binIndex + 1,
    config.coloringNegativeOutOfRangeColor,
    config.coloringPositiveOutOfRangeColor,
    COLORING_PALETTE(config)
  );
  const blendWeight = getBlendWeight(normalizedBinDistance, config.coloringBlurDiameterInMm/_binWidthMm);
  return new Color().lerpColors(currentColor, nextColor, blendWeight);
}

const distanceToTexCoord = (distance: number, config: IOcclusionFeatureConfig) =>
{
  const normalizedDistance = lerpInvert(config.coloringMinThresholdInMm, config.coloringMaxThresholdInMm, distance);
  return clamp01(lerpUnclamped(TEX_COORD_FOR_MIN_DISTANCE(config), TEX_COORD_FOR_MAX_DISTANCE(config), normalizedDistance));
}

const clamp01 = (value: number) =>
{
  if (value < 0.0)
  return 0.0;
  return value > 1.0 ? 1.0 : value;
}
const lerpUnclamped = (a: number, b: number, t: number) => a + (b - a) * t;

const lerpInvert = (boundary0: number, boundary1: number, value: number) => {
  return (value - boundary0) / (boundary1 - boundary0);
}

const getBlendWeight = (normalizedBinDistance: number, coloringBlurDiameterInMm: number) => {
  return clamp(
    (normalizedBinDistance - 0.5) / coloringBlurDiameterInMm + 0.5,
    0, 1);
}

const clamp = (f: number, low: number, high: number) => {
  return (f < low) ? low : (f > high) ? high : f;
}

const getColorByBinIndex = (binIndex: number, coloringNegativeOutOfRangeColor: string,
  coloringPositiveOutOfRangeColor: string, coloringPalette: string[]) => {
  if (binIndex < 0) return new Color(coloringNegativeOutOfRangeColor);
  if (binIndex >= coloringPalette.length) return new Color(coloringPositiveOutOfRangeColor);
  return new Color(coloringPalette[binIndex]);
}