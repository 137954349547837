import React, { useMemo } from 'react';
import styles from './styles.scss';
import TableRow from './TableRow';
import {IToothPositionMovement, TeethMovementStage} from '@data/models';
import { IToothlikeNameProvider } from '@data/providers/DentalNotationProvider/IToothlikeNameProvider';
import { ToothlikeNameProviderFactory } from '@data/providers/DentalNotationProvider/ToothlikeNameProviderFactory';
import { DentalNotationType } from '@data/providers/DentalNotationProvider/Models';

type TeethMovementTableProps = {
  type: 'upperJaw' | 'lowerJaw';
  isJawVisible: boolean;
  teeth: string[];
  currentStageData: TeethMovementStage;
  currentStage: string;
  toothNumbering: DentalNotationType;
};

const TeethMovementTable = ({
  isJawVisible,
  teeth,
  currentStage,
  currentStageData,
  toothNumbering,
  type,
}: TeethMovementTableProps) => {
  const toothlikeNameProvider: IToothlikeNameProvider = useMemo(
    () => ToothlikeNameProviderFactory.create(toothNumbering),
    []
  );

  const isUpperJaw = type === 'upperJaw';

  return (
    <div className={styles.teethMovement_table}>
      {isJawVisible &&
        teeth.map((toothNumber: string) => {
          const toothData = currentStageData[toothNumber] ?? [undefined];
          return (
            toothData.map((tooth: IToothPositionMovement) => {
              const toothPosition = tooth?.toothPosition;
              const rowKey = `${currentStage}-${toothNumber}` + (toothPosition?.relativePosition ? `-${toothPosition.toothMapPosition}-${toothPosition.relativePosition}` : '');
              return <TableRow
                  key={rowKey}
                  data={tooth?.toothMovement}
                  toothNumber={tooth?.toothLabel ?? toothlikeNameProvider.getName(+toothNumber)}
                  isFirst={toothNumber === (isUpperJaw ? '18' : '48')}
                  isLast={toothNumber === (isUpperJaw ? '28' : '38')}
                />
            })
          );
        })}
    </div>
  );
};

export default TeethMovementTable;
