class BaseLoader {
  public loader: any;
  public manager: any;
  private _lastError: string | undefined;

  constructor(manager: any) {
    this.loader = null;
    this.manager = manager;
  }

  async load (url: string) {
    if (this.loader === null) {
      return null;
    }

    const getLoadPromise = () =>
      new Promise((resolve, reject) => {
        const onComplete = (object: any) => {
          this._lastError = undefined;
          resolve(object);
        };

        const onProgress = () => {
          // console.info(`Load ${url}, progress: ${round((event.loaded / event.total) * 100, 0)}%.`);
        };

        const onError = (error: any) => {
          this._lastError = error;
          reject(error);
        };

        this.loader.load(url, onComplete, onProgress, onError);
      });

    return getLoadPromise();
  }
}

export default BaseLoader;
