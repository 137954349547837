export class DentalNotationHelper {
  public static readonly MIN_NUMBER_IN_QUADRANT = 1;
  public static readonly MAX_NUMBER_IN_QUADRANT = 8;
  public static readonly MIN_QUADRANT = 1;
  public static readonly MAX_QUADRANT = 4;

  constructor() {}

  private static getQuadrantRaw(toothNumber: number): number {
    return Math.floor(toothNumber / 10);
  }

  private static getNumberInQuadrantRaw(toothNumber: number): number {
    return toothNumber % 10;
  }

  static getNumberInQuadrant(toothNumber: number) {
    const toothNumberInQuadrant = this.getNumberInQuadrantRaw(toothNumber);
    this.validateToothNumberInQuadrant(toothNumberInQuadrant);
    return toothNumberInQuadrant;
  }

  static getQuadrant(toothNumber: number): number {
    const quadrant = this.getQuadrantRaw(toothNumber);
    this.validateToothQuadrant(quadrant);
    return quadrant;
  }

  static validateToothQuadrant(toothQuadrant: number) {
    if (!this.isValidToothQuadrant(toothQuadrant)) throw new Error(`Jaw quadrant should be between 1 and 4. ${toothQuadrant} is given`);
  }

  private static validateToothNumberInQuadrant(toothNumberInQuadrant: number) {
    if (!this.isValidToothNumberInQuadrant(toothNumberInQuadrant)) throw new Error(`Tooth number in quadrant should be between 1 and 8. ${toothNumberInQuadrant} is given`);
  }

  static isValidToothQuadrant(toothQuadrant: number) {
    return this.MIN_QUADRANT <= toothQuadrant && toothQuadrant <= this.MAX_QUADRANT;
  }

  static isValidToothNumberInQuadrant(toothNumberInQuadrant: number) {
    return this.MIN_NUMBER_IN_QUADRANT <= toothNumberInQuadrant && toothNumberInQuadrant <= this.MAX_NUMBER_IN_QUADRANT;
  }

  static isValidToothNumber(toothNumber: number) {
    return this.isValidToothQuadrant(this.getQuadrantRaw(toothNumber))
      && this.isValidToothNumberInQuadrant(this.getNumberInQuadrantRaw(toothNumber));
  }
}