import { EXRLoader as ThreeEXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import BaseLoader from '../BaseLoader';

export class EXRLoader extends BaseLoader {
  constructor(manager?: any) {
    super(manager);

    this.loader = new ThreeEXRLoader(manager);
  }
}
