import {IToothlikeNameProvider} from '@data/providers/DentalNotationProvider/IToothlikeNameProvider';
import {DentalNotationHelper} from '@data/providers/DentalNotationProvider/DentalNotationHelper';
import {PrimaryTeethNotationHelper} from '@data/providers/DentalNotationProvider/PrimaryTeethNotationHelper';

export class ToothlikeUniversalNameProvider implements IToothlikeNameProvider {
  getName(toothNumber: number): string {
    return this.getToothNumberPalmer(toothNumber);
  }
  private getToothNumberPalmer(toothNumber: number): string {
    if (DentalNotationHelper.isValidToothNumber(toothNumber))
      return ToothlikeUniversalNameProvider.PermanentFdiToUniversal.get(toothNumber)!.toString();
    else if (PrimaryTeethNotationHelper.isValidPrimaryToothNumber(toothNumber))
      return ToothlikeUniversalNameProvider.PrimaryFdiToUniversal.get(toothNumber)!.toString();
    else
      throw new Error(`${toothNumber} is not a valid tooth number!`);
  }
  
  private static readonly PermanentFdiToUniversal = new Map<number, number>(
    [
      [18,1], [17,2], [16,3], [15,4], [14,5], [13,6], [12,7], [11,8],
      [21,9], [22,10], [23,11], [24,12], [25,13], [26,14], [27,15], [28,16],
      [38,17], [37,18], [36,19], [35,20], [34,21], [33,22], [32,23], [31,24],
      [41,25], [42,26], [43,27], [44,28], [45,29], [46,30], [47,31], [48,32]
    ]);

  private static readonly PrimaryFdiToUniversal = new Map<number, string>(
    [
      [55,'A'], [54,'B'], [53,'C'], [52,'D'], [51,'E'],
      [61,'F'], [62,'G'], [63,'H'], [64,'I'], [65,'J'],
      [75,'K'], [74,'L'], [73,'M'], [72,'N'], [71,'O'],
      [81,'P'], [82,'Q'], [83,'R'], [84,'S'], [85,'T']
    ]);
}