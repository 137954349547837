import { Color } from 'three';

class ColorConverter {
  static hexStringToNumber(value: string) {
    return Number.parseInt(value.trim().replace('0x', ''), 16);
  }

  static numberToHexString(value: number) {
    return `0x${value.toString(16)}`;
  }

  static hexStringToThreeColor(value: string) {
    return new Color(ColorConverter.hexStringToNumber(value));
  }

  static numberToThreeColor(value: number) {
    return new Color(value);
  }
}

export default ColorConverter;
