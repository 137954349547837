import { PointLight as ThreePointLight } from 'three';
import BaseLight from './BaseLight';
import { ColorConverter } from '@utils';
import { LightTypes, PointLightDescription } from '@data/models';

export class PointLight extends BaseLight {
  constructor(description: PointLightDescription) {
    super(description.id, description.name, LightTypes.Point);

    this._light = new ThreePointLight(
      ColorConverter.numberToThreeColor(description.color),
      description.intensity,
      description.distance,
      description.decay
    );

    this._light.position.set(description.position.x, description.position.y, description.position.z);
  }
}
