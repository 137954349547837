import {
  AttachmentObjectDescription,
  BiterampObjectDescription,
  ElasticObjectDescription,
  GingivaObjectDescription,
  IIPR,
  IPRObjectDescription,
  IStage,
  ITreatmentPlan,
  JawType,
  ObjectDescription,
  ObjectTypes,
  PonticObjectDescription,
  ToothObjectDescription
} from '@data/models';
import { getNameByFile } from './getNameByFile';
import { Dictionary } from '@types';
import { RawFile } from '../types';


const getJawTypeByGingivaName = (name: string): JawType | undefined => {
  // @ts-ignore
  const [_, type] = name.match(/-[lu]-/);
  switch (type) {
    case 'l':
      return JawType.Lower;
    case 'u':
      return JawType.Upper;
  }
  return undefined;
};

const getJawTypeByToothName = (name: string): JawType | undefined => {
  // @ts-ignore
  const [_, quarter] = name.match(/-([0-9])([0-9])/);
  switch (quarter) {
    case '1':
    case '2':
      return JawType.Upper;
    case '3':
    case '4':
      return JawType.Lower;
  }

  return undefined;
};

export const getGingivaObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): GingivaObjectDescription => {
  const gingivaName = getNameByFile(file);
  const jawType = getJawTypeByGingivaName(gingivaName);

  return {
    id: gingivaName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Gingiva,
    jaw: jawType || JawType.Upper,
    visible: false,
    transform
  };
};

export const getToothObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): ToothObjectDescription => {
  const toothName = getNameByFile(file);
  const jawType = getJawTypeByToothName(toothName);

  return {
    id: toothName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Tooth,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper
  };
};

export const getAttachmentObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): AttachmentObjectDescription => {
  const attachmentName = getNameByFile(file);
  const jawType = getJawTypeByToothName(attachmentName);

  return {
    id: attachmentName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Attachment,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper
  };
};

export const getElasticObject = (
  id: string,
  points: number[][],
  number: number,
  elasticType: 'Button' | 'Hook',
  jaw?: JawType
): ElasticObjectDescription => {

  return {
    id,
    points,
    type: ObjectTypes.Elastic,
    number,
    visible: false,
    transform: null,
    jaw: jaw || JawType.Upper,
    elasticType
  };
};

export const getBiterampObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): BiterampObjectDescription => {
  const name = getNameByFile(file);
  const jawType = getJawTypeByToothName(name);

  return {
    id: name,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Biteramp,
    visible: false,
    transform,
    jaw: jawType || JawType.Upper
  };
};

export const getPonticObject = (
  file: string,
  transform: number[] | null,
  jaw?: JawType,
  data?: any
): PonticObjectDescription => {
  const ponticName = getNameByFile(file);

  return {
    id: ponticName,
    url: URL.createObjectURL(new Blob([data])),
    type: ObjectTypes.Pontic,
    visible: false,
    transform,
    jaw: JawType.Upper
  };
};

export const getIPRObject = (
  id: string,
  label: string,
  jaw?: JawType
): IPRObjectDescription => {
  return {
    id,
    type: ObjectTypes.IPR,
    visible: false,
    label,
    jaw: jaw || JawType.Upper,
    transform: null,
    firstToothName: '',
    secondToothName: '',
  };
};

export const getObjectByFile = (file: string, data: any): ObjectDescription => {
  const name = getNameByFile(file);

  if (name.includes('gingiva')) {
    return getGingivaObject(file, null, undefined, data);
  }

  if (name.includes('attach')) {
    return getAttachmentObject(file, null, undefined, data);
  }

  if (name.includes('biteramp')) {
    return getBiterampObject(file, null, undefined, data);
  }

  if (name.includes('pontic')) {
    return getPonticObject(file, null, undefined, data);
  }

  return getToothObject(file, null, undefined, data);
};

export const getAllObjects = (
  plan: ITreatmentPlan,
  files: RawFile[],
  stages: IStage[]
): Dictionary<ObjectDescription> => {
  const baseObjects: Dictionary<ObjectDescription> = plan.files
    .reduce((result: Dictionary<ObjectDescription>, file: string) => {
      const filename = getNameByFile(file.replace('.stl', '.drc'));
      const [foundFile] = files.filter((item) => getNameByFile(item.filename) === filename);
      const obj = getObjectByFile(file, foundFile.data);
      result[obj.id] = obj;
      obj.visible = false;
      return result;
    }, {});

  const elasticObjects = stages.flatMap((stage: IStage) => {
    const createElasticObject = (elastic: any, jawType: JawType) => {
      let points;
      if (elastic.points) {
        // This is a previous version of exporter with flipped X axis.
        // By this, we need to flip X axis (first number in array[3]).
        points = elastic.points.map((point: number[]) => [-point[0], point[1], point[2]]);
      } else {
        // This is a new version of exporter. We don't need to do anything with coords.
        points = elastic.coords;
      }
      return getElasticObject(
        `${elastic.id}--${stage.id}`,
        points,
        elastic.number,
        elastic.type,
        jawType
      );
    };

    const result: ObjectDescription[] = [];

    if (stage.upper && stage.upper.elastics) {
      result.push(...stage.upper.elastics.map((elastic) => createElasticObject(elastic, JawType.Upper)));
    }

    if (stage.lower && stage.lower.elastics) {
      result.push(...stage.lower.elastics.map((elastic) => createElasticObject(elastic, JawType.Lower)));
    }

    return result;
  });

  const iprObjects = stages.flatMap((stage: IStage) => {
    const createIprObject = (ipr: IIPR, index: number, jawType: JawType) => {
      return getIPRObject(
        `ipr-${jawType === JawType.Upper ? 'u' : 'l'}-${stage.id}-${index+1}`,
        String(ipr.spacing),
        jawType
      );
    };

    const result: ObjectDescription[] = [];

    if (stage.upper && stage.upper.iprs) {
      result.push(...stage.upper.iprs.map((ipr, index) => createIprObject(ipr, index, JawType.Upper)));
    }

    if (stage.lower && stage.lower.iprs) {
      result.push(...stage.lower.iprs.map((ipr, index) => createIprObject(ipr, index, JawType.Lower)));
    }

    return result;
  });

  return {
    ...baseObjects,
    ...iprObjects.reduce((res: Dictionary<ObjectDescription>, desc) => {
      res[desc.id] = desc;
      return res;
    }, {}),
    ...elasticObjects.reduce((res: Dictionary<ObjectDescription>, desc) => {
      res[desc.id] = desc;
      return res;
    }, {})
  };
};
