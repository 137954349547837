import React from 'react';
import { Point } from '@data/models';
import classNames from 'classnames';
import styles from './styles.scss';

interface ITextLabelProps {
  className?: string;
  children: string | number;
  position: Point;
  onClick?: (e: React.MouseEvent) => void;
}

class TextLabel extends React.Component<ITextLabelProps, any> {
  private readonly _ref: React.RefObject<SVGTextElement>;

  constructor(props: ITextLabelProps) {
    super(props);

    this._ref = React.createRef<SVGTextElement>();
  }

  componentDidMount() {
    const { position } = this.props;

    if (this._ref.current) {
      const { width, height } = this._ref.current.getBBox();

      this._ref.current.setAttribute('visibility', 'visible');
      this._ref.current.setAttribute('x', String(position.x - width / 2));
      this._ref.current.setAttribute('y', String(position.y + height / 2));
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ITextLabelProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    const { position } = this.props;

    if (this._ref.current) {
      const { width, height } = this._ref.current.getBBox();

      this._ref.current.setAttribute('visibility', 'visible');
      this._ref.current.setAttribute('x', String(position.x - width / 2));
      this._ref.current.setAttribute('y', String(position.y + height / 2));
    }
  }

  render() {
    const { className, children, onClick } = this.props;

    return (
      <text
        className={classNames(
          styles.knob_textLabel,
          { [styles.__hoverable]: onClick },
          className
        )}
        ref={this._ref}
        visibility="hidden"
        onClick={onClick}
      >
        {children}
      </text>
    );
  }
}

export default TextLabel;
