import { OrthographicCamera } from './OrthographicCamera';
import { PerspectiveCamera } from './PerspectiveCamera';

export type Camera =
  | PerspectiveCamera
  | OrthographicCamera;

export {
  OrthographicCamera,
  PerspectiveCamera
};
