import React from 'react';
import styles from './styles.scss';

type MenuButtonProps = {
  title: string;
  icon: React.ReactNode;
  onClick?: () => void;
};

const MenuButton = ({ title, icon, onClick }: MenuButtonProps) => {
  return (
    <button onClick={onClick} className={styles.menuButtons}>
      <div className="w-[20px] flex justify-center items-center">{icon}</div>
      <div className={styles.menuButtons_title}>{title}</div>
    </button>
  );
};

export default MenuButton;
