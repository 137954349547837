import React from 'react';
import { GridOption } from '../index';
import styles from './styles.scss';

type GridPanelProps = {
  onClose: () => void;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
  gridSize: number;
  gridState: boolean;
  gridOptions: GridOption[];
  onGridPanelClick: (gridSize: number | null) => void;
};

const GridPanel = ({
  onGridPanelClick,
  onClose,
  gridSize,
  gridState,
  gridOptions,
  onMouseLeave,
  onMouseEnter,
}: GridPanelProps) => {
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={styles.gridPanel}
    >
      {gridOptions.map((option) => {
        return (
          <div
            key={option.label}
            onClick={() => {
              onGridPanelClick(option.value);
              onClose();
            }}
            className={styles.gridPanel_option}
          >
            {option.label}
            {option.value === gridSize && gridState && (
              <img src="/assets/images/check_icon.svg" alt="Check icon" />
            )}
            {!gridState && option.value === null && (
              <img src="/assets/images/check_icon.svg" alt="Check icon" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GridPanel;
