import { Scene } from 'three';
import { LightTypes } from '@data/models';


class BaseLight {
  private readonly _id: string;
  private readonly _name: string;
  private readonly _type: LightTypes;
  protected _light: any;

  constructor (id: string, name: string, type: LightTypes) {
    this._id = id;
    this._name = name;
    this._type = type;

    this._light = null;
  }

  get instance () {
    return this._light;
  }

  get uuid () {
    if (this._light) {
      return this._light.uuid;
    }

    return null;
  }

  get name () {
    return this._name;
  }

  get id () {
    return this._id;
  }

  get type (): LightTypes {
    return this._type;
  }

  addToScene (scene: Scene) {
    if (this._light) {
      scene.add(this._light);
    }
  }

  removeFromScene (scene: Scene) {
    if (this._light) {
      scene.remove(this._light);
    }
  }
}

export default BaseLight;
