import React, { useEffect, useRef } from 'react';
import styles from './styles.scss';
import { Point } from '@data/models';
import TextLabel from '@view/components/Knob/TextLabel';
import { GeometryUtil } from '@utils';
import classNames from 'classnames';

/*
 * Just small trick for simplify overcorrection displaying.
 * We need to display overcorrection since MIN stage with overcorrection. For this I used Math.min() function,
 * and because XXStageOvercorrection variables have type 'number | null', I can use expression
 * 'XXStageOvercorrection || MAX_STAGES_COUNT' instead of branching.
 */
const MAX_STAGES_COUNT = 10000;

interface ICursorProps {
  size: number;
  center: Point;
  radius: number;
  stagesAll: number;
  stageCurrent: number;
  lowerStageOvercorrection: number | null;
  upperStageOvercorrection: number | null;
}

const Cursor = ({
  size,
  center,
  radius,
  stagesAll,
  stageCurrent,
  lowerStageOvercorrection,
  upperStageOvercorrection,
}: ICursorProps) => {
  const ref = useRef<SVGGElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute(
        'transform',
        `translate(${position.x}, ${position.y})`
      );
    }
  }, [stageCurrent]);

  const startDeg = -225;
  const fullDeg = 270;
  let filledDeg = 0;
  if (stagesAll > 1) {
    filledDeg = (stageCurrent / (stagesAll - 1)) * fullDeg;
  }
  const paddingDeg = 0;
  const position = GeometryUtil.toPoint(
    { radius, phi: startDeg + filledDeg + paddingDeg },
    center
  );
  const rotationDeg = -225;
  const cursorR = size === 200 ? 7 : 10;
  const pointA = GeometryUtil.toPoint(
    { radius: cursorR, phi: -225 },
    { x: 0, y: 0 }
  );
  const pointB = GeometryUtil.toPoint(
    { radius: cursorR, phi: 45 },
    { x: 0, y: 0 }
  );
  const pointC = GeometryUtil.toPoint(
    { radius: cursorR + cursorR * 0.4, phi: 90 },
    { x: 0, y: 0 }
  );
  const overcorrectionStage = Math.min(
    lowerStageOvercorrection || MAX_STAGES_COUNT,
    upperStageOvercorrection || MAX_STAGES_COUNT
  );
  const ploygonPoints = `${pointA.x},${pointA.y} ${pointB.x},${pointB.y} ${pointC.x},${pointC.y}`;

  return (
    <g ref={ref}>
      <g transform={`rotate(${90 + rotationDeg + filledDeg + paddingDeg})`}>
        <circle
          className={
            stageCurrent >= overcorrectionStage
              ? styles.knob_cursor_overcorrection
              : styles.knob_cursor_marker
          }
          cx={0}
          cy={0}
          r={cursorR}
          fill="black"
        />
        <polygon
          className={
            stageCurrent >= overcorrectionStage
              ? styles.knob_cursor_overcorrection
              : styles.knob_cursor_marker
          }
          points={ploygonPoints}
          fill="black"
        />
        <TextLabel
          className={classNames(styles.knob_cursor_label, {
            [styles.__small]: size < 300,
          })}
          position={{ x: 0, y: -2 }}
        >
          {stageCurrent}
        </TextLabel>
      </g>
    </g>
  );
};

export default Cursor;
